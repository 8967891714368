// Reducer
export default function responseHandler(state = {fetching: false, error: null, success: true}, action={}) {
    const { STORE_PATH, error, showSuccess, isOpen, data } = action;
    const fetchState = {
        fetching: false,
        error: null,
        success: true
    };
    switch (action.type) {
        case 'REQUEST_DATA':
            fetchState.data = data;
            break;
        case 'REQUEST':
            fetchState.fetching = true;
            fetchState.error = false;
            fetchState.success = false;
            break;
        case 'SUCCESS':
        case 'TOGGLE_PANEL':
        case 'UPDATE_ROW':
        case 'UPDATE_PREFIX_ROW':
        case 'GET_PERMISSIONS':
        case 'GET_COBRAND_DETAILS':
        case 'GET_COBRAND_DETAILS_RMA':
        case 'GET_LABELS_OF_ENTITY':
        case 'GET_SSO_DETAILS':
        case 'LIST_CONVERSION_PIXEL_COMPONENT':
        case 'LIST_LABEL_COMPONENT':
        case 'CREATE_LABEL_COMPONENT':
        case 'ALL_PARTNERS_LIST':
        case 'INVITE_PARTNER':
        case 'PARTNER_MEMBERSHIP_LIST':
        case 'GET_PARTNER_DETAILS':
            fetchState.fetching = false;
            fetchState.error = false;
            fetchState.success = showSuccess;
            fetchState.isOpen = isOpen;
            fetchState.data = data;
            break;
        case 'ERROR':
            fetchState.fetching = false;
            fetchState.error = error;
            fetchState.success = false;
            break;
        case 'RESET_TOASTER':
            fetchState.fetching = false;
            fetchState.error = false;
            fetchState.success = false;
            break;
        case 'RESET_MODAL_ERROR_MSG':
            fetchState.fetching = false;
            fetchState.error = false;
            fetchState.success = false;
            break;
    }

    if(typeof STORE_PATH !== 'undefined') {
        return {
            ...state,
            [STORE_PATH]: {
            ...state[STORE_PATH],
            ...fetchState,
            }
        };
    } else {
        return {
            ...state
        };
    }
}