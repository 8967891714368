import { combineReducers } from 'redux';
import toggleSideMenuReducer from './toggleSideMenuReducer';
import permissionReducer from './permissionReducer';
import dashboardReducer from '../../dashboard/reducer/dashboardReducer';
import accountReducer from '../../accounts/reducer/accountReducer';
import peopleReducer from '../../peopleContainer/people/reducer/peopleReducer';
import conversionPixelReducer from '../../brand/ConversionPixel/reducer/conversionPixelReducer';
import pixelReducer from '../../brand/RetargetingPixel/reducer/pixelReducer';
import appReducer from './appReducer';
import uploadReducer from './uploadReducer';
import settingsReducer from '../../settingsModal/reducers/settingsReducer';
import popoverReducer  from '../../commonUI/popover/reducer/popoverReducer';
import editPersonReducer from '../../peopleEdit/reducer/editPersonReducer';
import updatePersonStateReducer from '../../peopleEdit/reducer/editProfileReducer';
import editAccReducer from '../../accountEdit/reducer/editAccReducer';
import responseHandler from './responseHandler';
import labelReducer from '../../label/reducer/labelReducer';
import prefixReducer from '../../prefix/reducer/prefixReducer';
import partnerMainReducer from '../../partnerOrg/reducer/mainCompReducer';
import partnerReducer from '../../partnerOrg/allpartners/reducer/allPartnerReducer';
import invitePartnerState from '../../partnerOrg/invitepartner/reducer/invitePartnerReducer';
import pendingInvitesReducer from '../../partnerOrg/pendinginvites/reducer/pendingInviteReducer';
import partnerMemberReducer from '../../peopleContainer/partnerMembership/reducers/partnerMemberReducer';
import roleReducer from '../../helper/reducer/roleReducer';
import {reducer as toastrReducer} from 'react-redux-toastr';
import partnerIntegrationReducer from '../../partnerIntegration/reducer/partnerIntegrationReducer';

const reducers = {
  toastr: toastrReducer,
  toggleSideMenuReducer: toggleSideMenuReducer, 
  dashboardReducer: dashboardReducer, 
  accountReducer: accountReducer, 
  peopleReducer: peopleReducer, 
  permissionReducer: permissionReducer,
  pixelReducer: pixelReducer,  
  conversionPixelReducer: conversionPixelReducer,
  appReducer: appReducer, 
  settingsReducer: settingsReducer, 
  popoverReducer: popoverReducer, 
  editPersonReducer: editPersonReducer, 
  updatePersonStateReducer: updatePersonStateReducer, 
  editAccReducer: editAccReducer, 
  uploadReducer: uploadReducer, 
  responseHandler: responseHandler, 
  labelReducer: labelReducer,
  prefixReducer: prefixReducer,
  allPartnersState: partnerReducer,
  invitePartnerState: invitePartnerState,
  pendingInvitesState: pendingInvitesReducer,
  partnerMainReducer: partnerMainReducer,
  partnerMemberState: partnerMemberReducer,
  roleReducerState: roleReducer,
  allTrackerState: partnerIntegrationReducer
}

/**
 * List All reducers to supply combined to store
 */
const reducer = combineReducers(reducers);
export default reducer;