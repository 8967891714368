import * as actionTypes from '../../constants/dashboardConstants.js'

const defaultTheme = {
    "--main-font-color" : " #666666 ",     /*#666666*/
    "--font-name" : 'Soleto,sans-serif', /*Soleto,sans-serif*/
    "--font-src" : '',
    "--main-font-size" : "1em",
    "--subHeading-color" : " #333 ", /* #333 */
    "--pageTitle-font-color" : " #C0C ", /* C0C */
    "--primary-color" : " #909",
    "--primary-hover-color" : " #C0C ",
  
    "--btnPrimary-default-bg-color" : " #909 ", /* #909 */ 
    "--btnPrimary-default-border-color" : " #a02ea4 ", /* #a02ea4 */ 
    "--btnPrimary-default-color" : " white ",

    /* we don't have any style for hover currently */
    "--btnPrimary-hover-bg-color" : " #909 ", /* #909 */
    "--btnPrimary-hover-color" : " white ",
    
    "--btnDefault-default-bg-color" : " #e6e6e6 ", /* #e6e6e6 */ 
    "--btnDefault-default-border-color" : " #adadad ", /* #adadad */ 
    "--btnDefault-default-color" : " #333 ", /* #333 */
    
    "--btnDefault-hover-bg-color" : " #fff ", /* #fff */ 
    
    "--active-tab-bg-color" : " #fff ", /* #fff */
    "--active-tab-color" : " #909 ", /* #909 */
    "--inactive-tab-bg-color" : " #eee ", /* #eee */
    "--inactive-tab-color" : " #999 ", /* #999 */
    
    "--notification-success-bgColor" : " #5cb85c ",
    "--notification-success-color" : " #fff ",
    "--notification-success-borderColor" : " #4cae4c ",
    
    "--notification-warning-bgColor" : " #f0ad4e ",
    "--notification-warning-color" : " #fff ",
    "--notification-warning-borderColor" : " #eea236 ",
    
    "--notification-error-bgColor" : " #d9534f ",
    "--notification-error-color" : "  #fff ",
    "--notification-error-borderColor" : " #d43f3a ",
    
    "--notification-info-bgColor" : " #5bc0de ",
    "--notification-info-color" : " #fff ",
    "--notification-info-borderColor" : " #46b8da ",
    
    "--notification-message-color" : " #fff ",

    "--header-bg-color" : " #bbb ", /* #bbb */
    "--header-hover-color" : " transparent ",
    "--navtitle-bg-color" : " #999 ", /* #999  */

    "--left-col-bg-color" : " #666666 ",/* #666666 */
    "--left-col-text-color" : " #E7E7E7 ", /* E7E7E7 */
    "--active-menu-bg-color" : " #909 ", /*linear-gradient(#A0A, #909), #909 ",*/
    "--active-menu-color" : "#e7e7e7", 
    "--current-view-bg-color" : "#00000080",
    "--active-child-menu-bg-color" : " #555 ", /* #555 */
    "--child-menu-hover" : " rgba(255, 255, 255, .06) ",
    "--expanded-menu-bg-color" : " #9900994d ", /*rgba(153, 0, 153, 0.3)*/
    "--expanded-menu-color" : " #fff ",
    "--expanded-childMenu-font-color" : "#E7E7E7",
    "--main-menu-bg-color" : " transparent ",
    "--expanded-childMenu-bg-color" : " transparent ",
    "--active-menu-rightBorder-color" : " #333 ",
    
    "--form-control-text-color" : " black ", /* black */
    "--form-control-border" : " #909 ", /* #909 */

    "--select-menu-bg-color" : " #909 ",/* #909 ", */
    "--select-menu-color" : " #ffffff ",
    "--select-menu-border-color" : " #a02ea4 ",

    "--select-menu-hover-bg-color" : " #C0C ",/* #C0C ", */
    "--select-menu-hover-color" : " #ffffff ",
    "--react-select-font-color": "#666666",

    /* To set left padding to input for account name, based on prefix */
    "--left-padding": "45px"

};
export default function dashboardReducer(state = { recentActList: {}, totalActivity: -1, recentAccountList: {}, 
    accDetails: {}, ssoDetails: {}, sharedEntity: {}, coBrandDetails:{},  activitiLoading: false,
    accountLoading: true, settingsLoading: true,
    theme : defaultTheme}, action) {
    switch (action.type) {
        case actionTypes.SET_RECENT_ACTIVITI:
            if(typeof action.list.activities !== 'undefined') {
                let appendAcc = state.recentActList[action.viewType] && state.recentActList[action.viewType].length ? [
                    ...state.recentActList[action.viewType],
                    ...action.list.activities
                ] : action.list.activities;
                
                const nextState = JSON.parse(JSON.stringify(state)); // deep copy your state
                nextState.recentActList = {};
                nextState.recentActList[action.viewType] = appendAcc;
                
                return {
                    ...state,
                    recentActList: nextState.recentActList,
                    activitiLoading: action.loading,
                    totalActivity: action.list.totalCount
                }
            } else {
                return {
                    ...state
                }
            }  
        case actionTypes.SET_ACTIVITI_LOADER:
            return {
                ...state,
                activitiLoading: action.loading
            }
        case actionTypes.SET_RECENT_ACCOUNT:
            return {
                ...state,
                recentAccountList: action.accList,
                accountLoading: action.loading
            }
        case actionTypes.SET_LOGGED_USERDETAILS:
            return {
                ...state,
                accDetails: action.accInfo,
                settingsLoading: action.loading
            }
        case actionTypes.GET_SSO_DETAILS:
            return {
                ...state,
                ssoDetails: action.ssoDetails
            }
        case actionTypes.GET_SHARED_ENTITY:
            return {
                ...state,
                sharedEntity: action.sharedEntity
            }
        case actionTypes.GET_COBRAND_DETAILS:
            var 
                isObjectEmpty = Object.keys(action.coBrandList.varOptions).length,
                returnedTarget = {};

            if(isObjectEmpty > 0) {
                returnedTarget = Object.assign({}, state.theme, action.coBrandList.varOptions);
            }
            return {
                ...state,
                coBrandDetails: action.coBrandList,
                theme: isObjectEmpty > 0 ? returnedTarget : state.theme,
                coBrandLoading: action.loading
            }
        case actionTypes.GET_COBRAND_DETAILS_RMA:
            var 
                isObjectEmpty = Object.keys(action.coBrandListRMA.varOptions).length,
                returnedTarget = {};

            if(isObjectEmpty > 0) {
                returnedTarget = Object.assign({}, state.theme, action.coBrandListRMA.varOptions);
            }
            return {
                ...state,
                coBrandDetailsRMA: action.coBrandListRMA,
                coBrandLoading: action.loading
            }
        case actionTypes.RESET_COBRAND_DETAILS:
            return{
                ...state,
                coBrandDetails: {},
                theme: defaultTheme
            }
        case actionTypes.UPDATE_ACCNAME_INPUT_SIZE:
            var returnedTarget = !!action.list ? Object.assign({}, state.theme, {"--left-padding" : action.list}) : {};
            
            return {
                ...state,
                theme: !!action.list ? returnedTarget : state.theme
            }
        default:
            return state;
    }
}