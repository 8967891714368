import React from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const ColorLinearProgress = withStyles({
  colorPrimary: {
    backgroundColor: "#909"
  },
  barColorPrimary: {
    backgroundColor: "#DA70D6"
  }
})(LinearProgress);

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    position: "relative",
    top: "50px"
  },
  margin: {
    margin: theme.spacing(1)
  }
}));

export default function CustomizedProgressBars() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <ColorLinearProgress className={classes.margin} />
    </div>
  );
}
