import React from 'react';

const TenantSessionExists = (props) => {
    return (   
        <div className="tenant-session-msg">
            <h4>You have an existing session with another tenant, please close the session to proceed.
            </h4>
        </div>   
    );
}
export default TenantSessionExists;