import React from 'react';
import '../textarea/textarea.css';

const TextArea = ( props ) => {
    const inputClasses = ["inputText", "form-control"];
    let { elementConfig, value, readOnly, disabled } = props.formElement
    readOnly = readOnly ? true : false
    disabled = disabled ? true : false
    return(
        <div>
            <br />
            <textarea
                className={inputClasses.join(' ')}
                placeholder=" "
                {...elementConfig}
                value={value}
                id={props.id}
                onFocus={props.onFocus}
                onChange={props.changed} 
                onBlur={props.onBlur}
                autoComplete = "off"
                readOnly={readOnly}
                disabled={disabled}
            />
        </div>
    );
}

export default TextArea;