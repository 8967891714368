import React from 'react';
import {isEmpty} from '../../helper/commonFunc';
var _ = require('lodash');

export const FormLabel = (props) => {
    var 
        label = '',
        formElement = props.formElement;
    const prefixHolderClass = !!formElement.prefixHolderClass ? formElement.prefixHolderClass : '';
    const spanclass = formElement.elementConfig.spanclass;
    const labelLeft = !!spanclass && spanclass.includes('form-control-feedback left') ? 'floatinglabel-left-60': 'floatinglabel-left-30';

    switch(formElement.elementType) {
        case 'creatable':
        case 'select':
        case 'async':
        case 'tooltipSelect':
        case 'checkedSelect':
        case 'textArea':
        case 'timePicker':
            label = <label htmlFor={`label-${props.id}`} className={!!formElement.inputVal || (!isEmpty(formElement.value) || (formElement.value && formElement.value.length)) ? "labelOut textLabel" : "labelIn textLabel"}>{formElement.elementConfig.label}{!!formElement.validation.required || !!formElement.validation.dependent && !_.isEmpty(formElement.value) || (!!formElement.validation.dependent && !_.isEmpty(formElement.value) && !!formElement.validation.required) ? <span className="required">*</span> : false}</label>
            break;
        case 'checkbox':
            label = <span className= "textLabel">{formElement.elementConfig.label}{!!formElement.validation.required ? <span className="required">*</span> : false}</span>
            break;
        case 'input':
        case 'numericInput':
            label = <span className={`floating-label ${labelLeft} ${prefixHolderClass}`}>{formElement.elementConfig.label}{formElement.validation.required ? <span className="required">*</span> : false}</span>
            break;
        case 'paragraph':
        case 'radio':
            label = <label htmlFor={formElement.id} className="margin-left-10">{formElement.elementConfig.labelname}</label>
            break;
        case 'custom-radio':
        case 'checkbox-group':
            label = !!formElement.elementConfig.label && <label className="textLabel customRadioGroupLabel">{formElement.elementConfig.label}</label>
            break;
        default:
            label = ''
            break;
    }
    return label;   
}