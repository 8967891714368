import { updatePersonApi } from '../services/settingsApi.js';
import * as actionTypes from '../../constants/settingsConstants.js';
import { apiError, apiRequest, apiSuccess } from '../../app/actions/appAction.js';

function setLoading(loadingData) {
    return {
        type: actionTypes.SHOW_UPDATE_PERSON_LOADER,
        loadingData
    }
}

export const componentLoadingAction = (loadingData) => (dispatch) => {
    dispatch(setLoading(loadingData));
}

export const updatePersonAction  = (postData, storePath, callback) => (dispatch) => {
    dispatch(apiRequest(storePath));
    updatePersonApi( postData, (response) => {
        if(response.error) {
            dispatch(apiError(response.error, storePath));
        } else {
            dispatch(apiSuccess(storePath, true));
        }
        callback && callback(response);
    });
}