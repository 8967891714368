import React from 'react';

export const FormWarning = ({ warningMessage }) => {
	return (
		!!warningMessage ?
			<div className='divWarning'>
				<i className="far fa-exclamation-triangle warningIcon"></i>
				<span className="warningMessage">{warningMessage}</span>
			</div>
			: null
	);
};
