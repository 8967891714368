import React from 'react';

export const FormError = (props) => {
    var
        parent = props.parent,
        index = !!props.index ? props.index : parent.formElement.id, 
        divId = 'div' + index,
        hideFormError = !!parent && !!parent.formElement && !!parent.formElement.config && parent.formElement.config.hideFormError,
        removeErrorSpace = !!parent && !!parent.formElement && !!parent.formElement.config && parent.formElement.config.removeErrorSpace;
    return(
        !hideFormError && <div id={divId} className = 'divError'>
            <span className="far fa-exclamation-triangle span-nodisplay" data-display-none={ removeErrorSpace }></span>
            <span className="span-msg"></span>
        </div>
    )
}