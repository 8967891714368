export const SET_ALL_ACCOUNTS = 'SET_ALL_ACCOUNTS';
export const SHOW_PERSON_INVITE_LOADER = 'SHOW_PERSON_INVITE_LOADER';
export const SET_NEWACC_DATA = 'SET_NEWACC_DATA';
export const SHOW_ADD_ACC_LOADER = 'SHOW_ADD_ACC_LOADER';
export const SHOW_CREATE_ACC_LOADER = 'SHOW_CREATE_ACC_LOADER';
export const SHOW_ACC_EDIT_LOADER = 'SHOW_ACC_EDIT_LOADER';
export const SET_EDIT_ACC_DETAILS = 'SET_EDIT_ACC_DETAILS';
export const CATEGORIZED = 'CATEGORIZED';
export const SET_ACCOUNTS = 'SET_ACCOUNTS';
export const SEARCH_RESULT_ACC = 'SEARCH_RESULT_ACC';
export const SET_CARD = 'SET_CARD';
export const ADD_FAVOURITE = 'ADD_FAVOURITE';
export const FAVMARK_RESET = 'FAVMARK_RESET';
export const SET_CARD_ID = 'SET_CARD_ID';
export const REMOVE_FAVOURITE = 'REMOVE_FAVOURITE';
export const DELETE_CARD = 'DELETE_CARD';
export const DROP_ACC = 'DROP_ACC';
export const UPDATE_RECENT_ACC = 'UPDATE_RECENT_ACC';
export const RECENTMARK_RESET = 'RECENTMARK_RESET';
export const BRAND = 'Brand';
export const RETAILER = 'Retailer';
export const RELOAD_ACCOUNTS = 'RELOAD_ACCOUNTS';
export const FETCH_MORE_ACC = 'FETCH_MORE_ACC';
export const GET_ALL_ACC = 'GET_ALL_ACC';
export const CREATE_ACC_COMPONENT = 'CREATE_ACC_COMPONENT';
export const CREATE_RETAIL_ACC_COMPONENT = 'CREATE_RETAIL_ACC_COMPONENT';
export const EDIT_ACC_COMPONENT = 'EDIT_ACC_COMPONENT';
export const ADDTO_PERSON_COMPONENT = 'ADDTO_PERSON_COMPONENT';
export const DELETE_ACC_COMPONENT = 'DELETE_ACC_COMPONENT';
export const FAV_ACC_COMPONENT = 'FAV_ACC_COMPONENT';
export const REACTIVATE_ACC_COMPONENT = 'REACTIVATE_ACC_COMPONENT';
export const ALL_ACC_COMPONENT = 'ALL_ACC_COMPONENT';
export const SET_ASSOCIATED_PEOPLE = 'SET_ASSOCIATED_PEOPLE';
export const GET_ASSOCIATED_BRAND = 'GET_ASSOCIATED_BRAND';
export const GET_CONFIGS = 'GET_CONFIGS';
export const GET_RETAILERS = 'GET_RETAILERS';
export const GET_GCM_ADVERTISERS = 'GET_GCM_ADVERTISERS';
/* SUCCESS MESSAGES */
export const INVITE_PERSON_SUCCESS_MSG = 'Invitation sent successfully';
export const DELETE_ACC_SUCCESS_MSG = 'Account deleted successfully';
export const REACTIVATE_ACC_SUCCESS_MSG = 'Account reactivated successfully';
export const UNFAV_ACC_SUCCESS_MSG = 'Removed account from favourite';
export const FAV_ACC_SUCCESS_MSG = 'Account added to favourite';
export const EDIT_ACC_SUCCESS_MSG = 'Account Updated successfully';
export const NEW_ACC_SUCCESS_MSG = 'Account created successfully';
export const NEW_RETAIL_ACC_SUCCESS_MSG = 'Retail Account created successfully';
export const RELOADING_BRAND_INFORMATION = 'Reloading brand information';
export const RELOADING_ORGANIZATION_INFORMATION = 'Reloading organization information';
export const SUCCESSFULLY_UPDATED = 'Account successfully updated';
export const GET_LABELS_OF_ENTITY = 'GET_LABELS_OF_ENTITY';

/* MODAL TITLE */
export const UNDO_FAVOURITE_TITLE = 'Remove account from favourite';
export const ACC_DELETE = 'Delete Account';
export const REACTIVATE_ACCOUNT = 'Reactivate Account';

/* TOASTER TITLE */
export const EDIT_ACCOUNT = 'Edit Account';

/* CONFIG */
export const LABEL_OF_ENTITIES_CONFIG = {
    storePath: CREATE_ACC_COMPONENT,
    getData: 'data',
    actionType: GET_LABELS_OF_ENTITY
}
export const GET_BRAND_DATA_CONFIG = {
    storePath: CREATE_ACC_COMPONENT,
    getData: 'availableEntities',
    actionType: GET_ASSOCIATED_BRAND
}
export const GET_RETAILER_ACC = {
	storePath: CREATE_ACC_COMPONENT,
    getData: 'account',
    actionType: GET_RETAILERS
}
export const GET_GCM_ADV = {
	storePath: CREATE_ACC_COMPONENT,
    getData: 'advertiserList',
    actionType: GET_GCM_ADVERTISERS
}
export const GET_CONFIG_NAME = {
	storePath: CREATE_ACC_COMPONENT,
    getData: 'configList',
    actionType: GET_CONFIGS
}
export const CARD_TYPES = {
	account: ['Account', 'Template Account'],
	retailAccount: 'Retail Account',
}

//partner platform integrate card footer constants
export const PARTNER_MEMBER_INTEGRATION = 'Partner Member Integration';
export const BRAND_SAFETY_AND_MEASUREMENT = 'Brand Safety & Measurement';
export const GO_TO_ACCOUNT = 'Go to Account';

//settings modal 
 export const SETTINGS_MODAL_COMPONENT = 'SETTINGS_MODAL_COMPONENT';
 export const SETTINGS_SUCCESS_MESSAGE = 'Success';
 export const GENERAL_SETTINGS = 'General';
 export const CHANGE_PASSWORD = 'Change Password';
 export const SETTINGS_MODAL_FILE_UPLOAD = 'SETTINGS_MODAL_FILE_UPLOAD';

 //organization modal
 export const ORGANIZATION_MODAL_COMPONENT = 'ORGANIZATION_MODAL_COMPONENT';
 export const ORG_GENERAL_SETTINGS = 'ORG_GENERAL_SETTINGS';
 export const ORG_COBRANDING = 'ORG_COBRANDING';
 export const ORG_RMA_COBRANDING = 'ORG_RMA_COBRANDING';
 export const ORG_TABS = {
    GENERAL_SETTINGS : 'General',
    WHITE_LABELLING : 'Co-branding',
    RMA_WHITELABELLING: 'Jivox IQ DaVinci Co-branding'
 }

 export const RMA_ROLES = {
    RETAIL_ADMIN : 'Retail Admin',
    ADVERTISER : 'Advertiser'
 }

 export const ROLES_WITH_ADS_VIEW_ACCESS = ['Admin', 'Creative Designer', 'Observer', 'API-Role', 'Campaign Builder', 'Support'];
 export const RETAILER_ORG = 'Retailer';
 export const MEMBER_ROLE = 'Member';

export const accountTooltipMessage = 'Create a new Jivox IQ account. Select this option if you want to create and scale personalized campaigns using dynamic creative optimization.';
export const retailAccountTooltipMessage = 'Create a new Jivox IQ DaVinci Advertiser account. Select this option if you want easy and seamless campaign activation with pre-defined templates.';

export const removeGCMAdv = 'Remove GCM Advertiser';
export const toggleGCMLinkMessage = 'If you remove this GCM Advertiser, any Media plans created using campaigns from this Advertiser will be not usable for the Davinci campaigns. Do you want to remove the GCM Advertiser?';