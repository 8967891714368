import * as appConstants from '../../constants/appConstants.js';
import * as peopleConstants from '../../constants/peopleConstants.js';
import * as accConstants from '../../constants/accountConstants.js';
import * as settingsConstants from '../../constants/settingsConstants.js';
import ApiConfigClass  from '../../config/apiConfig';

export default function appReducer(state = {
    loadingData: {fetching: true, fetchingDetails: 'loading please wait ...'},
    loadingCompData: {loadingComp: false, loadingDetails: ''},
    apiConfig: {data: {}, error: ''},
    loadingCard: [],
    roles: [], socketToken: '', menuList: [], entityIds: ''
}, action) {
    switch (action.type) {
        case peopleConstants.SHOW_SUBMIT_PERSON_LOADER:
        case accConstants.SHOW_CREATE_ACC_LOADER:
        case peopleConstants.SHOW_CREATE_KEY_LOADER:
        case appConstants.SHOW_APP_LOADER :
        case appConstants.FETCH_CONFIG_REJECTED:
            return {
                ...state,
                loadingData: action.loadingData
            }
        case peopleConstants.SHOW_ADD_PERSON_LOADER:
        case peopleConstants.SHOW_PEOPLE_LOADER:
        case peopleConstants.SET_ALL_PEOPLES:
        case settingsConstants.SHOW_UPDATE_PERSON_LOADER:
        case accConstants.SHOW_ADD_ACC_LOADER:
        case peopleConstants.SHOW_GENERATE_KEY_LOADER:
            return {
                ...state,
                loadingCompData: action.loadingCompData
            }
        case appConstants.CARD_LOADER:
            const newState = state.loadingCard.slice();
            newState.push(action.id);
          
            return {
                ...state,
                loadingCard: newState
            }
        case appConstants.RESET_CARD_LOADER:
            let items = state.loadingCard.filter((item, index) => {
                if(item === action.id) {
                    return false;
                }
                return true;
            });
            return {
                ...state,
                loadingCard: items
            }
        case appConstants.SET_ROLES:
            return {
                ...state,
                roles: action.data.roles
            } 
        case appConstants.FETCH_CONFIG_FULFILLED:
            var {
                payload: { data }
            } = action, config = {data}

            ApiConfigClass.setData(appConstants.API_ROOT, config.data.API_ROOT);
            ApiConfigClass.setData(appConstants.MOAT_API_ROOT, config.data.MOAT_API_ROOT);
			ApiConfigClass.setData(appConstants.DCM_SERVER_URL, config.data.DCM_SERVER_URL);
            ApiConfigClass.setData(appConstants.SOCKET_ROOT, config.data.SOCKET_ROOT);
            ApiConfigClass.setData(appConstants.LOCAL, config.data.LOCAL);
            ApiConfigClass.setData(appConstants.BRB_URL, config.data.BRB_URL);
            ApiConfigClass.setData(appConstants.RMA_URL, config.data.RMA_URL);
            ApiConfigClass.setData(appConstants.SUPPORT_URL, config.data.SUPPORT_URL);
            ApiConfigClass.setData(appConstants.API_CONFIG_LOADED, true);
            ApiConfigClass.setData(appConstants.RP_JS_HELP, config.data.RP_JS_HELP);
            ApiConfigClass.setData(appConstants.CP_JS_HELP, config.data.CP_JS_HELP);
            ApiConfigClass.setData(appConstants.SYSTEM_MAINTENANCE_WARNING, config.data.SYSTEM_MAINTENANCE_WARNING);
            ApiConfigClass.setData(appConstants.SOFTWARE_UPDATE_WARNING, config.data.SOFTWARE_UPDATE_WARNING);
            return {
                ...state
            }
        case appConstants.FETCH_CONFIG_REJECTED:
            ApiConfigClass.setData(appConstants.API_CONFIG_LOADED, false);
            return {
                ...state
            }
        case appConstants.SET_SOCKET_TOKEN:
            return {
                ...state,
                socketToken: action.response.jwt
            }
        case [action.actionType]:
            if(action.entityIds) {
                return {
                    ...state,
                    menuList: action.list,
                    entityIds: action.entityIds
                }
            }
        default:
            return state;
    }
}