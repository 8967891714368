import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../app/reducers/rootReducers';

let middleware = [thunk];

if (process.env.NODE_ENV !== 'production') {
  let logger = require('redux-logger').default;
  middleware = [...middleware];
} 

export default function configureStore() {
    const composeEnhancers = process.env.NODE_ENV !== 'production' ? (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose) : compose;
    const enhancer = composeEnhancers(applyMiddleware(...middleware));
    const store = createStore(rootReducer, enhancer);
    return store;
}