export default function popoverReducer(state = {
    personAcc: '', personEmail: '', personRole: [], brandName: '', orgName: '', switched: false
}, action) {
    switch (action.type) {
        case 'SET_ADVANCE_SEARCH':
            return {
                ...state,
                [action.inputName]: action.inputValue
            }
        case 'RESET_ADVANCE_SEARCH_ACC':
            return {
                ...state,
                brandName: '', 
                orgName: '',
                switched: false
            }
        case 'RESET_ADVANCE_SEARCH_PEOPLE':
            return {
                ...state,
                personName: '', 
                personAcc: '', 
                personEmail: '', 
                personRole: []
            }
        default:
            return state;
    }
}