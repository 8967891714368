import React from 'react';
import ReactTooltip from 'react-tooltip';
import './tooltip.css'

const Tooltip = (props) => {
    var reactDelayHide = props.cssclass === "image-preview" ? 1000 : 0;
    return(
        <ReactTooltip 
            html={typeof props.html !='undefined' ? props.html :  false}
            getContent={() => { return null }} 
            id={props.id} 
            place={props.position} 
            delayHide={ reactDelayHide } 
            type={props.type ? props.type : "dark"} 
            effect={props.effect?props.effect : 'solid'} 
            class={props.cssclass} 
            clickable={props.clickable} 
            globalEventOff={props.globalEventOff}
        >
            {props.source}
        </ReactTooltip>
    )
}

export default Tooltip;