import React from 'react';
import Modal from 'react-bootstrap-modal';
import * as DOMPurify from 'dompurify';
import Loader from '../../commonUI/loader/withLoader';
import CustomizedProgressBars from '../../commonUI/linearProgress/colorLinearProgress';
import './modal.css';
import '../error/error.css';

const MyModal = (props) => {

    const renderErrorMessage = () => {
        if (props.modalErr) {
            if (props.modalErr.includes('<') && props.modalErr.includes('>')) {
                return <div className="error-div" data-testid="modal-err" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.modalErr, {
                    ADD_ATTR: ['target'] // Allow 'target' attribute after sanitizing
                  })}} />;
            } else {
                return <div className="error-div" data-testid="modal-err">{props.modalErr}</div>;
            }
        }
        return null;
    };

    return(
        <Modal show={props.show} onHide={props.onHide} dialogClassName={props.modalClass}>
            <Modal.Header closeButton>
                {
                props.modalWindowData.name === 'generatedTag' ? null :
                    props.loading ?
                        <CustomizedProgressBars />
                    : null
                }
                <Modal.Title><p className="modal-title">{props.modalWindowData.title}{props.requiredMsg === 'optional' ? null : <span className = 'requiredFieldModal'> Required Information </span>}</p></Modal.Title>
            </Modal.Header>
            {/* {props.modalWindowData.name === 'generatedTag' ? null :
                props.loading ?
                <div className='loading-modal loading-modal-margin' id="modal-loader">
                    <Loader loader={props.loading} />
                    <br />
                    <span className="loader-span">{props.loadingMsg}</span>
                </div>
                : null
            } */}
            <Modal.Body className={props.modalBodyClass ? props.modalBodyClass : ''}>
                {props.modalWindowData.body}
            </Modal.Body>
            {props.modalWindowData.name === 'generatedTag' ? null :
            <Modal.Footer className={props.footerClass}>
                 {renderErrorMessage()}
                {
                    !props.modalWindowData.hideSubmitBtn &&
                    <button className="modal-button btn btn-round update-details btn-primary" onClick={props.onSubmit} disabled={props.buttonDisable? props.buttonDisable : props.submitBtnLoader}>
                        {props.submitBtnLoader ? 'Processing ' : props.modalWindowData.buttonText}
                        {props.submitBtnLoader ? <i className="far fa-spinner fa-spin"></i> : null}
                    </button>
                }
                {
                    !props.modalWindowData.hideCancelBtn ?
                    (
                        <button className="modal-button btn btn-default btn-round update-details" onClick={props.onHide}>{props.modalWindowData.cancelText || 'Cancel'}</button>
                    ) : null
                }
                
            </Modal.Footer>
            }
        </Modal>
    )
}

export default MyModal;