import * as appConstants from '../constants/appConstants.js';
class ApiConfClass {
    constructor() {
        this.data = {
            [appConstants.API_ROOT]: '',
            [appConstants.MOAT_API_ROOT]: '',
			[appConstants.DCM_SERVER_URL]: '',
            [appConstants.SOCKET_ROOT]: '',
            [appConstants.LOCAL]: true,
            [appConstants.BRB_URL]: "",
            [appConstants.RMA_URL]: "",
            [appConstants.SUPPORT_URL]: "",
            [appConstants.API_CONFIG_LOADED]: false,
            [appConstants.RP_JS_HELP]: "",
            [appConstants.CP_JS_HELP]: "",
            [appConstants.SYSTEM_MAINTENANCE_WARNING]: "",
            [appConstants.SOFTWARE_UPDATE_WARNING]: ""
        }   
    }	
    getData = (property) => {
        return this.data[property];
    }
    setData = (property, data) => {
        this.data[property] = data;
    }
}

const ApiConfigClass = new ApiConfClass;
export default ApiConfigClass;
