import React, { useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import { logoutAction, removeFileAction, selectError, selectFetching, selectFetchingg, selectSuccess, selectUploadError, selectUploadResp, uploadAction } from '../app/actions/appAction';
import { CreateForm } from '../commonUI/Form/createForm';
import ReduxToaster from '../commonUI/notification/reduxToaster';
import { formSubmit } from '../helper/formHelper/submitForm';
import { inputChangedHandler } from '../helper/formHelper/updateForm';
import PopupModal from '../commonUI/modal/modal';
import { updatePersonAction } from './action/updatePersonAction.js';
import { checkFileExtension, uploadHelper, validateSize, uploadCallback, isEmpty, logoutResp } from '../helper/commonFunc';
import { userInfoAction } from '../dashboard/actions/dashboardAction';
import { settingsChangePasswordAction } from './action/changePassworsAction';

import * as accountConstants from '../constants/accountConstants';

import './settingsModalNew.css';

export const SettingsModalNew = (props) => {
    const {show, onHide, accDetails} = props;
    const [activeTab, setActiveTab] = useState(accountConstants.GENERAL_SETTINGS);
    const {uploadResponse, uploading, settingsError, settingsSuccess, settingsLoading, passwordError, passwordLoading } = useSelector(state => {
		return {
            uploadResponse: selectUploadResp(state, 'personsettings'),
            uploading: selectFetching(state,'personsettings'),
            settingsError: selectError(state, accountConstants.GENERAL_SETTINGS ),
            passwordError: selectError(state, accountConstants.CHANGE_PASSWORD),
            settingsSuccess: selectSuccess(state, accountConstants.GENERAL_SETTINGS),
            settingsLoading: selectFetchingg(state, accountConstants.GENERAL_SETTINGS),
            passwordLoading: selectFetchingg(state, accountConstants.CHANGE_PASSWORD), 
		}
	}, shallowEqual);

    const settingsForm = {
        firstName: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'First Name',
                spanclass: 'fas fa-user form-control-feedback left',
                divclass: 'user-input-wrp has-feedback form-group form-group-div col-md-6 col-sm-12 col-xs-12'
            },
            value: accDetails.firstName,
            validation: {
                required: true,
                format: 'names'
            },
            valid: false,
            touched: false
        },
        lastName: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Last Name',
                spanclass: 'fas fa-user form-control-feedback left',
                divclass: 'user-input-wrp has-feedback form-group form-group-div col-md-6 col-sm-12 col-xs-12'
            },
            value: accDetails.lastName,
            validation: {
                required: true,
                format: 'names'
            },
            valid: false,
            touched: false
        },
        email: {
            elementType: 'input',
            elementConfig: {
                type: 'email',
                label: 'Email Address',
                readOnly: true,
                spanclass: 'far fa-envelope form-control-feedback left optional',
                divclass: 'user-input-wrp has-feedback form-group form-group-div col-xs-12'
            },
            value: accDetails.primaryEmail,
            validation: {
                required: false
            },
            valid: true,
            touched: false
        },
        primaryPhone: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Primary Phone',
                spanclass: 'fas fa-phone form-control-feedback left optional',
                divclass: 'user-input-wrp has-feedback form-group form-group-div col-xs-12'
            },
            value: accDetails.primaryPhone,
            validation: {
                required: false
            },
            valid: true,
            touched: false
        },
        addressLine1: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Address Line1',
                spanclass: 'fas fa-address-card form-control-feedback left optional',
                divclass: 'user-input-wrp has-feedback form-group form-group-div col-md-6 col-sm-12 col-xs-12'
            },
            value: accDetails.addressLine1,
            validation: {
                required: false
            },
            valid: true,
            touched: false
        },
        addressLine2: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Address Line2',
                spanclass: 'fas fa-address-card form-control-feedback left optional',
                divclass: 'user-input-wrp has-feedback form-group form-group-div col-md-6 col-sm-12 col-xs-12'
            },
            value: accDetails.addressLine2,
            validation: {
                required: false
            },
            valid: true,
            touched: false
        },
        country: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Country',
                spanclass: 'fas fa-map-marker-alt form-control-feedback left optional',
                divclass: 'user-input-wrp has-feedback form-group form-group-div col-md-6 col-sm-12 col-xs-12'
            },
            value: accDetails.country,
            validation: {
                required: false
            },
            valid: true,
            touched: false
        },
        state: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'State',
                spanclass: 'fas fa-map-marker-alt form-control-feedback left optional',
                divclass: 'user-input-wrp has-feedback form-group form-group-div col-md-6 col-sm-12 col-xs-12'
            },
            value: accDetails.state,
            validation: {
                required: false
            },
            valid: true,
            touched: false
        },
        city: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'City',
                spanclass: 'fas fa-map-marker-alt form-control-feedback left optional',
                divclass: 'user-input-wrp has-feedback form-group form-group-div col-md-6 col-sm-12 col-xs-12'
            },
            value: accDetails.city,
            validation: {
                required: false
            },
            valid: true,
            touched: false
        },
        zipCode: {
            elementType: 'input',
            elementConfig: {
                type: 'text',
                label: 'Zip Code',
                spanclass: 'fas fa-map-marker-alt form-control-feedback left optional',
                divclass: 'user-input-wrp has-feedback form-group form-group-div col-md-6 col-sm-12 col-xs-12'
            },
            value: accDetails.zipCode,
            validation: {
                required: false
            },
            valid: true,
            touched: false
        },
        image: {
            elementType: 'file',
            elementConfig: {
                type: 'text',
                label: '',
                disabled: 'disabled',
                readOnly: true,
                spanclass: 'far fa-image form-control-feedback left optional',
                divclass: 'user-input-wrp has-feedback form-group form-group-div col-xs-12'
            },
            value: '',
            url: '',
            validation: {
                required: false
            },
            valid: true,
            acceptFileType:"image/*",
            touched: false
        },
    };

    const passwordForm = {
        oldPassword: {
            elementType: 'input',
            elementConfig: {
                type: 'password',
                label: 'Current Password',
                readOnly: false,
                spanclass: 'fas fa-key form-control-feedback left',
                divclass: 'user-input-wrp has-feedback form-group form-group-div col-xs-12'
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        },
        newPassword: {
            elementType: 'input',
            elementConfig: {
                type: 'password',
                label: 'New Password',
                readOnly: false,
                spanclass: 'fas fa-key form-control-feedback left',
                divclass: 'user-input-wrp has-feedback form-group form-group-div col-xs-12'
            },
            value: '',
            validation: {
                required: true,
                format: 'password'
            },
            valid: false,
            touched: false
        },
        confirmPassword: {
            elementType: 'input',
            elementConfig: {
                type: 'password',
                label: 'Confirm Password',
                readOnly: false,
                spanclass: 'fas fa-key form-control-feedback left',
                divclass: 'user-input-wrp has-feedback form-group form-group-div col-xs-12'
            },
            value: '',
            validation: {
                required: true
            },
            valid: false,
            touched: false
        }
    }

    const [generalSettingsForm, updateSettingsForm] = useState(settingsForm);
    const [passwordSettingsForm, updatePasswordForm] = useState(passwordForm);
    const dispatch = useDispatch();
    const handleTabChange = (tab) => {
        setActiveTab(tab);
    }

    const handleInputChange = (event, inputIdentifier, selection) => {
        var updatedSettingsForm = inputChangedHandler(event, inputIdentifier, selection, generalSettingsForm);
        updateSettingsForm(updatedSettingsForm);
    }

    const handlePasswordInputChange = (event, inputIdentifier, selection) => {
        var updatedPasswordForm = inputChangedHandler(event, inputIdentifier, selection, passwordSettingsForm); 
        updatePasswordForm(updatedPasswordForm);
    }

    const handlefileUpload = (e) =>{      //Upload logo image
        var fileName = e.target.files[0].name,
        fileObj = e.target.files[0],
        isValid = checkFileExtension(fileName),
        fileSize = validateSize(fileObj),
        inputId = e.target.id;

        if(isValid && fileSize) {
            var reader = new FileReader();
            reader.readAsDataURL(fileObj) //insert the file into reader
            reader.onloadend = (event) => {
                let fileRead = uploadHelper(event);
                if(fileRead) {
                    handleInputChange(null, "image", fileName, generalSettingsForm);
                    dispatch(uploadAction(fileObj, 'personsettings', 'uploadUserImage', uploadCallback.bind(this, inputId, afterUpload)));
                }
            };
        }
    }
    const afterUpload = () => {
        handleInputChange(null, "image", '', generalSettingsForm);
    }
    const removeLogoImage = () => {
        handleInputChange(null, "image", '', generalSettingsForm);
        dispatch(removeFileAction('personsettings'));
    }


    const formElementsArray = [];
        var formData = {};
        for (let key in generalSettingsForm) {
            formElementsArray.push({
                id: key,
                config: generalSettingsForm[key]
            });
        }
        formData = {
            formElementsArray: formElementsArray,
            formName: "settings-form",
            chunkSize:[2,2,1,1,2,2,2,2,2,2,1],
            inputChangedHandler: handleInputChange,
            fileUpload: handlefileUpload,
            removeLogoImage: removeLogoImage,
            uploadResponse: uploadResponse,
            uploading: uploading,
        }
    
        const passwordSettingFormArray = [];
        var passwordformData = {};
        for (let key in passwordSettingsForm) {
            passwordSettingFormArray.push({
                id: key,
                config: passwordSettingsForm[key]
            });
        }
    
        passwordformData = {
            formElementsArray: passwordSettingFormArray,
            formName: "password-form",
            chunkSize:[2,2,2],
            inputChangedHandler: handlePasswordInputChange,
        }

    
        const handleFormSubmit = (event) => {
            if(activeTab === accountConstants.GENERAL_SETTINGS ){
                var formData = {
                        fileObj: uploadResponse,
                        type: 'editForm'
                };
                var editFormData = formSubmit(event, generalSettingsForm, formData);
                let postData = editFormData.postData;
                if(editFormData.formValid){
                    if(editFormData.formTouched) {
                        dispatch(updatePersonAction(postData, accountConstants.GENERAL_SETTINGS, handleSubmitResp));
                    }else {
                            let msg = 'No change in the data to update';
                            let toastrOptions = {
                                icon: 'error',
                                status: 'error'
                            };
                            let title = 'Edit Profile';
                            toastr.light(title, msg, toastrOptions)
                            onHide('settingsModal', accountConstants.GENERAL_SETTINGS); 
                    }
                }
            } else {
                var formData = {
                    type: 'createForm'
                };
                var editFormData = formSubmit(event, passwordSettingsForm, formData);
                let postData = editFormData.postData;
                if(editFormData.formValid){
                    if(postData["newPassword"] !== postData["confirmPassword"]) {
                        document.getElementById("divnewPassword").lastChild.innerHTML = 'Password and confirm password does not match'; 
                        document.getElementById("divnewPassword").firstChild.className = "fa fa-exclamation-triangle span-error ";
                        return false;
                    }
                    if(!isEmpty(postData)) {
                        dispatch(settingsChangePasswordAction(postData, accountConstants.CHANGE_PASSWORD, handleSubmitResp));
                    }
                }
            }
        }

        const handleSubmitResp = (data) => {
            if(!data.error){
            let toastrOptions = {
                icon: 'success',
                status: 'success'
            };
            let title = 'Settings', msg;
            
            if(activeTab === accountConstants.GENERAL_SETTINGS){
                msg = 'Profile has been successfully updated!';
                toastr.light(title, msg, toastrOptions)   
                dispatch(userInfoAction());
            } else {
                msg = 'Password has been successfully updated!';
                toastr.light(title, msg, toastrOptions) 
                dispatch(logoutAction(logoutResp));
            }
            onHide('settingsModal', accountConstants.SETTINGS_MODAL_COMPONENT);
            }
        }
    
    
    //Popup Modal content;
    const settings_modalWindowData = {
        name : "Settings",
        id: "settings",
        title: "Settings",
        body:  
            <>
            <div className="settings-modal-tabs">
                <div className={[activeTab === accountConstants.GENERAL_SETTINGS ? 'settings-modal-active' : '', 'settings-modal-tab settings'].join(' ')} onClick={(e)=>handleTabChange(accountConstants.GENERAL_SETTINGS)}><div className="settings-modal-tab-box">{accountConstants.GENERAL_SETTINGS}</div></div>
                <div className={[activeTab === accountConstants.CHANGE_PASSWORD ? 'settings-modal-active' : '', 'settings-modal-tab password'].join(' ')}  onClick={(e)=>handleTabChange(accountConstants.CHANGE_PASSWORD)}><div className="settings-modal-tab-box">{accountConstants.CHANGE_PASSWORD}</div></div>
            </div>
            {
            activeTab == accountConstants.GENERAL_SETTINGS &&
                <div className="create-form">
                    <CreateForm formData={formData} />
                </div>
            }
            {
            activeTab == accountConstants.CHANGE_PASSWORD &&
                <div className="create-form">
                   <CreateForm formData={passwordformData} />
                </div>
            }
            </>,
        buttonText : activeTab == accountConstants.CHANGE_PASSWORD? "Update Password" : 'Update'     
    };

    return(
            <>
                <ReduxToaster
                    title="Settings"
                    success={""} 
                    storePath={accountConstants.SETTINGS_MODAL_COMPONENT}
                    successMsg={accountConstants.SETTINGS_SUCCESS_MESSAGE} 
                />   
                <PopupModal 
                    show={show} 
                    onHide={() => onHide('settingsModal', accountConstants.SETTINGS_MODAL_COMPONENT)}
                    modalWindowData={settings_modalWindowData} 
                    onSubmit={handleFormSubmit}
                    modalErr={activeTab == accountConstants.GENERAL_SETTINGS? settingsError : passwordError}
                    loading={false} loadingMsg=''
                    submitBtnLoader={settingsLoading || passwordLoading? true : false}
                />
            </>
    )
}