export function getInviteType (list) {
    let inviteType = 0, isIncoming = false, isOutgoing = false;
    for(let item of list) {
        if(inviteType < 2) {
            if(item.isIncoming && !isIncoming) {
                isIncoming = true;
                inviteType++;
            } else if(!item.isIncoming && !isOutgoing) {
                isOutgoing = true;
                inviteType++;
            }
        } else {
            return inviteType;
        }
    }
    return inviteType;
}
