export const SET_RECENT_ACTIVITI = 'SET_RECENT_ACTIVITI';
export const SET_RECENT_ACCOUNT = 'SET_RECENT_ACCOUNT';
export const SET_LOGGED_USERDETAILS = 'SET_LOGGED_USERDETAILS';
export const GET_SSO_DETAILS = 'GET_SSO_DETAILS';
export const SET_ACTIVITI_LOADER = 'SET_ACTIVITI_LOADER';
export const GET_COBRAND_DETAILS = 'GET_COBRAND_DETAILS';
export const RESET_COBRAND_DETAILS = 'RESET_COBRAND_DETAILS';
export const UPDATE_ACCNAME_INPUT_SIZE = 'UPDATE_ACCNAME_INPUT_SIZE';
export const DATA_SHARING = 'Data Sharing';
export const DATA_SHARING_COMP = 'DATA_SHARING_COMP';
export const GET_SHARED_ENTITY = 'GET_SHARED_ENTITY';
export const BRAND_DATA_SHARING_SUCCESS = 'The brand data was shared';
export const DATA_SHARING_STOPPED_SUCCESS = 'Removed all brand data sharing';
export const ORG_SETTINGS_COMP = 'ORG_SETTINGS_COMP';
export const DATA_SHARE_STOP = "Don't Share";
export const GET_COBRAND_DETAILS_RMA = 'GET_COBRAND_DETAILS_RMA';