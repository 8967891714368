import * as appConstants from '../../constants/appConstants.js';
import { appApi, getRoles, uploadApi, generateSocketToken, searchMenuApi} from '../services/appApi.js';
import ApiConfigClass  from '../../config/apiConfig';

var axios = require('axios');

function setLoading(loadingData, actionType) {
    return {
        type: actionType,
        loadingData
    }
}

function setCompLoading(loadingCompData, actionType) {
    return {
        type: actionType,
        loadingCompData
    }
}

function setCategoryLoading(actionType, id) {
    return {
        type: actionType,
        id
    }
}

function setSSOLoading(loadingData, actionType) {
    return {
        type: actionType,
        loadingData
    }
}

export function setMenu(data, actionType) {
    return {
        type: actionType,
        data
    }
}

export function apiError(error, STORE_PATH) {
    return {
        type: appConstants.ERROR,
        STORE_PATH,
        error
    }
}

export function apiRequest(STORE_PATH) {
    return {
        type: appConstants.REQUEST,
        STORE_PATH
    }
}

export function apiRequestData(STORE_PATH, data) {
    return {
        type: appConstants.REQUEST_DATA,
        STORE_PATH,
        data
    }
}

export function restToaster(STORE_PATH) {
    return {
        type: appConstants.RESET_TOASTER,
        STORE_PATH
    }
}

export function setMenuList(response) {
    return {
        type: response.actionType,
        list: response.list,
        entityId: response.entityId
        //STORE_PATH: response.STORE_PATH
    }
}

/**  resetError - Reset the modal window apiError message using STORE_PATH
 *
 * @param {string} STORE_PATH Store path of the component like DELETE_ACC_COMPONENT
 */
export function resetError(STORE_PATH) {
    return {
        type: appConstants.RESET_MODAL_ERROR_MSG,
        STORE_PATH
    }
}

/** apiSuccess
 * 
 * @param {string} STORE_PATH Component name - ADD_LABEL_COMPONENT 
 * @param {boolean} showSuccess if you want to show success notification
 * @param {boolean} isOpen if you want to close the panel on API success
 * @param {Object} data Data returned from API call
 */
export function apiSuccess(STORE_PATH, showSuccess = false, isOpen = false, data = {}) {
    return {
        type: appConstants.SUCCESS,
        STORE_PATH,
        showSuccess,
        isOpen,
        data
    }
}

export const loadingAction = (loadingData, type) => (dispatch) => {
    dispatch(setLoading(loadingData, type));
}

export const loadingCompAction = (loadingData, type) => (dispatch) => {
    dispatch(setCompLoading(loadingData, type));
}

export const loadingCategoryAction = (type, id) => (dispatch) => {
    dispatch(setCategoryLoading(type, id));
}

export const loadingSSOAction = (loadingData, type) => (dispatch) => {
    dispatch(setSSOLoading(loadingData, type));
}

export const logoutAction = (callback) => (dispatch) => {
    appApi( (response) => {
        callback && callback(response);
    });
}

export const getRolesAction = (actionData) => (dispatch) => {
    let STORE_PATH = actionData.storePath;
    getRoles( actionData.url, (response) => {
        if(response.error) {
            dispatch(apiError(response.error, STORE_PATH));
        } else {
            dispatch(setMenu(response, actionData.actionType));
        }
    });
}

export const setApiConfigAction = (callback) => (dispatch) => {
    axios.get('/config.json')
    .then((response) => {
        dispatch({type:'FETCH_CONFIG_FULFILLED', payload: {data: response.data, error: ''}});
        callback && callback();
    })
    .catch((err) => {
       dispatch({type:'FETCH_CONFIG_REJECTED', payload: {data: {}, error: err}});
    })
}

// Remove this method and replace with selectFetchingg
export function selectFetching(state, STORE_PATH) {
    return state.uploadReducer[STORE_PATH] ? state.uploadReducer[STORE_PATH].fetching: null;
}

export function selectUploadResp(state, STORE_PATH) {
    return state.uploadReducer[STORE_PATH] ? state.uploadReducer[STORE_PATH].data : '';;
}
 
// Remove this method and replace with selectError
export function selectUploadError(state, STORE_PATH) {
    return state.uploadReducer[STORE_PATH] ? state.uploadReducer[STORE_PATH].errMsg : null;
}

export function selectError(state, STORE_PATH) {
    return state.responseHandler[STORE_PATH] ? state.responseHandler[STORE_PATH].error : null;
}

export function selectFetchingg(state, STORE_PATH) {
    return state.responseHandler[STORE_PATH] ? state.responseHandler[STORE_PATH].fetching : null;
}

export function selectSuccess(state, STORE_PATH) {
    return state.responseHandler[STORE_PATH] ? state.responseHandler[STORE_PATH].success : null;
}

export function selectData(state, STORE_PATH) {
    return state.responseHandler[STORE_PATH] ? state.responseHandler[STORE_PATH].data : null;
}

export function checkToggle(state, STORE_PATH) {
    return state.responseHandler[STORE_PATH] ? state.responseHandler[STORE_PATH].isOpen : null;
}

export const addErrorAction = (errorMsg, storePath) => (dispatch) => {
    dispatch(apiError(errorMsg, storePath));
}

export const resetToasterAction = (storePath) => (dispatch) => {
    dispatch(restToaster(storePath));
}

export const resetErrorAction = (storePath) => (dispatch) => {
    dispatch(resetError(storePath));
}

export const uploadAction = (fileObj, STORE_PATH, apiPath, callback) => (dispatch) => {
    dispatch({ type: 'UPLOAD_REQUEST', STORE_PATH });
    uploadApi(fileObj, apiPath, (resp) => {
        if ((typeof resp.status !== 'undefined' && Math.floor(resp.status/100) !== 2)
            || typeof resp.message !== "undefined") {
            var errMsg = resp.data ? resp.data.errorMsg : resp.message;
            dispatch({ type: appConstants.UPLOAD_ERROR, STORE_PATH, errMsg});
            var data = {path: '', err: true, msg: errMsg};
            callback && callback(data);
        } else {
            var response = {path: resp["i256x200"], err: false},
            data = resp["i256x200"];
            callback && callback(response);
            dispatch({ type: appConstants.UPLOAD_SUCCESS, STORE_PATH, data });
        }
    });
}

export const removeFileAction = (STORE_PATH) => (dispatch) => {
    dispatch({ type: appConstants.REMOVE_FILE, STORE_PATH });
}

/* BEGIN: WEB SOCKET FOR ACTIVITI SERVICE */
export const setupSocket = (token) => {
    return function action(dispatch) {
        var url = ApiConfigClass.getData(appConstants.SOCKET_ROOT);
        const socket = new WebSocket(url+'/activities/ws')
    
        socket.onopen = () => {
        socket.send(JSON.stringify({
            type: "TOKEN",
            data: token
        }))
        }
        socket.onmessage = (event) => {
        const data = JSON.parse(event.data)
        switch (data.type) {
            case "NEW_ACTIVITY":
            //   dispatch(messageReceived(data.message, data.author))
                console.log('at socket new activity');
                break
            default:
                break
        }
        }
    }
}
/* END: WEB SOCKET FOR ACTIVITI SERVICE */

export const getSocketTokenAction = (callback) => (dispatch) => {
    generateSocketToken( (response) => {
        dispatch({ type: appConstants.SET_SOCKET_TOKEN, response });
        callback && callback();
    });
}

export const appLoader = () => (dispatch) => {
    let loadingData = {
        fetching: false,
        fetchingDetails: ""
    }
    dispatch({ type: appConstants.SHOW_APP_LOADER,  loadingData});
}

export const searchMenuAction = (menuDetails, callback) => (dispatch) => {
    let endPoint = !!menuDetails.endPoint ? menuDetails.endPoint : menuDetails.url ;
    let isPartnerIntegration = !!menuDetails.requestInfo && menuDetails.requestInfo.isPartnerIntegration ? true : false;
	let isDCM = !!menuDetails.requestInfo && menuDetails.requestInfo.isDCM ? true : false;
    searchMenuApi( endPoint, isPartnerIntegration, isDCM, (response) => {
        if(callback) {
            callback(response);
        } else {
            let {storePath, entityId, actionType} = menuDetails;
            if(response.error) {
                dispatch(apiError(response.error, storePath));
            } else {
                let getData = !!menuDetails.getData ? menuDetails.getData : menuDetails.requestInfo.getData;
            
                let data = {
                    entityId: entityId,
                    list: response[getData],
                    actionType: actionType,
                    STORE_PATH: storePath
                }
                dispatch(setMenuList(data));
            }
        }
    });
}
