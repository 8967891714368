import * as actionTypes from '../../constants/labelConstants';
import {GET_LABELS_OF_ENTITY} from '../../constants/accountConstants';
import {updateRow} from '../../helper/formHelper/updateForm';
import { processList } from '../../helper/formatData';

export default function labelReducer(state = {labelList: [], processedList: [], totalCount: -1, isUpdateRequired: true, labelScope: [], entityIds: '', labelsOfEntity: []}, action) {
    switch (action.type) {
        case actionTypes.SET_SCOPE:
            return {
                ...state,
                labelScope: action.list,
            }
        case GET_LABELS_OF_ENTITY:
            // org/brand changed so get new labels
            if(action.entityId) {
                return {
                    ...state,
                    labelsOfEntity: action.list,
                    entityIds: action.entityId
                }
            } 
        case actionTypes.LIST_LABEL_COMPONENT:
            let processedList;
            if(action.list.totalCount > 0) {
                processedList = setKeys(action.list.labels);
            }
            return {
                ...state,
                labelList: action.list.labels,
                processedList: processedList,
                isUpdateRequired: false,
                totalCount: action.list.totalCount
            }
        case actionTypes.ADD_LABEL:
            let newLabel = state.labelList && state.labelList.length ? [
                    ...state.labelList,
                    action.list
                ] : action.list
            return {
                ...state,
                labelList: newLabel
            }
        case actionTypes.UPDATE_ROW:
            const updatedList = updateRow({list: state.labelList, rowId:action.rowId, requestData:action.requestData}); 
            return {
                ...state,
                labelList: updatedList,
                processedList: setKeys(updatedList),
                isUpdateRequired: false,
            }
        case actionTypes.SET_UPDATE_REQUIRED:
            return {
                ...state,
                isUpdateRequired: true
            }
        default:
            return state;
    }
}

/**
 * set keys to process the list
 * @param {Array} list 
 */
function setKeys(list) {
    let pickKeys = ['type', 'name', 'scope', 'brands', 'organizations', 'labels'];
    return processList(list, pickKeys);
}