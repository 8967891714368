import { getInviteType } from '../../../helper/reducer/reducerUtil';
import { updateRow } from '../../../helper/formHelper/updateForm';

import {PENDING_INVITES_LIST, DELETE_PENDING_INVITE, PENDING_INVITE_UPDATE_REQUIRED, UPDATE_PENDING_INVITE_ROW} from '../../../constants/partnerConstants';

export default function pendingInviteReducer(state = {pendingInvites: [], isUpdateRequired: true, totalCount: -1, inviteType: 0, isAccepted: false}, action) {
    switch (action.type) {
        case PENDING_INVITES_LIST:
            let count = 0;
            let totalCount = action.data.totalCount;
            let pendingInvites = action.data.invitations;
            let inviteType = getInviteType(pendingInvites);

            return {
                ...state,
                pendingInvites: action.data.invitations,
                isUpdateRequired: false,
                totalCount: totalCount,
                inviteType: inviteType
            }
        case PENDING_INVITE_UPDATE_REQUIRED:
            return {
                ...state,
                isUpdateRequired: true
            }
        case DELETE_PENDING_INVITE:
             return deleteInvite(state,action);
        case UPDATE_PENDING_INVITE_ROW:
             return updatePendingInviteRow(state,action);
        default:
            return state;
    }
}

function deleteInvite(pendingInviteState = {}, action) {
    let pendingInvites = [...pendingInviteState.pendingInvites];
    let filteredList = pendingInvites.filter((invite, index) => {
         return invite.id !== action.data.requestInfo.invitationId
    });

    let totalCount = filteredList.length;
    let inviteType = getInviteType(filteredList);
 
     return {
         pendingInvites: filteredList,
         totalCount: totalCount,
         inviteType: inviteType
     }
 }
 
 function updatePendingInviteRow(pendingInviteState = {},action){
    let pendingInvites = [...pendingInviteState.pendingInvites];
    const updatedList = updateRow({list: pendingInvites, rowId:action.data.rowId, requestData:action.data.updateData});
    return{
        pendingInvites: updatedList,
        totalCount:updatedList.length,
        inviteType: getInviteType(updatedList)
    } 
 }