import React from 'react';

import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';

import './toggleBar.css';

const CustomSwitch = withStyles({
  switchBase: {
    color:'#ffff',
    '&$checked': {
      color: 'var(--primary-color)',
    },
    '&$checked + $track': {
      backgroundColor: 'var(--primary-color)'
    },
  },
  checked: {},
  track: {},
})(Switch);

export default function ToggleBarComp(props) {
	const handleChange = name => event => {
		props.toggleSwitch(event.target.checked);
  	};
	return (
		<div>
			<CustomSwitch
				checked={props.switched}
				onChange={handleChange('checkedA')}
				disabled={props.disabled}
				value="checkedA"
			/>
		</div>
	);	
}