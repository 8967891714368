import React, { Component } from 'react';
import './errBoundary.css';
import {isEmpty} from '../../helper/commonFunc';
import { detect } from 'detect-browser';
const browser = detect();

class ErrorBoundary extends Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
    componentDidCatch(error, info) {
        //You can also log the error to an error reporting service
        //logErrorToMyService(error, info);
    }
  
    render() {
        var template = '', browserSupport = true;

        if(browser) {
            if(browser.name === 'chrome' && browser.version < 49) {
                browserSupport = false;
            } else if(browser.name === 'firefox' && browser.version < 59) {
                browserSupport = false;
            } else if(browser.name === 'safari' && browser.version < 11) {
                browserSupport = false;
            } else if(browser.name === 'ie' && browser.version < 11) {
                browserSupport = false;
            } else if(browser.name === 'edge' && browser.version < 16) {
                browserSupport = false;
            }
        }
       
        if(!browserSupport) {
            template = 
            <div className="err-main">
                <div className="err-content">
                    <i className="browser-err-icon far fa-exclamation-circle"></i>
                    <br />
                    <h4 className="err-header">An error occurred. </h4>
                    <p className="lower-browser-msg">
                        Sorry, EAM is not supported in your browser
                        <strong> {browser.name}</strong> version 
                        <strong> {browser.version}</strong>. 
                        We're crying deeply inside too. Upgrade below for free.
                    </p>
                    <br />
                    <div>
                        <a target="_blank" href='http://www.firefox.com/download'>Firefox</a> |
                        <a target="_blank" href='http://www.google.com/chrome'> Chrome</a> |
                        <a target="_blank" href='https://support.apple.com/en-us/HT204416'> Safari</a> |
                        <a target="_blank" href='http://windows.microsoft.com/en-us/internet-explorer/download-ie'> Internet Explorer</a>
                    </div>
                </div>
            </div>
            return template;
        } else if(this.state.hasError || this.props.error) {
            // You can render any custom fallback UI
            let redirectTo = window.location.protocol+'//'+window.location.hostname+(window.location.port ? ':'+window.location.port: '');
            return ( 
                <div className="err-main">
                    <div className="err-content">
                        <i className="err-icon far fa-exclamation-circle"></i>
                        <br />
                        <h4 className="err-header">{this.props.error ? this.props.error : 'An error occurred.'} </h4>
                        <p>Please ensure that the system is connected to internet and <a href={redirectTo}> reload</a>.</p> <p>Contact <a href="mailto:support@jivox.com">support@jivox.com</a> for further assistance.</p>
                    </div>
                </div>  
            )
        }
        return this.props.children;
    }
}

export default ErrorBoundary;