//sections
export const VIEWABILITY_TRACKER = "Viewability Trackers";
export const THIRDPARTY_TRACKER = "Thirdparty Trackers";
export const DSP_TAG_INTEGRATIONS = "DSP Tag Integrations";

//Actions
export const TRACKER_CONFIG_LIST = "TRACKER_CONFIG_LIST"; 
export const TRACKER_CONFIG_LIST_UPDATE_REQUIRED = "TRACKER_CONFIG_LIST_UPDATE_REQUIRED";
export const DELETE_TRACKER = "DELETE_TRACKER";
export const CURRENT_TRACKER_CONFIG = "CURRENT_TRACKER_CONFIG";
export const SAVE_CONFIGURATION = "SAVE_CONFIGURATION";
export const GET_SITE_DETAILS = "GET_SITE_DETAILS";
export const RESET_CURRENT_CONFIG = "RESET_CURRENT_CONFIG";
export const SET_TRACKER_DETAILS = "SET_TRACKER_DETAILS";

//Trackers Card
export const CARD_COMP = "CARD_COMP";

// GCM TRACKER CONFIG
export const SAVE_DCM_CONFIGURATION = "SAVE_DCM_CONFIGURATION";
export const GET_ADVERTISER_DETAILS = "GET_ADVERTISER_DETAILS";
export const GET_CAMPAIGN_DETAILS = "GET_CAMPAIGN_DETAILS";

//DV360 Tracker config
export const SAVE_DV360_CONFIGURATION = "SAVE_DV360_CONFIGURATION";
export const GET_ADVERTISER_DETAILS_DV360 = "GET_ADVERTISER_DETAILS_DV360";

//INPUT OPTIONS
export const MAP_EXTERNAL_PLACEMENT = "Map To External Placement Id";
export const JIVOX_MACRO = "Jivox Macro";
export const DSP_MACRO = "DSP Macro";
export const CONSTANT_VALUE = "Constant Value";
export const PLATFORM_DEFINED = 'Platform Defined'

//MACROS
export const JVX_CAMPAIGNID =  "%JVX_CAMPAIGNID%";
export const JVX_SITEID =  "%JVX_SITEID%";
export const JVX_PLACEMENTID =  "%JVX_PLACEMENTID%";
export const JVX_GROUPID =  "%JVX_GROUPID%";
export const JVX_SEGNAME =  "%JVX_SEGNAME%";
export const JVX_UUID =  "%JVX_UUID%";
export const JVX_IMPRESSION_ID =  "%%JVX_IMPRESSION_ID%%";

//EXTERNAL PLACEMENT ID
export const externalPlacementId = "{{external_placement_id}}";

//success
export const TRACKER_DELETE_SUCCESS = "The configuration was deleted successfully";
export const SAVE_CONFIGURATION_SUCCESS = "The tracker configuration was successful";
export const SAVE_DCM_CONFIGURATION_SUCCESS = "Media Plan has been imported successfully and uploaded to the Jivox Platform.";
export const UPDATE_DCM_CONFIGURATION_SUCCESS = "Media Plan has been updated successfully and updated in the Jivox Platform";
export const SAVE_DV360_CONFIGURATION_SUCCESS = "DV360 Configuration saved successfully.";