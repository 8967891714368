import React, { Component } from "react";
import Select, { components } from "react-select";

const Option = props => (
  <div>
    <components.Option {...props}>
      <input type="checkbox" checked={props.isSelected} onChange={() => null} />{" "}
      <label>{props.data.name}</label>
    </components.Option>
  </div>
);

const MultiValue = props => (
  <components.MultiValue {...props}>
    <span>{props.data.name}</span>
  </components.MultiValue>
);

class CheckedSelect extends Component {
  render() {
    const { options, index, ...otherProps } = this.props;
    let { onChangeCallback, onInputChange, getOptionValue, getOptionLabel } = this.props.parent;
    return (
      <Select
        placeholder=""
        closeMenuOnSelect={false}
        isMulti
        components={{ Option, MultiValue }}
        options={options}
        hideSelectedOptions={false}
        backspaceRemovesValue={false}
        onChange={(e) => onChangeCallback(e, index)}
        onInputChange={(e) => onInputChange(e, index)}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        {...otherProps}
      />
    );
  }
}

export default CheckedSelect;
