import {isEmpty} from './commonFunc';
import {isPlainObject} from "lodash";

/**
 * joinValues - Looping a list to get the formatted data  
 * @param {Array} options options in a selectBox
 * @param {Array} prop joining the array from props
 */
export function joinValues(options, prop) {
    var arr = [];
    for(let option in options) {
        if(prop) {
            arr.push(options[option][prop]);
        } else {
            arr.push(options[option].id);
        }
    }
    return arr;
}

/**
 * getValue - Get object value prop or id prop
 * @param {Object} obj 
 */
export function getValue(obj) {
    return obj.value || obj.id;
}

/**
 * joinPair: Join a pair with comma
 * @param {String} item1 
 * @param {String} item2 
 */
export function joinPair(value1, value2) {
    if(value1 && value2) {
        return `${value1},${value2}`;
    } else if(value1) {
        return value1;
    } else {
        return value2;
    }
}

/**
 * processList - Looping a list to get the formatted data 
 * @param {ArrayOFObjects} rows 
 * @param {Number} actionBtnIndex zero based positioning of action buttons
 * @param {Array} keys List of keys you want to skip from the back end response 
 */
export const processList = (rows, pickKeys, missingKeys = []) => {
    const list = rows.map((row, index) => {
        let rowData = [];
        
        for(let key of pickKeys){
            if(row.hasOwnProperty(key)){
                rowData.push(formatData(row[key]));
            } else {
                rowData.push(null);
            }
            
        }
        //Pushing index to data for refering the object in customBodyRenderer.
        if(missingKeys.length) {
            for(let key of missingKeys){
                rowData.push(index);
            }
        } else {
            // where missingKeys not defined, assuming only one column is missing
            rowData.push(index);   
        }
        return rowData;
    });
    return list;
}

/**
 * skipKeys keys you want to skip from the back end response 
 * @param {Array} keys 
 * @param {String} currentKey 
 */
export function skipKeys(keys, currentKey) {
    if(keys.indexOf(currentKey) !== -1) {
        return true;
    }
}

/**
 * formatData formatData based upon type 
 * @param {AnyRecord} value each item in a row 
 */
export function formatData(value) {
    let typeOf = typeof value === 'object' ? 'joinObj' : value;
    switch(typeOf) {
        case 'joinObj':
            // for object access name directly no need to join
            let arrNames = isEmpty(value) ? [] : isPlainObject(value) ? value.name : joinValues(value, 'name');
            if(typeof arrNames !== "string") {
                arrNames = arrNames.length > 1 ? arrNames.join(', ') : arrNames.length === 1 ? value[0].name : null;
            }
            return arrNames;
        default:
            return value;
    }
}

export function parseURL(url) {
    var parser = document.createElement('a'),
        searchObject = {},
        queries, split, i;
        
    // Let the browser do the work
    parser.href = url;

    // Convert query string to object
    queries = parser.search.replace(/^\?/, '').split('&');
    for( i = 0; i < queries.length; i++ ) {
        split = queries[i].split('=');
        searchObject[split[0]] = split[1];
    }
    return {
        protocol: parser.protocol,
        host: parser.host,
        hostname: parser.hostname,
        port: parser.port,
        pathname: parser.pathname,
        search: parser.search,
        searchObject: searchObject,
        hash: parser.hash.substr(1)
    };
}


/**
 * Utility function to get query parameters from a URL.
 * Returned list is of the format [ [key1, value1], [key2,value2] ]
 * @param {Stringq} URL URL whose parameters need to be extracted
 * @param {boolean} unique if the return list of parameters should be unique
 * @param {boolean} isMoatURL if the URL follows moat URL's format
 * @returns list of parameters
 */
export const getURLParameters = (URL, unique=false, isMoatURL=false) => {
    const parsedURL = parseURL(URL);
    let searchString = parsedURL.search.replace(/^\?/, '');
    if(isMoatURL) { 
        // Sample moat url:
        // https://z.moatads.com/<client-code>/moatad.js#
        searchString = parsedURL.hash;
    }
    const queryParams = searchString.split('&')
        .map(query => {
            if(!query.includes('=')) return [ '', '' ]
            const [ key ='', value='' ] = query.split('=');
            let updatedKey = '', updatedValue='';
                try {
                    updatedKey = decodeURIComponent(key);
                } catch(error) {
                    updatedKey = key;
                    console.log(error.toString(), key)
                }
                try {
                    updatedValue = decodeURIComponent(value);
                } catch(error) {
                    updatedValue = value;
                    console.log(error.toString(), value);
                }
                return [ updatedKey, updatedValue ];
        }) //Construct an array of [key, value] items
        .filter(([ key ]) => key.trim() !== ''); // Filter empty params
    
    if(unique) {
        const uniqueQueryParams = queryParams.reduce((prev, [key, value]) => ({
            ...prev,
            [key]: value
        }), {}) // Remove duplicate parameters
        return uniqueQueryParams
    }
    return queryParams;
}