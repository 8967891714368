import {http} from '../../helper/commonFunc.js';
import { func } from 'prop-types';

export function dashboardApi(actionCallback) {
    http('get', '/profile', {}, actionCallback);
}

export function dashboardApiSSODetails(orgId, actionCallback){
    http('get', '/accounts/org/'+orgId, {}, actionCallback);
}

export function sharedEntitityApi(orgId, actionCallback){
    http('get', `/accounts/brand/${orgId}/data/shared-entities`, {}, actionCallback);
}

export function shareBrandDataApi(postData, brandId, actionCallback) {
    http('patch', `/accounts/brand/${brandId}/data`, postData, actionCallback);
}

export function dashboardApiSetSSODetails(accId, data, actionCallback) {
    var formData = new FormData();
    var dataArr = Object.keys(data);
    for(var key in dataArr){
        if(data[dataArr[key]] !== undefined){
            formData.append(dataArr[key], data[dataArr[key]]);
        }
    }
    http('put', '/accounts/org/'+accId, formData, actionCallback);
}

export function activitiApi(postData, actionCallback) {
    let url =  `${postData.url}?offset=${postData.offset}&limit=${postData.limit}&filters=action.opCode<>LIST_ACCESS`;
     http('get', url, {}, actionCallback);
}

export function dashboardApiCoBrandDetails(actionCallback){
    http('get', '/whiteLabelling/json', {}, actionCallback);
}

export function dashboardApiCoBrandDetailsRMA(actionCallback){
    http('get', '/whiteLabelling/json?scope=rma', {}, actionCallback);
}

export function dashboardApiResetCoBrandDetails(orgId, scope, actionCallback){
    http('delete', '/accounts/org/'+orgId+'/whiteLabelling?scope='+scope, {}, actionCallback);
}

export function dashboardApiResetCoBrandDetailsRMA(orgId, scope, actionCallback){
    http('delete', '/accounts/org/'+orgId+'/whiteLabelling?scope='+scope, {}, actionCallback);
}

export function dashboardApiSetCoBrandDetails(accId, data, isRM, actionCallback) {
    var formData = new FormData();
    formData.append('selectorOptions', JSON.stringify(data.cssObj));
    if(!isRM){
        if(data.fontFileObj.name !== undefined && data.fontFileObj.name !== ""){
            formData.append('fontFileObj', data.fontFileObj);
        }
        if(data.logoFileObj.name !== undefined && data.fontFileObj.name !== ""){
            formData.append('logoFileObj', data.logoFileObj);
        }
        formData.append('fontName', data.fontName);
        data.theme = {
            ...data.theme,
            footerConfig: JSON.stringify(data.footerConfig),
            brandTitle: data.brandTitle
        }
    } else {
        if(data.rm_fontFileObj.name !== undefined && data.rm_fontFileObj.name !== ""){
            formData.append('fontFileObj', data.rm_fontFileObj);
        }
        if(data.rm_logoFileObj.name !== undefined && data.rm_logoFileObj.name !== ""){
            formData.append('logoFileObj', data.rm_logoFileObj);
        }
        if(data.rm_faviconObj.name !== undefined && data.rm_faviconObj.name !== ""){
            formData.append('favIconObj', data.rm_faviconObj);
        }
        if(data.rm_iconStyleSheetObj.name !== undefined && data.rm_iconStyleSheetObj.name !== ""){
            formData.append('iconStyleSheetObj', data.rm_iconStyleSheetObj);
        }
        formData.append('fontName', data.rm_fontName);
        data.theme = {
            ...data.theme,
            footerConfig: JSON.stringify(data.rm_footerConfig),
            brandTitle: data.rm_brandTitle
        }
    }
    formData.append('varOptions', JSON.stringify(data.theme));
    formData.append('scope', isRM? 'rma':'eam');
    http('put', '/accounts/org/'+accId+'/whiteLabelling', formData, actionCallback);
}
