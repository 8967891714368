import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-bootstrap-modal';
import {isEmpty} from '../helper/commonFunc';
import { settingsChangePasswordAction } from './action/changePassworsAction.js';
import { updatePersonAction } from './action/updatePersonAction.js';
import { loadingCompAction, 
        logoutAction,
        selectUploadResp,
        selectUploadError,
        selectFetching,
        uploadAction, 
        removeFileAction
    } from '../app/actions/appAction';
import { userInfoAction } from '../dashboard/actions/dashboardAction';
import Tooltip from '../commonUI/tooltip/tooltip.js'
import Input from '../commonUI/input/input.js';
import {FormError} from '../commonUI/Form/FormError';
import { UPDATE_BUTTON_FOR_MODAL } from '../constants/appConstants.js';
import * as actionTypes from '../constants/settingsConstants.js';
import Loader from '../commonUI/loader/withLoader';
import {CreateNotification} from '../commonUI/notification/reactNotification.js';
import {logoutResp, uploadHelper, uploadCallback, checkFileExtension, validateSize} from '../helper/commonFunc';
import './settingsModal.css';

const mapDispatchToProps = (dispatch) => {
    return {
        submitForm: (postData, callback) => {
            dispatch(settingsChangePasswordAction(postData, callback));
        },
        loadingComponent: (loadingData, type) => {
            dispatch(loadingCompAction(loadingData, type));
        },
        submitSettingsForm: (postData, callback) => {
            dispatch(updatePersonAction(postData, callback));
        },
        getUserInfo: () => {
            dispatch(userInfoAction());
        },
        logoutAction: (callback) => {
            dispatch(logoutAction(callback));
        },
        upload: (fileObj, storePath, apiPath, callback) => {
            dispatch(uploadAction(fileObj, storePath, apiPath, callback));
        },
        removeFile: (storePath) => {
            dispatch(removeFileAction(storePath));
        }
    };
}

const mapStateToProps = (state) => {
    return {
        accDetails: state.dashboardReducer.accDetails,
        loadingCompData: state.appReducer.loadingCompData,
        uploadErrorResp: selectUploadError(state, 'personsettings'),
        uploadResponse: selectUploadResp(state, 'personsettings'),
        uploading: selectFetching(state, 'personsettings')
    }
}

var  toolTipImageSource, toolTipImageFileName, tooltipId, 
    rules = {
        display: 'block'
    };

class SettingsModalComp extends Component{
    constructor(props) {
        super(props);
        this.submitHandler = this.submitHandler.bind(this);
        this.submitResp = this.submitResp.bind(this);
        this.handleClickChangePassword = this.handleClickChangePassword.bind(this);
        this.state = {
            showUploadModal: false,
            showCancel: 0,
            toolTipShow: false,
            settingsForm: {
                firstName: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'text',
                        label: 'First Name',
                        spanclass: 'far fa-user form-control-feedback left',
                        divclass: 'user-input-wrp has-feedback form-group form-group-div col-md-6 col-sm-12 col-xs-12'
                    },
                    value: props.accDetails.firstName,
                    validation: {
                        required: true,
                        format: 'string'
                    },
                    valid: false,
                    touched: false
                },
                lastName: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'text',
                        label: 'Last Name',
                        spanclass: 'far fa-user form-control-feedback left',
                        divclass: 'user-input-wrp has-feedback form-group form-group-div col-md-6 col-sm-12 col-xs-12'
                    },
                    value: props.accDetails.lastName,
                    validation: {
                        required: true,
                        format: 'string'
                    },
                    valid: false,
                    touched: false
                },
                email: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'email',
                        label: 'Email Address',
                        readOnly: true,
                        spanclass: 'far fa-envelope form-control-feedback left optional',
                        divclass: 'user-input-wrp has-feedback form-group form-group-div col-xs-12'
                    },
                    value: props.accDetails.primaryEmail,
                    validation: {
                        required: false
                    },
                    valid: true,
                    touched: false
                },
                primaryPhone: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'text',
                        label: 'Primary Phone',
                        spanclass: 'far fa-phone form-control-feedback left optional',
                        divclass: 'user-input-wrp has-feedback form-group form-group-div col-xs-12'
                    },
                    value: props.accDetails.primaryPhone,
                    validation: {
                        required: false
                    },
                    valid: true,
                    touched: false
                },
                addressLine1: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'text',
                        label: 'Address Line1',
                        spanclass: 'far fa-address-card form-control-feedback left optional',
                        divclass: 'user-input-wrp has-feedback form-group form-group-div col-md-6 col-sm-12 col-xs-12'
                    },
                    value: props.accDetails.addressLine1,
                    validation: {
                        required: false
                    },
                    valid: true,
                    touched: false
                },
                addressLine2: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'text',
                        label: 'Address Line2',
                        spanclass: 'far fa-address-card form-control-feedback left optional',
                        divclass: 'user-input-wrp has-feedback form-group form-group-div col-md-6 col-sm-12 col-xs-12'
                    },
                    value: props.accDetails.addressLine2,
                    validation: {
                        required: false
                    },
                    valid: true,
                    touched: false
                },
                country: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'text',
                        label: 'Country',
                        spanclass: 'far fa-map-marker-alt form-control-feedback left optional',
                        divclass: 'user-input-wrp has-feedback form-group form-group-div col-md-6 col-sm-12 col-xs-12'
                    },
                    value: props.accDetails.country,
                    validation: {
                        required: false
                    },
                    valid: true,
                    touched: false
                },
                state: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'text',
                        label: 'State',
                        spanclass: 'far fa-map-marker-alt form-control-feedback left optional',
                        divclass: 'user-input-wrp has-feedback form-group form-group-div col-md-6 col-sm-12 col-xs-12'
                    },
                    value: props.accDetails.state,
                    validation: {
                        required: false
                    },
                    valid: true,
                    touched: false
                },
                city: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'text',
                        label: 'City',
                        spanclass: 'far fa-map-marker-alt form-control-feedback left optional',
                        divclass: 'user-input-wrp has-feedback form-group form-group-div col-md-6 col-sm-12 col-xs-12'
                    },
                    value: props.accDetails.city,
                    validation: {
                        required: false
                    },
                    valid: true,
                    touched: false
                },
                zipCode: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'text',
                        label: 'Zip Code',
                        spanclass: 'far fa-map-marker-alt form-control-feedback left optional',
                        divclass: 'user-input-wrp has-feedback form-group form-group-div col-md-6 col-sm-12 col-xs-12'
                    },
                    value: props.accDetails.zipCode,
                    validation: {
                        required: false
                    },
                    valid: true,
                    touched: false
                },
                image: {
                    elementType: 'file',
                    elementConfig: {
                        type: 'text',
                        label: '',
                        disabled: 'disabled',
                        readOnly: true,
                        spanclass: 'far fa-image form-control-feedback left optional',
                        divclass: 'user-input-wrp has-feedback form-group form-group-div col-xs-12'
                    },
                    value: '',
                    url: '',
                    validation: {
                        required: false
                    },
                    valid: true,
                    acceptFileType:"image/*",
                    touched: false
                },
                oldPassword: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'password',
                        label: 'Current Password',
                        readOnly: false,
                        spanclass: 'far fa-key form-control-feedback left',
                        divclass: 'user-input-wrp has-feedback form-group form-group-div col-xs-12'
                    },
                    value: '',
                    validation: {
                        required: true
                    },
                    valid: false,
                    touched: false
                },
                newPassword: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'password',
                        label: 'New Password',
                        readOnly: false,
                        spanclass: 'far fa-key form-control-feedback left',
                        divclass: 'user-input-wrp has-feedback form-group form-group-div col-xs-12'
                    },
                    value: '',
                    validation: {
                        required: true,
                        format: 'password'
                    },
                    valid: false,
                    touched: false
                },
                confirmPassword: {
                    elementType: 'input',
                    elementConfig: {
                        type: 'password',
                        label: 'Confirm Password',
                        readOnly: false,
                        spanclass: 'far fa-key form-control-feedback left',
                        divclass: 'user-input-wrp has-feedback form-group form-group-div col-xs-12'
                    },
                    value: '',
                    validation: {
                        required: true
                    },
                    valid: false,
                    touched: false
                }
            },
            show: false,
            formIsValid: false,
            isUpdatingPassword: false,
            currentRef: '',
            styleObj: rules,
            modalErr: ''
        }
    }
    inputChangedHandler = (event, inputIdentifier, selection) => {
        const updateSettingsForm = {
            ...this.state.settingsForm
        };
        const updatedFormElement = { 
            ...updateSettingsForm[inputIdentifier]
        };

        updatedFormElement.value = event ? event.target.value : selection;
        updatedFormElement.valid = this.checkValidity(updatedFormElement.value, updatedFormElement.validation);
        updatedFormElement.touched = true;
        updateSettingsForm[inputIdentifier] = updatedFormElement;
        
        var formValid = true;
        for (var inputIden in updateSettingsForm) {
            if(updateSettingsForm[inputIden].title !== "Optional") {
                formValid = updateSettingsForm[inputIden].valid && formValid;
            }
        }
        this.setState({settingsForm: updateSettingsForm, formIsValid: formValid});    
    }
    submitHandler = () => {
        var formValid = true, formData = {}, noErr = true;

        for (let formElementIdentifier in this.state.settingsForm) {
            let fieldValid = true;
            if(this.state.settingsForm[formElementIdentifier].elementConfig.type === "password") {
                fieldValid = this.checkValidity(this.state.settingsForm[formElementIdentifier].value, this.state.settingsForm[formElementIdentifier].validation);
                formValid = fieldValid && formValid;
                
                formData[formElementIdentifier] = this.state.settingsForm[formElementIdentifier].value;
                let divId = 'div'+formElementIdentifier;
                noErr = this.setErrMsg(fieldValid, divId, this.state.settingsForm[formElementIdentifier].value); 

                if(!noErr) {
                    formValid = false;
                }
            }
        }

        if(formValid) {
            let loadingData = {
                loadingComp: true, 
                loadingDetails: 'updating please wait..'
            }
            this.props.loadingComponent(loadingData, actionTypes.SHOW_UPDATE_PERSON_LOADER);
            this.props.submitForm(formData, this.submitResp.bind(this));
        }
    }
    handleClickChangePassword(event){
        if(event.currentTarget.className.indexOf('change-password') > -1){
            document.getElementsByClassName('general')[0].classList.remove('setting-active');
            document.getElementsByClassName('change-password')[0].classList.add('setting-active');
            this.setState({ 
                isUpdatingPassword: true
            });
        } else {
            document.getElementsByClassName('general')[0].classList.add('setting-active');
            document.getElementsByClassName('change-password')[0].classList.remove('setting-active');
            this.setState({ 
                isUpdatingPassword: false
            });
        }
    }
    submitPersonDetails = () => {
        var formValid = true, formData = {}, noErr = true;
        for (let formElementIdentifier in this.state.settingsForm) {
            var fieldValid = true;
            if(formElementIdentifier === "image") {
                formData[formElementIdentifier] = this.props.uploadResponse;
            } else if(this.state.settingsForm[formElementIdentifier].elementConfig.type !== "password") {
                fieldValid = this.checkValidity(this.state.settingsForm[formElementIdentifier].value, this.state.settingsForm[formElementIdentifier].validation);
                formValid = fieldValid && formValid;

                formData[formElementIdentifier] = this.state.settingsForm[formElementIdentifier].value;
                let divId = 'div'+formElementIdentifier;
                noErr = this.setErrMsg(fieldValid, divId, this.state.settingsForm[formElementIdentifier].value); 

                if(!noErr) {
                    formValid = false;
                }
            }
        }

        if(formValid) {
            let loadingData = {
                loadingComp: true, 
                loadingDetails: 'updating please wait..'
            }
            this.props.loadingComponent(loadingData, actionTypes.SHOW_UPDATE_PERSON_LOADER);
            this.props.submitSettingsForm(formData, this.submitPersonResp.bind(this));
        }
    }

    setErrMsg(isValid, divId, value) {
        if(!isValid) {  
            let msg = '';
            
            if(!value) {
                msg = "Please fill this field";
            } else if((divId === "divfirstName" || divId === "divlastName" )) {
                msg = "Please enter a valid name";
            } else if(divId === "divnewPassword") {
                msg = `Password length must be between 8 and 99 char, required atleast one upper case, 
                lower case, numbers, special chars among ^ $ * . [ ] { } ( ) ? - " ! @ # % & / , > < ' : ; | _ ~ \`!`;
            }
            document.getElementById(divId).lastChild.innerHTML = msg; 
            document.getElementById(divId).firstChild.className = "far fa-exclamation-triangle span-error ";
            return false;
        } else {
            if(this.state.settingsForm["newPassword"].value !== this.state.settingsForm["confirmPassword"].value && divId === "divnewPassword") {
                document.getElementById("divnewPassword").lastChild.innerHTML = 'Password and confirm password does not match'; 
                document.getElementById("divnewPassword").firstChild.className = "far fa-exclamation-triangle span-error ";
                return false;
            } else {
                document.getElementById(divId).lastChild.innerHTML = ''; 
                document.getElementById(divId).firstChild.className = '';
                return true;
            }
        }
    }

    submitResp(response) {
        let data = {
            alertMsg: '',
            type: '',
            show: false,
            alertClass: 'hide-alert slidedown'
        }
        let loadingData = {
            loadingComp: false, 
            loadingDetails: ''
        }
        this.props.loadingComponent(loadingData, actionTypes.SHOW_UPDATE_PERSON_LOADER);
        
		if(!isEmpty(response) && (Math.floor(response.status/100 || typeof response.message !== "undefined") !== 2)) {
            this.setState({ //error messages are received as response.error, so handle that case as well
				modalErr: response.data || response.error ? (response.error || response.data.errorMsg) : response.message
		   	})
		} else {
            data.alertMsg = "Password Changed Successfully!";
            data.type = "success";
            this.props.onHide();
            CreateNotification(data);
            this.props.logoutAction(logoutResp.bind(this));
        }
    }

    submitPersonResp(response) {
        let data = {
            alertMsg: '',
            type: '',
            show: false,
            alertClass: 'hide-alert slidedown'
        }
        let loadingData = {
            loadingComp: false, 
            loadingDetails: ''
        }
        this.props.loadingComponent(loadingData, actionTypes.SHOW_UPDATE_PERSON_LOADER);
        
		if((typeof response.status !== 'undefined' && Math.floor(response.status/100) !== 2) 
            || typeof response.message !== "undefined") {
            this.setState({
				modalErr: response.data ? response.data.errorMsg : response.message
		   	})
		} else {
            data.alertMsg = "Profile has been successfully updated!";
            data.type = "success";
            this.props.onHide();
            CreateNotification(data);
            this.props.getUserInfo();
        }
    }
    fileUpload(e) {      //Upload logo image
        var fileName = e.target.files[0].name,
        fileObj = e.target.files[0],
        isValid = checkFileExtension(fileName),
        fileSize = validateSize(fileObj),
        inputId = e.target.id;
    
        if(isValid && fileSize) {
            var reader = new FileReader();
            reader.readAsDataURL(fileObj) //insert the file into reader
            reader.onloadend = (event) => {
                let fileRead = uploadHelper(event);
                if(fileRead) {
                    this.inputChangedHandler(null, "image", fileName);
                    this.props.upload(fileObj, 'personsettings', 'uploadUserImage', uploadCallback.bind(this, inputId, this.afterUpload)); // API
                }
            };
        }
    }
    afterUpload = () => {
        this.inputChangedHandler(null, "image", '');
    }
    removeLogoImage = () => {
        this.setState({toolTipShow: false});
        this.inputChangedHandler(null, "image", '');
        this.props.removeFile('personsettings');
    }
    openImageInNewWindow = () => {
        var source = document.getElementById('logoPreview');
        window.open(source.getAttribute('src'));
    }
    showToolTip = (e) => {
        toolTipImageSource = e.target.getAttribute('src');
        toolTipImageFileName = e.target.getAttribute('alt');
        tooltipId = e.currentTarget.getAttribute('data-for');
        this.setState({toolTipShow: true});
    }
    checkValidity(value, rules) {
        let isValid = true;
        
        if (rules.required) {
            if(typeof value === "number"){
                isValid = value && value.trim() !== '' && isValid;
            }
            
            if(rules.format === "string") {
                let stringValid = /^[A-Za-z\s]+$/.test(value);

                if(!stringValid) {
                    isValid = false;
                }
            } else if(rules.format === "password") {
                let strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*[\\.|?({})\]\-",><':;_~`/])(?=.{8,99}$)/;
        
                if(strongRegex.test(value)) {
                    isValid = true;
                } else {
                    isValid = false;
                }
            }
        } else if(rules.length) {
            isValid = rules.indexOf(value.toUpperCase()) >= 0;
        }
        return isValid;
    }
    render(){
        let toolTip = null;
        if(this.state.toolTipShow){
            toolTip = <Tooltip cssclass={"image-preview"} source={<div><img id="logoPreview" src={toolTipImageSource} alt={toolTipImageFileName}/><span className="img-name" onClick={this.openImageInNewWindow}>{toolTipImageFileName}</span>&nbsp;<i onClick={this.openImageInNewWindow} className="img-name far fa-external-link"></i></div>} id={tooltipId} position={'top'}/>
        }else{
            toolTip = "";
        }

        const formElementsArray = [];
        for (let key in this.state.settingsForm) {
            formElementsArray.push({
                id: key,
                config: this.state.settingsForm[key]
            });
        }
        return (
            <Modal show={this.props.show} onHide={this.props.onHide} dialogClassName="settings-dialog">
                <Modal.Header closeButton>
                    <Modal.Title><p className="modal-title"><span><i className="far fa-cog"></i>&nbsp;Settings<span className = 'requiredFieldModal'> Required Information </span></span></p></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {toolTip}
                    <div className="settings-container">
                        <div className="setting-tabs">
                            <div className="setting-tab general setting-active" onClick={this.handleClickChangePassword}><div className="setting-tab-box">General</div></div>
                            <div className="setting-tab change-password" onClick={this.handleClickChangePassword}><div className="setting-tab-box">Change Password</div></div>
                        </div>
                        <div style={{display: this.state.isUpdatingPassword ? 'none' : 'inline'}}>
                            <div className={this.props.loadingCompData.loadingComp ? 'loading-modal' : 'hide-loading'}>
                                 <Loader loader={this.props.loadingCompData.loadingComp}  />
                                <br />
                                <span>{this.props.loadingCompData.loadingDetails}</span>
                             </div> 
                             <div className = 'general-settings'>
                            {
                            
                            formElementsArray.map((formElement, index) => {   
                                if(formElement.config.elementConfig.type !== "password"){
                                    return(
                                        <div key={index} className={formElement.config.elementConfig.divclass}>
                                            <Input 
                                                id={formElement.id}
                                                formElement={formElement.config}
                                                spanclass={formElement.config.elementConfig.spanclass}
                                                changed={(event) => this.inputChangedHandler(event, formElement.id)} 
                                                fileUpload={(event) => this.fileUpload(event, 'settingsFile')}
                                                parent={this.props}
                                                removeLogoImage = {this.removeLogoImage}
                                                validation={formElement.config.validation}
                                                showToolTip={this.showToolTip}
                                                imageButtonId="settingsFile"
                                                modal={true}
                                            />
                                            <FormError index={formElement.id} />
                                        </div>
                                    )
                                } else {
                                    return false;
                                }
                            })
                        }
                        </div>
                        </div>
                        <div style={{display: !this.state.isUpdatingPassword ? 'none' : 'inline'}}>
                            <div className={this.props.loadingCompData.loadingComp ? 'loading-modal' : 'hide-loading'}>
                                 <Loader loader={this.props.loadingCompData.loadingComp}  />
                                <br />
                                <span>{this.props.loadingCompData.loadingDetails}</span>
                             </div> 
                             <form id="changePassowrd" method="post" action="" autoComplete="off">
                                {
                                    formElementsArray.map((formElement, index) => {                                       
                                        if(formElement.config.elementConfig.type === "password"){
                                            return(
                                                <div key={index} className={formElement.config.elementConfig.divclass}>
                                                    <Input 
                                                        id={formElement.id}
                                                        formElement={formElement.config}
                                                        shouldValidate={formElement.config.validation}
                                                        touched={formElement.config.touched}
                                                        spanclass={formElement.config.elementConfig.spanclass}
                                                        validation={formElement.config.validation}
                                                        changed={(event) => this.inputChangedHandler(event, formElement.id)}
                                                    />
                                                    <FormError index={formElement.id} />
                                                </div>
                                            )
                                        } else {
                                            return false;
                                        }
                                    })
                                }
                            </form>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    {
                        this.state.modalErr ?
                            <div className="error-div">
                                {this.state.modalErr}
                            </div>
                        : null
                    }
                    <button id="btnUpdatePass" className="modal-button btn btn-round update-password-details btn-primary" onClick={this.submitHandler} style={{display: !this.state.isUpdatingPassword ? 'none' : 'inline'}}>Update Password</button>
                    <button id="btnUpdate" className="modal-button btn btn-round update-details btn-primary" onClick={this.submitPersonDetails} style={{display: this.state.isUpdatingPassword ? 'none' : 'inline'}}>{UPDATE_BUTTON_FOR_MODAL}</button>
                    <button id="btnUpdateCancel" className="modal-button btn btn-default btn-round update-details" onClick={this.props.onHide}>Cancel</button>
                </Modal.Footer>
            </Modal>
        )
    }
        
}

const SettingsModal = connect(mapStateToProps, mapDispatchToProps)(SettingsModalComp);
export default SettingsModal;