var _ = require('lodash');

export function inputChangedHandler(event, inputIdentifier, selection, form) {
    if(typeof event !== 'object') {
        return updateFormElement(inputIdentifier, event, form);  
    } else {
        var userInput = event && event.target? event.target.value : selection;
        if(form[inputIdentifier]['elementConfig']['type'] === "number"){
            userInput = (isNaN(parseInt(userInput)) ? ""  : parseInt(userInput)) ;
        }
        if(form[inputIdentifier]['elementConfig']['type'] === "color" && !event.target){
            userInput = event.hex; //colors with input as hex values for color picker
        }

        return updateFormElement(inputIdentifier, userInput, form);  
    }
}

export function selectInputChange(inputValue, inputIdentifier, form) {
    const updatedForm = {
        ...form
    };
    const updateFormElement = { 
        ...form[inputIdentifier]
    };
    
    updateFormElement.inputVal = inputValue;
    updatedForm[inputIdentifier] = updateFormElement;
    return updatedForm;
}

/**
 * Converts a checkbox-group state into a string
 * @param {Array} options checkbox options array
 * @returns value of the checkbox-group
 */
export const getCheckboxValueFromOptions = (options) => {
	return Object.values(options).reduce((prev,option) => {
		return prev + (option.checked ? option.name+' & ' : '')
	}, '').trim().replace(/&$/, '').trim(); //Eg. value: checkboxA & checkboxB
}

/**
 * Takes a checkbox-group value to give options array
 * that can be used to render a checkbox-group in the UI
 * @param {string} value value of the checkbox-group
 * @returns options array for the checked checkboxes
 */
export const getCheckboxOptionsFromValue = (value) => {
	const values = value.split('&');
    return values.reduce((prev,val) => {
        val = val.trim();
		return {
			...prev,
			[val] : {
				checked: true,
				label: val,
				name: val
			}
		}
	}, {})
}

/**
 * updateDependent - This will update the dependent value in a form.
 * @param {String} inputIdentifier Field name
 * @param {Object} form Take the whole form to update dependent value in validation
 */
export function updateDependent(inputIdentifier,form) {
    let dependentFieldArr = [];
    dependentFieldArr = _.map(form, function(obj,key){
        if (_.hasIn(obj, 'validation') && _.hasIn(obj['validation'], 'dependent') && _.hasIn(obj['validation']['dependent'], inputIdentifier) && (!obj['elementConfig'].hasOwnProperty('isDisabled') || (obj['elementConfig'].hasOwnProperty('isDisabled') && obj['elementConfig']['isDisabled'] === false))){
            return key;
        }
    });

    dependentFieldArr  = _.without(dependentFieldArr, undefined);

    if(form[inputIdentifier]['validation'].hasOwnProperty('dependent') && !Object.keys(dependentFieldArr).length && _.isEmpty(form[inputIdentifier]['value'])){
        form[inputIdentifier]['validation']['required'] = true;  
    }

    for(let dependentField in dependentFieldArr) {
        let data = dependentFieldArr[dependentField];

        form[data]['validation']['dependent'][inputIdentifier] = form[inputIdentifier]['value'];

        let hasDependentData = [],
            rules = form[data]['validation'];

        _.map(rules['dependent'], function(val){
            if((_.isObject(val) && _.isEmpty(val)) || !val) {
                if(_.isEmpty(form[data]['value'])) {
                    hasDependentData.push(true);
                }else {
                    hasDependentData.push(false);
                }    
            }else {
                hasDependentData.push(false);
            }
        }); 
        if(hasDependentData.includes(true)) {
            form[data]['validation']['required'] = true;
            form[inputIdentifier]['validation']['required'] = true;   
        }else{
            form[data]['validation']['required'] = false;
        }
    }
}   

export function updateFormElement(inputIdentifier, userInput, form) { 
    const updateForm = {
        ...form
    };
    const updateFormElement = { 
        ...updateForm[inputIdentifier]
    };

    var labelClass = !!updateFormElement && updateFormElement.elementConfig.labelClass;
    
    updateFormElement.value = userInput;
    updateFormElement.touched = true;
 
    if(labelClass) {
        labelClass = (userInput && userInput.length) ? "labelOut textLabel" : "labelIn textLabel";
    } 

    updateForm[inputIdentifier] = updateFormElement;
    updateDependent(inputIdentifier,updateForm);
    return updateForm;
}

/**
 * updateRow: Update a row of the list
 * @param {Object} 
 *  list: List in which you want to update
 *  rowId: Id of the row you want to update
 *  requestData: Edited data you want to update the row with
 * @return Array of Objects
 */
export function updateRow({list, rowId, requestData}) {
    let listData = list.slice();
    const updatedRowList = listData.map((row, index) => {
        if(row.id === rowId) {
            const editRow = { ...row, ...requestData };
            return editRow;
        } else {
            return row;
        }
    })
    return updatedRowList;
}

/**
* To update few properties of form Object dynamically
*/
export function updatePropsOfFields(elementsProp, form) {
    const updateForm = {
        ...form
    };
    
    for(let elementProp of elementsProp) {
        const updateFormElement = { 
            ...updateForm[elementProp.element]
        };
        const props = elementProp.props;

        for(let prop in props) {
            if(_.isPlainObject(props[prop])){
                Object.assign(updateFormElement[prop], props[prop]);
            } else {
                updateFormElement[prop] = props[prop];
            }
        }
        updateForm[elementProp.element] = updateFormElement;
    }
    return updateForm;
}