import {INVITE_PARTNER_ROLE} from '../../../constants/partnerConstants';

export default function invitePartnerState(state = {inviteRole: [], isUpdateRequired: true, totalCount: 0}, action) {
    switch (action.type) {
        case INVITE_PARTNER_ROLE:
            return {
                ...state,
                inviteRole: action.data.roles,
                isUpdateRequired: false,
                totalCount: action.data.totalCount,
            }
        default:
            return state;
    }
}