import { apiError, apiRequest, apiSuccess } from '../../app/actions/appAction.js';
import { changeSettingsApi } from '../services/settingsApi.js';

export const settingsChangePasswordAction  = (postData, storePath, callback) => (dispatch) => {
    dispatch(apiRequest(storePath));
    changeSettingsApi( postData, (response) => {
        if(response.error) {
            dispatch(apiError(response.error, storePath));
        } else {
            dispatch(apiSuccess(storePath, true));
        }
        callback && callback(response);
    });
}