import * as actionTypes from '../../../constants/pixelConstants';
export default function pixelReducer(state = { pixelList: {}, pixelForEdit: {}, generatedTag: {}, pixelDetails: {}, pixelEmail: {}, pixelLoading: true}, action) {
    switch (action.type) {
        case actionTypes.GET_ALL_PIXELS:
            return {
                ...state,
                pixelList: action.list,
            }
        case actionTypes.SET_PIXELS_FOR_EDIT:
            return {
                ...state,
                pixelForEdit: action.list,
            }
        case actionTypes.UPDATE_PIXEL_DETAILS:
            return {
                ...state,
                pixelDetails:action.list
            }
        case actionTypes.GET_RETARGETING_PIXEL_TAG:
            return {
                ...state,
                generatedTag:action.tag
            }
        default:
            return state;
    }
}