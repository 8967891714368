import { PARTNER_ROLES } from "../../constants/partnerConstants";

export default function roleReducer(state = {roles: [], isUpdateRequired: true, totalCount: -1}, action) {
    switch (action.type) {
        case PARTNER_ROLES:
            return {
                ...state,
                roles: action.data.roles,
                isUpdateRequired: false,
                totalCount: action.data.roles.length
            }
        default:
            return state;
    }
}