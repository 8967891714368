import { getURLParameters } from '../formatData';
var _ = require('lodash');

/**
 * To check the validation for form fields
 * @param {string} inputIdentifier - form element name to identifiy  
 * @param {string} value - user Input
 * @param {object} validation - object to check the rules
 */
export function setValidity(inputIdentifier, value, rules) {
    var isValid = true,
        divId = "div" + inputIdentifier;
    let msg = '';
    if (!_.isEmpty(rules)) {
        if (rules.required === false && (typeof value !== "string" && !_.isEmpty(value)) || (typeof value === "string" && value.trim() !== '') && !!rules.format) {
            if (value === []) {
                rules.required = true;
            } else {
                isValid = true;
            }
        } else {
            isValid = true;
        }
        if(rules.required) {
			let ruleFormats = typeof rules.format != 'undefined' && typeof rules.format === 'string' ? rules.format : '';
            const formats = ruleFormats.split(',');
            formats: for(let format=0; format<formats.length; format++){
                switch(formats[format]) {
                    case 'range' :
                        if(rules.maxValue) {
                            isValid = (value === '' || value > rules.maxValue)  ? false : true;
                        }else if(rules.minValue) {
                            isValid = (value === '' || value < rules.minValue)  ? false : true;
                        }

                        if (!isValid) {
                            if (value === '') {
                                msg = "Please enter a number";
                            } else if (value > rules.maxValue) {
                                msg = 'Allowed max value is ' + rules.maxValue
                            } else if (value < rules.minValue) {
                                msg = 'Allowed max value is ' + rules.minValue
                            }
                        }
                        break;
                    case 'option':
                        isValid = (value === '' || _.isEmpty(value)) ? false : true
                        if (!isValid) {
                            msg = "Please choose an option";
                        }
                        break;
                    case 'string':
                        isValid = value.trim() !== '';
                        if (!isValid) {
                            if (value.trim() === '') {
                                msg = "Please fill this field";
                            } else {
                                msg = 'Please enter a valid data';
                            }
                        }
                        break;
                    case 'names':
                        isValid = /^[A-Za-z\s]+$/.test(value);
                        if (!isValid) {
                            msg = 'Please enter a valid name';
                        }
                        break;
                    case 'number':
                        isValid = !isNaN(value);
                        if (typeof value === 'string') {
                            // prevents empty strings
                            isValid = isValid && value.length !== 0
                            // validates whitespaces
                            isValid = rules.preventWhiteSpace ? isValid && value.indexOf(' ') === -1 : isValid;
                            // validates decimal numbers
                            isValid = rules.preventFloatingNumbers ? isValid && value.indexOf('.') === -1 : isValid;
                        } else if(typeof value === 'boolean') {
                            isValid = value
                        }

                        if (!isValid) {
                            if (inputIdentifier === 'profileId') {
                                msg = "Please enter a valid Profile Id"
                            } else if (inputIdentifier === 'dv360PartnerId') {
                                msg = "Please enter a valid Partner Id";
                            } else {
                                msg = "Please enter a number";
                            }
                        }
                        break;
                    case 'alphaNumeric':
                        const alphaNumericRegEx = /^[a-z0-9]+$/i;
                        isValid = alphaNumericRegEx.test(value);
                        if (!isValid) {
                            msg = "Please enter valid alphanumeric characters";
                        }
                        break;
                    case 'time':
                        isValid = value >= rules.minValue;
                        if (!isValid) {
                            msg = 'Minimum 5 mins required';
                        }
                        break;
                    case 'email':
                        let eMailRegEx = /^[a-zA-Z0-9_!#$%&'*+/=?`{|}~^.-]+@[a-zA-Z0-9.-]+$/;
                        isValid = eMailRegEx.test(value);
                        if (!isValid) {
                            if (value.trim() === '') {
                                msg = "Please enter your email address";
                            } else {
                                msg = "Please enter a valid email";
                            }
                        }
                        break;
                    case 'configurationName':
                        isValid = value.trim() !== '';
                        if (!isValid) {
                            if (value.trim() === '') {
                                msg = "Please enter the Configuration name before saving the configuration.";
                            } else {
                                msg = 'Please enter a valid data';
                            }
                        } else {
                            const characterLimit = rules.characterLimit || 256;
                            isValid = value.length <= characterLimit;
                            if(!isValid) {
                                msg = `Configuration name can only have upto ${characterLimit} characters`
                            }
                        }
                        break;
                    case 'moatUrl':
                        let moatUrl = /^https?:\/\/+z.moatads.com\/+[a-zA-Z0-9]+\/moatad.js+#/;
                        isValid = moatUrl.test(value);
                        let httpTest = /^https:\/\//;
                        let isHttps = httpTest.test(value);
                        isValid = isValid && isHttps;
                        if(!isValid) {
                            if(value.trim() === '') {
                                msg = "Please enter MOAT Url";
                            } else {
                                msg = "Please enter a valid MOAT Url";
                            }
                        } else { // when URL is valid, check if parameters are present
                            const queryParams = getURLParameters(value,true, true);
                            isValid = Object.keys(queryParams).length > 0;
                            if(!isValid) {
                                msg = 'Tracker URL should contain at least one parameter'
                            }
                        }
                        break;
                    case 'trackerURL':
                        try {
                            const urlObject = new URL(value);
                            
                            // origin is "null" when the entered protocol is not a valid one(http, https..)
                            isValid = !!urlObject && urlObject.origin !== "null" // Let the built-in Web API do the validation
                            if(isValid) { // when URL is valid, check if parameters are present
                                const queryParams = getURLParameters(value,true);
                                isValid = Object.keys(queryParams).length > 0;
                                if(!isValid) {
                                    msg = 'Tracker URL should contain at least one parameter'
                                }
                            } else {
                                msg='Please enter a valid URL'
                            }
                        } catch {
                            // When invalid, it throws a TypeError exception
                            isValid = false;
                            msg = 'Please enter a valid URL'
                        }
                        break;
                    case 'uniqueCombo':
                        isValid = !value;
                        if (!isValid) {
                            msg = 'The selected media plan, site and campaign values combination already exists. Please select different values';
                            if (rules.errorMessage) {
                                msg = rules.errorMessage
                            }
                        }
                        break;
                    case 'Param Input Option':
                        isValid = value;
                        if(!isValid) {
                            msg = "The selected media plan doesn't have any external placements. Please select a different input option"
                        }
                        break;
                    case 'uniqueComboDV360':
                    case 'uniqueComboGCM':
                        isValid = !value;
                        if (!isValid) {
                            msg = rules.errorMessage;
                        }
                        break;
                    case 'xss':
                        isValid = !/<.+?>.*<\/.+?>|<.+?\/>|.*on[a-z]+.*=/g.test(value);
                        if (!isValid) {
                            msg = 'Invalid Value';
                        }
                        break;
                    case 'password':
                        let strongRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*[\\.|?({})\]\-",><':;_~`/])(?=.{8,99}$)/;
                        
                        isValid = strongRegex.test(value);
                        if(!isValid){
                            msg = `Password length must be between 8 and 99 char, required atleast one upper case, 
                            lower case, numbers, special chars among ^ $ * . [ ] { } ( ) ? - " ! @ # % & / , > < ' : ; | _ ~ \`!`;
                        }
                        break;
                    case 'link':
                        let regex = /^(?:(?:https?):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
                        isValid = regex.test(value) && value !== '' && /^https?:\/\/.*/.test(value); //should have protocol
                        if(!isValid){
                            msg = 'Enter a valid URL. URL must begin with http(s)://';
                        }
                        break;
                    default :
                        isValid = !_.isEmpty(typeof value === 'string' ? value.trim() : value);

                        if (!isValid) {
                            if (rules.errMsg) {
                                msg = rules.errMsg;
                            } else {
                                msg = "Please fill this field";
                            }
                        }
                        break;
                }
                if (!isValid)
                    break formats;
            }
        }
    }
    /* To display error message for form fields after validation */
    if (!!document.getElementById(divId)) {
        if (!isValid) {
            document.getElementById(divId).lastChild.innerHTML = msg;
            document.getElementById(divId).firstChild.className = "far fa-exclamation-triangle span-error ";
        } else {
            document.getElementById(divId).lastChild.innerHTML = '';
            document.getElementById(divId).firstChild.className = '';
        }
    }
    return isValid;
}