export default function updatePersonStateReducer(state = {personProfileDetails: {}}, action){
    switch (action.type) {
        case "UPDATE_PERSON_DETAILS":
            return {
                ...state,
                personProfileDetails:action.data
            }
        default:
            return state;
    }
}