import {PARTNER_TAB_COUNT, UPDATE_PARTNER_COUNT} from '../../constants/partnerConstants';

export default function partnerMainReducer(state = {pendingInviteCount: 0, isUpdateRequired: true, allPartnerCount: 0}, action) {
    switch (action.type) {
        case PARTNER_TAB_COUNT:
            return {
                ...state,
                pendingInviteCount: action.data.pendingPartnersCount,
                allPartnerCount: action.data.acceptedPartnersCount,
                isUpdateRequired: false
            }
        case UPDATE_PARTNER_COUNT:
            return {
                ...state,
                isUpdateRequired: true
            }
        default:
            return state;
    }
}
 