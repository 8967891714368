import React from "react";

import Button from '../button/button.js';
import {FieldGenerator} from './FieldGenerator';

import * as labelConstants from '../../constants/labelConstants.js';
import {GET_LABELS_OF_ENTITY} from '../../constants/accountConstants';
import {jivoxMacro} from '../../config/brandsafetyconfig';

import styles from '../../css/createForm.module.css';

var _ = require('lodash');

const getOptionValue = option => {
    return option.value || option.id || option.configId;
};

const getOptionLabel = (option, formelement, parent) => {
    let elemName =  !!formelement && formelement.elementConfig.name;
    let orgId = !!parent && parent.authorizationDetails && parent.authorizationDetails.organizationId;

    if(elemName === "organizations" && orgId < 1000 && option.type) {
        return (option.label || option.name) + ' ('+option.type+')' ;
    } else {
        return option.label || option.name || option.prefix || option.mediaPlanName || option.siteName || option.campaignName || option.email || option.configName;
    }
};

export const CreateForm = (props) => {
    props.formData.getOptionValue = getOptionValue;
    props.formData.getOptionLabel = getOptionLabel;

    let parent = props.formData;
    return ( 
        <React.Fragment>
            <div className={`form-group ${!props.noMinHeight && !parent.nestedForm && 'form-container'}`}>
                {parent.toolTip}
                {parent.toolTipRole}
                {
                    //nestedForm is a child form nested inside a parent form
                    parent.nestedForm === true ?
                    <div className={`${styles.nestedForm} ${!!parent.formName ? parent.formName : ""}`}>
                        {renderJsx(parent)}
                        {buttonRender(parent)}
                    </div>
                    :
                    <form 
                        role="form"
                        onSubmit={parent.submitHandler}
                        className={(!!parent.formName ? parent.formName : "")}
                    >
                        {renderJsx(parent)}
                        {buttonRender(parent)} 
                    </form>
                }
            </div>
        </React.Fragment>
    );
}

function buttonRender(parent) {
    var buttonArr = [];
    if(parent.hasOwnProperty('btn') && !_.isEmpty(parent.btn)){
        for(var i=0;i<parent.btn.length;i++){
            buttonArr.push(parent.btn[i]);
        }
    }
    if(!!parent.btnObj) {
        buttonArr.push(parent.btnObj);
    }
    return buttonArr.map((btnData, btnIndex) => {
        return (     
            <div className={"form-group "+(!!btnData.btnSize ? btnData.btnSize : "col-xs-12")} key={btnIndex}>
                <Button submitHandler={parent.submitHandler} btnObj={btnData} btnIcon={btnData.btnIcon} btnDisabled ={btnData.btnDisabled} btnLoader= {btnData.btnLoader}/>
            </div>
        ) 
    });
}


function renderJsx(parent) {  
    var formElementsArray = parent.formElementsArray, i, j, chunkArray = [], 
    chunkArrSize, chunkArr = parent.chunkSize, chunk;
    
    if(chunkArr) {
        chunkArrSize = chunkArr.length
    } else {
        let newArr = new Array(parent.formElementsArray.length).fill(2);
        chunkArrSize = newArr.length;
        chunkArr = newArr;
    }
    for (i = 0,j = chunkArrSize; i < j; i += chunk) {
        chunk = chunkArr[i];
        chunkArray.push(formElementsArray.slice(i,i+chunk));
    }
    return chunkArray.map((formElements, index) => {
        return (            // parent.dynamicRow ----->  To adjust width and other css for row dynamically
                <div className={!!parent.dynamicFormRow ? "form-row row " + parent.dynamicFormRow : 'form-row row'} key={index}>
                {formElements.map(formElement => {
                    return fieldGenerator(formElement, parent);
                })}
            </div>
        ) 
    })
}

function fieldGenerator(formElement, parent) {
    var 
        selectDataSource = !!parent.selectDataSource ? parent.selectDataSource : {},
        selectConfig = {
            dataSource: [],
            searchMenuDetails: {}
        },
        elementConfig = formElement.config.elementConfig,
        sameTenant = typeof parent.sameTenant !== 'undefined' ? parent.sameTenant : true,
        destorg = parent.destOrdId ? `&orgId=${parent.destOrdId}` : '',
        accountId = !!parent.accountId ? parent.accountId : null,
        mediaPlanVal = !!parent.mediaPlanVal ? parent.mediaPlanVal : null,
        advertiserId = !!parent.advertiserId ? parent.advertiserId : null,
        profileId = !!parent.profileId ? parent.profileId : null,
        partnerId = !!parent.partnerId ? parent.partnerId : null;
    switch(elementConfig.name) {
        case "brands":
            selectConfig.dataSource = selectDataSource.brands ? selectDataSource.brands : [];
            selectConfig.searchMenuDetails = {
                endPoint: '/accounts/brands?sortBy=name&orderBy=asc&name=',
                getData: 'entities'
            }
            break;
        case "organizations":
            selectConfig.searchMenuDetails = {
                endPoint: `/accounts?accountType=Organization&sameTenant=${sameTenant}${destorg}&sortBy=accountName&orderBy=asc&fields=id,name,type&accountName=`,
                getData: 'account'
            }
            break;   
		case "retailers":
			selectConfig.dataSource = selectDataSource.retailers;
			break;
		case "gcmConfigList":
			selectConfig.dataSource = selectDataSource.configList;
			break;
		case "gcmAdvertiserName":
			selectConfig.dataSource = selectDataSource.advertiserList;
			break;
        case "accounts":
            selectConfig.searchMenuDetails = {
                endPoint: `/accounts?accountType=Account&sortBy=accountName&orderBy=asc&hideSystemEntity=true&fields=id,name,type&accountName=`,
                getData: 'account'
            }
            break; 
		case "retailAccounts":
				selectConfig.searchMenuDetails = {
					endPoint: `/accounts?accountType=Retail Account&sortBy=accountName&orderBy=asc&hideSystemEntity=true&fields=id,name,type&accountName=`,
					getData: 'account'
				}
			break; 
        case "accountSelect":
            selectConfig.searchMenuDetails = {
                endPoint: '/accounts?accountType=Account&fields=id,name,type,advertiserId&sortBy=accountName&orderBy=asc&hideSystemEntity=true&sameTenant=false&accountName=',
                getData: 'account'
            }
            break;
        case "scope":
            selectConfig.dataSource = selectDataSource.labelScope;
            break;    
        case "inviteRole":
            selectConfig.dataSource = selectDataSource.inviteRole;
            break;
        case "partnerRoles":
            selectConfig.dataSource = selectDataSource.partnerRoles;
            break;
        case "role":
        case "roles":
            selectConfig.dataSource = selectDataSource.roles;
            break;
        case "labels":
            selectConfig.dataSource = selectDataSource.labelsOfEntity;
            if(parent.entitiyIds) {
                selectConfig.searchMenuDetails = {
                    endPoint: `/labels/scope/Account?withEntities=${parent.entitiyIds}&labelName=`,
                    actionType: GET_LABELS_OF_ENTITY,
                    getData: 'data'
                }
            }
            break; 
        case "pixelAction":
            selectConfig.dataSource = selectDataSource.action;
            break;
        case "cookieGroup":
            selectConfig.dataSource = selectDataSource.cookieGroup;
            break;
        case "trackerType":
            selectConfig.dataSource = selectDataSource.trackerType;
            break;  
        case "mergeLabel":
            selectConfig.searchMenuDetails = {
                endPoint: `/labels?type=label&name=`,
                getData: 'labels'
            }
            break;
        case "emailList":  //selectBox to choose email for AddToPerson in Account.jsx
            selectConfig.dataSource = selectDataSource.associatedPeople;
            break;
        case "accountList": //selectBox to choose account for AddToAcc in People.jsx
            selectConfig.dataSource = selectDataSource.associatedAcc;
            break;
        case "groupLabel":
            selectConfig.searchMenuDetails = {
                endPoint: `/labels?type=label&name=`,
                getData: 'labels'
            }
            break;
        case "associatedBrandData": 
            selectConfig.dataSource = selectDataSource.associateBrandData;

            if(parent.brandId) {
                selectConfig.searchMenuDetails = {
                    endPoint: `/accounts/brand/${parent.brandId}/data/available-entities?brandName=`,
                    getData: 'availableEntities',
                    groupHeader: 'brandData'
                }
            }
            break;
        case "shareBrandSource":
        case "shareBrandDestination":
            selectConfig.searchMenuDetails = {
                endPoint: `/accounts?accountType=Brand,Retailer&accountName=&retailBrandFilter=true`,
                getData: 'account'
            }
            break;
        case "groupCategory":
            selectConfig.searchMenuDetails = {
                endPoint: `/labels?type=category&name=`,
                getData: 'labels'
            }
            break;
        case "editGroupCategory":
            selectConfig.searchMenuDetails = {
                endPoint: `/labels/category/${parent.categoryId}/parents?name=`,
                getData: 'data'
            }
            break;
        case "prefixFor":
            selectConfig.dataSource = selectDataSource.prefixFor;
            break;
        case "prefixList":
            selectConfig.dataSource = selectDataSource.listPrefix;
            break;
        case "mediaPlan":
            if(!!accountId) {
                selectConfig.searchMenuDetails = {
                    endPoint: `/v2/accounts/${accountId}/media-plans`,
                    getData: 'mediaPlans',
                    paginate: '?offset=0&limit=20',
                    filterQuery: `/v2/accounts/${accountId}/media-plans?filters=mediaPlanName(like)`,
                    requestInfo: {
                        isPartnerIntegration: true
                    },
                    fetchMenu: elementConfig.fetchMediaPlan
                }
            }
            break; 
        case "campaigns":
            if(!!accountId) {
                selectConfig.searchMenuDetails = {
                    endPoint: `/v2/accounts/${accountId}/campaigns?fields=campaignId,campaignName`,
                    getData: 'campaigns',
                    paginate: '&offset=0&limit=20',
                    filterQuery: `/v2/accounts/${accountId}/campaigns?fields=campaignId,campaignName&filters=campaignName(like)`,
                    groupHeader: 'campaign',
                    requestInfo: {
                        isPartnerIntegration: true
                    },
                    fetchMenu: elementConfig.fetchCampaigns
                }
            }
            break;   
        case "sites":
            selectConfig.dataSource = selectDataSource.sites;
            selectConfig.searchMenuDetails = {
                getData: 'sites',
                groupHeader: 'site',
                paginate: '&offset=0&limit=20',
                filterQuery: `/v2/accounts/${accountId}/media-plans/${mediaPlanVal}/sites?filters=siteName(like)`,
                requestInfo: {
                    isPartnerIntegration: true
                }
            }
            break;
        case "dcmAdvertiserList":
            selectConfig.dataSource = selectDataSource.advertiserList;
            selectConfig.searchMenuDetails = {
                getData: 'advertiserList',
                groupHeader: 'advertiserLists',
                paginate: '&offset=0&limit=20',
                filterQuery: `/v2/accounts/${accountId}/dcm-configurations/${profileId}/advertisers?advertiserNameFilter=`,
                requestInfo: {
                    isPartnerIntegration: true
                } 
            }
            break;
        case "dcmCampaignName":
            selectConfig.dataSource = selectDataSource.campaignList;
            selectConfig.searchMenuDetails = {
                getData: 'campaignList',
                groupHeader: 'campaignList',
                paginate: '&offset=0&limit=20',
                filterQuery: `/v2/accounts/${accountId}/dcm-configurations/${profileId}/advertisers/${advertiserId}/campaigns?campaignNameFilter=`,
                requestInfo: {
                    isPartnerIntegration: true
                } 
            }
            break;
        case "advertiserListDV360":
            selectConfig.dataSource = selectDataSource.advertisersList;
            selectConfig.searchMenuDetails = {
                getData: "advertisersList",
                groupHeader: "advertisersLists",
                paginate: "&offset=0&limit=20",
                filterQuery: `/v2/accounts/${accountId}/dv360-configurations/${partnerId}/advertisers?advertiserNameFilter=`,
                requestInfo: {
                    isPartnerIntegration: true
                }
            }
            break;
        case "inputOptions":
            selectConfig.dataSource = selectDataSource.inputOptions;
            break;
        case "dspMacro":
            selectConfig.searchMenuDetails = {
                endPoint: '/v2/ad-servers/macros',
                getData: 'macros',
                paginate: '?offset=0&limit=20',
                filterQuery: '/v2/ad-servers/macros?filters=adServerName(like)',
                requestInfo: {
                    isPartnerIntegration: true
                } 
            }
            break;
        case "jivoxMacro":
            // Order of priority for data source is
            // 1. Data Source defined in the form element config
            // 2. Data source defined in the form config
            // 3. Data source obtained from an external file
            if(formElement.config.dataSource) {
                selectConfig.dataSource = formElement.config.dataSource;
            } else if(selectDataSource.jivoxMacro) {
                selectConfig.dataSource = selectDataSource.jivoxMacro;
            } else {
                selectConfig.dataSource = jivoxMacro;
            }
            break;
            
    }   
     return(
        <FieldGenerator {...parent} selectConfig={selectConfig} key={formElement.id} formElement={formElement} />
    );
}