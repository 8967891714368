import * as actionTypes from '../../constants/prefixConstants';
import {updateRow} from '../../helper/formHelper/updateForm';

export default function prefixReducer(state = {prefixList: [], prefixesOfEntity: []}, action) {
    switch (action.type) {
        case actionTypes.SET_PREFIX_LIST:
            return {
                ...state,
                prefixList: action.list
            }
        case actionTypes.UPDATE_PREFIX_ROW:
            const list = updateRow({list:state.prefixList.prefixes, rowId:action.rowId, requestData:action.requestData})
            return {
                ...state,
                prefixList: {
                    ...state.prefixList,
                    prefixes: list
                }
            }
        case actionTypes.SET_PREFIXES_OF_ENTITY:
            return {
                ...state,
                prefixesOfEntity:action.list
            }
        default:
            return state;
    }
}

