import React from 'react';

export const FormSuccess = ({ successMessage }) => {
	return (
		!!successMessage ?
			<div className='divSuccess'>
				<i className="far fa-check-circle"></i>
				<span className="successMessage">{successMessage}</span>
			</div>
			: null
	);
};
