import {http} from '../../helper/commonFunc.js';

export function appApi(actionCallback) {
  http('post', '/logout', {}, actionCallback);
}

export function getRoles(url, actionCallback) {
  http('get', url, {}, actionCallback);
}

export function uploadApi(postData, apiPath, actionCallback) {
  var formData = new FormData();
    formData.append("image", postData);
    formData.append("assetType", 'logo');
    http('post', '/'+apiPath, formData, actionCallback);
}

export function generateSocketToken(actionCallback) {
  http('get', '/activities/ws/token', {}, actionCallback);
}

export function searchMenuApi(endPoint, isPartnerIntegration, isDCM, actionCallback) { 
  http('get', endPoint, {isPartnerIntegration: isPartnerIntegration, isDCM: isDCM}, actionCallback);
}