import React, { Component } from "react";
import Select, { components } from "react-select";

const Option = (props) => (
  <div>
    <components.Option {...props}>
      <div onMouseEnter={(e) => props.selectProps.handleOptionTooltip(e, props.data, "show")} onMouseLeave={props.selectProps.handleOptionTooltip} data-tip data-for={props.selectProps.index}>{props.data.name}</div>
    </components.Option>
  </div>
);


class TooltipSelect extends Component {
  render() {
    const { options, index, parent, formelement, ...otherProps } = this.props;
    let { onChangeCallback, onInputChange, getOptionValue, getOptionLabel, handleOptionTooltip } = this.props.parent;
    return (
	<div className={ formelement.toolTipContent ? "select-tooltip" : ""}>
      <Select
        name={index}
        index={index}
        placeholder=""
        closeMenuOnSelect={false}
        components={{ Option }}
        options={options}
        hideSelectedOptions={false}
        backspaceRemovesValue={false}
        onChange={onChangeCallback}
        onInputChange={(e) => onInputChange(e, index)}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        handleOptionTooltip={handleOptionTooltip}
        {...otherProps}
      />
	  {!!formelement.toolTipContent ? <span className="info far fa-question-circle" onMouseEnter={(e)=>parent.showToolTip(e)} onMouseLeave={(e)=>parent.hideToolTip(e)} tooltipcontent={formelement.toolTipContent} data-tip data-for={index} ></span> : null}
	  </div>
    );
  }
}

export default TooltipSelect;
