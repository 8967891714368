import React, {Component}  from 'react';
import {connect} from 'react-redux';
import {toastr} from 'react-redux-toastr';
import * as DOMPurify from 'dompurify';

import {resetToasterAction} from '../../app/actions/appAction';

const mapDispatchToProps = (dispatch) => {
    return {
        resetToaster: (storePath) => {
            dispatch(resetToasterAction(storePath));
        }
    }
}
export class ReduxToasterComp extends Component {
    constructor(props){
        super(props);
        this.toastrData = '';
    }
    componentDidUpdate(){
        if(!!this.props.error || !!this.props.success){
            var msg = '', toastrType = '', toastrOptions ={}, title = this.props.title;
            
            if(this.props.error) {
                toastrType = 'error';
                msg = DOMPurify.sanitize(this.props.error);

                toastrOptions = {
                    icon: toastrType,
                    status: toastrType,
                    component: <p dangerouslySetInnerHTML={{ __html: msg }} />
                }           
            } else if(this.props.success) {
                toastrType = 'success';
                msg = DOMPurify.sanitize(this.props.successMsg);

                toastrOptions = {
                    icon: toastrType,
                    status: toastrType,
                    component: <p dangerouslySetInnerHTML={{ __html: msg }} />
                } 
            }
            toastrOptions.onHideComplete = () => this.props.resetToaster(this.props.storePath)
            toastrOptions.onCloseButtonClick = () => this.props.resetToaster(this.props.storePath);
            toastrOptions.onToastrClick = () => this.props.resetToaster(this.props.storePath);

            this.toastrData  = toastrType ? toastr.light(title,"", toastrOptions)  : null;
        }
        
    }
    render() {
        return (
            <React.Fragment> {this.toastrData} </React.Fragment>
        )
    }
}

const ReduxToaster = connect(null, mapDispatchToProps)(ReduxToasterComp)
export default ReduxToaster;