import React from 'react';
import { connect } from 'react-redux';
import { BeatLoader } from 'react-spinners';

const mapStateToProps = state => ({
    primaryColor: state.dashboardReducer.theme["--primary-color"]
})

class BeatLoaderComp extends React.Component {
    render() {
        return (
            <BeatLoader
                color={this.props.primaryColor} 
                loading={this.props.loader} 
                size={12}
            />
        )
    }
}

const Loader = connect(mapStateToProps, null)(BeatLoaderComp);

export default Loader;