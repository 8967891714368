export default function permissionReducer(state = {
    authorizationDetails: {consolidatedPermission: []}
}, action) {
    switch (action.type) {
        case 'GET_PERMISSIONS':
            return {
                ...state,
                authorizationDetails: action.response
            }
        default:
            return state;
    }
}