import {http} from '../../helper/commonFunc.js';

export function getTrackerConfigApi(url, isDCM ,actionCallback) { 
    http('get', url, isDCM ? {isDCM: true} : {isPartnerIntegration: true}, actionCallback);
}

export function deleteTrackerConfigApi(url, isDCM, actionCallback) {
    http('delete', url, isDCM ? {isDCM: true} : {isPartnerIntegration: true}, actionCallback);
}

export function saveTrackerConfigApi(url, postData, actionCallback) {
    if(!postData.isDCM)
		postData.isPartnerIntegration =  true;
    http('post', url, postData, actionCallback);
}

export function editTrackerConfigApi(url, postData, actionCallback) {
	if(!postData.isDCM)
		postData.isPartnerIntegration =  true;
    http(postData.isDCM ? 'put' : 'patch', url, postData, actionCallback);
}

export function getTrackerStatusApi(url, actionCallback) { 
    http('get', url, {isPartnerIntegration: true}, actionCallback);
}
