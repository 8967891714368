import {PARTNER_MEMBERSHIP_LIST , DELETE_PARTNER_MEMBERSHIP, EDIT_PARTNER_MEMBERSHIP, PARTNER_MEMBERSHIP_LIST_UPDATE_REQUIRED} from '../../../constants/partnerConstants';

export default function partnerMemberReducer(state = {partnerMembers: [], isUpdateRequired: true, totalCount: -1}, action) {
    switch (action.type) {
        case PARTNER_MEMBERSHIP_LIST:
            return {
                ...state,
                partnerMembers: action.data.users,
                totalCount: action.data.totalCount,
                isUpdateRequired: false
            }
        case EDIT_PARTNER_MEMBERSHIP:
            return updatePartnerMembershipRow(state,action);
        case DELETE_PARTNER_MEMBERSHIP:
            return deleteMember(state,action);
        case PARTNER_MEMBERSHIP_LIST_UPDATE_REQUIRED:
            return {
                ...state,
                isUpdateRequired: true
            }
        default:
            return state;
    }
}

function deleteMember(partnerMemberState = {}, action) {
    let partnerMembers = [...partnerMemberState.partnerMembers];
    let filteredList = partnerMembers.filter((member, index) => {
         return !((member.destOrgId === action.data.destOrgId) && (member.id === action.data.srcOrgId));
    });
     return {
         partnerMembers: filteredList,
         totalCount: filteredList.length,
     }
 }
 
function updatePartnerMembershipRow(partnerMemberState = {},action){
    let partnerMembers = [...partnerMemberState.partnerMembers];
    let requestData = action.data.requestInfo;
    const updatedRowList = partnerMembers.map((row, index) => {
        if((row.destOrgId === requestData.orgDetails.destOrgId) && (row.id === requestData.orgDetails.srcOrgId)) {
            const editRow = { ...row, ...requestData };
            return editRow;
        } else {
            return row;
        }
    });

    return{
        partnerMembers: updatedRowList,
        totalCount:updatedRowList.length,
        isUpdateRequired:false,
    } 
 }