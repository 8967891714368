import 'react-notifications/lib/notifications.css';
import {NotificationManager} from 'react-notifications';
 
export function CreateNotification(props) {
      switch (props.type) {
        case 'info':
            NotificationManager.info(props.alertMsg, '', 20000);
            break;
        case 'success':
            NotificationManager.success(props.alertMsg, '', 20000);
            break;
        case 'warning':
            NotificationManager.warning(props.alertMsg, '', 20000);
            break;
        case 'danger':
            NotificationManager.error(props.alertMsg, '', 20000);
            break;
        default:
            break;
      }
}