import React from 'react';
import reactCSS from 'reactcss';
import { ChromePicker } from 'react-color';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Checkbox } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {FormLabel} from '../Form/FormLabel';
import '../../commonUI/input/input.css';

const CustomRadio = withStyles({
    root: {
        fontSize: '2rem',
      '&$checked': {
        color: 'var(--primary-color)',
      }
    },
    checked: {},
})((props) => <Radio color="default"  size="medium" disableRipple {...props}/>);

const CustomCheckBox = withStyles({
    root: {
        '&$checked': {
            color: 'var(--primary-color)',
        }
    },
    checked: {}
})((props) => <Checkbox color='default' size='medium' disableRipple {...props} />);

const input = ( props ) => {
    let parent = !!props.parent ? props.parent : '';
    let inputElement = null;
    let {elementType, elementConfig, validation, value, acceptFileType, disabled , removeFile} = props.formElement;
    let {selectedRadio, clipboard, imageButtonId, listPrefixOfEntity, handleExpandPrefixes, prefixDisplayClass, expandContainerClass, prefixHolderClass} = parent;
    let isuploading = parent.uploading;
    let filePath = parent.uploadResponse;
    value = !!props.formElement.displayValue ? props.formElement.displayValue : value;
    disabled = disabled === true ? true : false;
    imageButtonId = !!imageButtonId ? imageButtonId: props.fileUploadId? props.fileUploadId+"-file" : "my-file-selector";
    const canBlur = !!props.onBlur;
    const canKeyDown = !!props.onKeyDown;
    const inputClasses = elementConfig.inputClasses ? elementConfig.inputClasses : "has-feedback-left inputText form-control";
    const removeExtraSpace = props.formElement.removeExtraSpace;
  
    switch ( elementType ) {      
        case ( 'input' ):
            if(elementConfig.type === 'simpleInput'){
                inputElement=
                    <div className="simpleInput">
                        <input
                        className={inputClasses}
                        placeholder=" "
                        {...elementConfig}
                        value={value}
                        onChange={(event) => props.changed(event, props.id)} 
                        {...(canBlur && { onBlur: (event) => props.onBlur(event) })}
                        {...(canKeyDown && { onKeyUp: (event) => props.onKeyDown(event) })}
                        validation={validation}
                        id={props.id}
                        autoComplete = "off"
                        disabled = {disabled}
                        data-testid={props.id}
                        />
                    </div>
            }
            else if(elementConfig.type !== "color" && elementConfig.type !=="simpleInput"){
                inputElement = <input
                    className={inputClasses}
                    placeholder=" "
                    {...elementConfig}
                    value={value}
                    onChange={(event) => props.changed(event, props.id)} 
                    {...(canBlur && { onBlur: (event) => props.onBlur(event) })}
                    {...(canKeyDown && { onKeyUp: (event) => props.onKeyDown(event) })}
                    validation={validation}
                    id={props.id}
                    autoComplete = "off"
                    disabled = {disabled}
                    data-testid={props.id}
                    />;
            } else {
                const styles = reactCSS({
                    'default': {
                        color: {
                            width: '36px',
                            height: '14px',
                            borderRadius: '2px',
                            background: `${value}`,
                            width: '21px',
                            height: '18px'
                        },
                        swatch: {
                            padding: '5px',
                            background: '#fff',
                            borderRadius: '1px',
                            boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
                            display: 'inline-block',
                            cursor: 'pointer',
                            width:'31px',
                            height:'29px'

                        },
                        popover: {
                            position: 'fixed',
                            zIndex: '2',
                        },
                        cover: {
                            position: 'fixed',
                            top: '0px',
                            right: '0px',
                            bottom: '0px',
                            left: '0px',
                        },
                    },
                });

                inputElement = <React.Fragment>
                                    <div style={ styles.swatch } onClick={(event) => parent.handleColorClick(event, props.id) }>
                                        <div style={ styles.color } />
                                    </div>
                                    { 
                                        props.formElement.displayColorPicker ? 
                                            (<div  style={ styles.popover }>
                                                <div style={ styles.cover } onClick={ (event)=> parent.handleCloseColorPicker(event, props.id) }/>
                                                <ChromePicker color={value} onChange={(event)=> props.changed(event, props.id)} />
                                            </div>) : null 
                                }
                                    <input type="text" onChange={(event) => {props.changed(event, props.id)}} value={value} className="inputColorText has-feedback-left inputText form-control"/>
                                    <span className="color-label">{elementConfig.label}</span>
                            </React.Fragment>;
            }
            break;
        case ('file'): 
            inputElement = 
            <div>
                <input
                    className={inputClasses}
                    {...elementConfig}
                    value={value}
                    onChange={(event) => props.changed(event, props.id)} 
                    id={props.id}
                />
                {
                    filePath ? <button className={props.modal ? 'remove-file-modal' : 'remove-file'} onClick={props.removeLogoImage}><i className="far fa-times" aria-hidden="true"></i></button> : isuploading ?  <i className="spinner far fa-spinner fa-pulse fa-3x fa-fw" aria-hidden="true"></i> : ""
                }
                 {
                    removeFile && value ? <button className='remove-file-modal' onClick={(e) => props.removeUploadedFile(e, elementConfig.label)}><i className="far fa-times" aria-hidden="true"></i></button>: ""
                }
                <span className="floating-label">{elementConfig.label}</span>
                <label className="btn btn-primary upload-label" htmlFor={imageButtonId}>
                    <input id={imageButtonId} type="file" accept={acceptFileType} onChange={(e)=>props.fileUpload(e, props.id)} className="image-selector"/>
                    Choose File
                </label>
            </div>
            break;
        case ('numericInput'):
            inputElement = 
                <input
                    className={inputClasses}
                    placeholder=" "
                    {...elementConfig}
                    min={validation.minValue}
                    //max={props.max}
                    id={props.id}
                    validation={validation}
                    onChange={(event) => props.changed(event, props.id)} 
                    value={value}
                    onInput={props.changed}
                    autoComplete = "off"
                /> 
            break;
        case ('radio'):
            inputElement = <input
                {...elementConfig}
                value={value}
                checked={value === selectedRadio}
                onChange={props.radioChange}
                validation={validation}
                id={props.id}
                disabled = {disabled}
                autoComplete = "off" 
                type="radio" />;
            break;
        case('custom-radio'):
            let radioGroupProps = {
                name: elementConfig.name,
                onChange: props.radioChange
            }
            if(!!elementConfig.controlled) { // controlled input
                radioGroupProps = { ...radioGroupProps,value }
            } else {
                radioGroupProps = { // uncontrolled input
                    ...radioGroupProps,
                    defaultValue: elementConfig.defaultValue
                }
            }
            inputElement = 
                <>
                    <FormLabel formElement={props.formElement} />
                    <RadioGroup row {...radioGroupProps}>
                    {
                        elementConfig.options.map(option => {
                            const label = 
                            <>
                                {option.label}
                                {!!option.toolTipContent && <span className="helpTextAnchor far fa-info-circle" data-for={elementConfig.id} onMouseEnter={props.showToolTip} onMouseLeave={props.hideToolTip} tooltipcontent={option.toolTipContent} data-tip data-html = 'true' ></span>}
                            </>
                            return (                            
                                <div className = "customRadioContainer">
                                    <FormControlLabel classes={{
                                        label: 'customRadioLabel',
                                    }} key={option.value} value={option.value} control={<CustomRadio />} label={label} disabled={disabled}/>
                                </div>
                            )
                        })
                    }
                    </RadioGroup>
                </>
              break;
        case('checkbox-group'):
                inputElement =
                <>
                    <FormLabel formElement={props.formElement} />
                    {
                        elementConfig.options.map(option => (
                            <FormControlLabel classes={{
                                label: 'checkboxLabel'
                            }} key={option.name} label={option.label} control={
                                <CustomCheckBox 
                                    onChange={(event) => props.checkboxChange(event, option.name)} 
                                    disabled={disabled}
                                    {...option} 
                                />
                            } />
                        ))
                    }
                </>
                break;
        default:
            inputElement = <input
                className={inputClasses}
                {...elementConfig}
                value={value}
                onChange={(event) => props.changed(event, props.id)}
                validation={validation}
                id={props.id}
                disabled = {disabled}
                autoComplete = "off" />;
    }
    return (
        <div>
            {/* <label>{props.elementConfig.placeholder}</label> */}
            
            {/* <Tooltip title="Please fill this field" position='left-start' arrow={true} open={true}
                                    interactive={true} trigger='manual' size='large'
                                    animation='scale' arrowSize='small'
                                    dynamicTitle={true} hideOnClick={false}>  */
                                    
            }
            
            {  removeExtraSpace ? null : <br/>  }
            {inputElement}

            {
                (elementConfig.label !== "Font Family" && elementConfig.label !== "Federation Metadata XML" && elementConfig.type !== "color" && elementConfig.type !== "simpleInput" && elementType !== 'custom-radio' && elementType !== 'checkbox-group' && validation !== undefined) ? <FormLabel formElement = {props.formElement} /> : ''
            }
            
            {
                clipboard ?
                (
                    <div>
                        <a className="clipboard-icon" onClick={props.onClick} oldtitle="Copy to clipboard" title="" data-tip data-for="copyKey" onMouseLeave={props.hideToolTip} onMouseEnter={props.showToolTip}><i className="far fa-copy fa-2x"></i></a>
                    </div>
                )
                : null 
            }
            {filePath && elementType === "file" ? <img data-tip data-for='imagePreview' className={props.modal ? 'image-modal' : 'image'} alt="image" onMouseOver={props.showToolTip} src={filePath} />
            :props.spanclass !== undefined ?
                <React.Fragment><span aria-hidden="true" className={props.spanclass}>{!!elementConfig.icontag ? elementConfig.icontag : null}</span>
                {props.id === 'accountName' ? <div className={"prefixHolder "+prefixHolderClass}><div className={"prefixList "+prefixDisplayClass} onClick={(e) => handleExpandPrefixes("collapse")}>{listPrefixOfEntity}</div><span className={"expand "+expandContainerClass} onClick={(e) => handleExpandPrefixes("expand")}>...</span> {'>'} </div> : null}</React.Fragment>
                : ''
            }
        </div>
    );
}

export default input;