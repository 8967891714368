/**
 * This is the configuration file for routing.
 * 
 * author      Arjita Mitra
 */

import React from 'react';
import App from '../app/components/App';
import { Provider } from 'react-redux';
import { Route, BrowserRouter } from 'react-router-dom';
import ReduxToastr from 'react-redux-toastr';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';

const Root = ({ store }) => (
    <Provider store={store}>
        <BrowserRouter>
            <div>
                <Route path="/" render={props => <App {...props} />}/>
                <ReduxToastr
                    timeOut={4000}
                    removeOnHover= {false}
                    newestOnTop={true}
                    preventDuplicates
                    position="top-center"
                    transitionIn="bounceIn"
                    transitionOut="bounceOut"
                    closeOnToastrClick/>
            </div>
        </BrowserRouter>
    </Provider>
)

export default Root