/**
 * setData: Set store data
 * @param {*} data Object
 * @param {*} actionType String
 * @param {*} storePath String
 */
export function setData(data, actionType = '', storePath = '') {
    // TO-DO: Replace setData from all action files to use this one
    let STORE_PATH = storePath ? storePath : actionType;
    return {
        type: actionType,
        data,
        STORE_PATH
    }
}

/**
 * Set update required flag to make a fresh API call and update the store
 * @param {Object} actionData 
 */
export const setUpdateRequired = (actionData) => (dispatch) => {
    dispatch(setData(actionData, actionData.actionType, actionData.storePath));
}