export const CREATE_LABEL_COMPONENT = 'CREATE_LABEL_COMPONENT';
export const EDIT_LABEL_COMPONENT = 'EDIT_LABEL_COMPONENT';
export const EDIT_CATEGORY_COMPONENT = 'EDIT_CATEGORY_COMPONENT';
export const SET_SCOPE = 'SET_SCOPE';
export const LABEL_PERM = '21502,21511';
export const LIST_LABEL_COMPONENT = 'LIST_LABEL_COMPONENT';
export const ADD_LABEL = 'ADD_LABEL';
export const TOGGLE_PANEL = 'TOGGLE_PANEL';
export const UPDATE_ROW = 'UPDATE_ROW';
export const MERGE_LABEL_COMPONENT = 'MERGE_LABEL_COMPONENT';
export const DELETE_LABEL_COMPONENT = 'DELETE_LABEL_COMPONENT';
export const LABEL = 'label';
export const SET_UPDATE_REQUIRED = 'SET_UPDATE_REQUIRED';

// SUCCSS_MSG
export const SUCCSS_MSG = 'The label was created';
export const CATEGORY_SUCCSS_MSG = 'The category was created';
export const EDIT_LABEL_SUCCESS_MSG = 'The label was updated';
export const EDIT_CATEGORY_SUCCESS_MSG = 'The category was updated';
export const MERGE_LABEL_SUCCESS_MSG = 'The label was merged';
export const DELETE_LABEL_SUCCESS_MSG = 'The label was deleted';
export const DELETE_CATEGORY_SUCCESS_MSG = 'The category was deleted';

