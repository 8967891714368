import * as actionTypes from '../../../constants/pixelConstants';

export default function conversionPixelReducer(state = { conversionPixelList: {}, conversionPixelsForEdit:{},pixelDetails: {},pixelEmail: {},pixelLoading: true}, action) {
    switch (action.type) {
        case actionTypes.LIST_CONVERSION_PIXEL_COMPONENT:
            return {
                ...state,
                conversionPixelList: action.data,
            }
        case actionTypes.SET_CONVERSION_PIXELS_FOR_EDIT:
            return {
                ...state,
                conversionPixelsForEdit: action.data,
            }
        case actionTypes.UPDATE_PIXEL_DETAILS:
            return {
                ...state,
                pixelDetails:action.data
            }
        case actionTypes.GET_CONVERSION_PIXEL_TAG:
            return {
                ...state,
                generatedTag:action.data
            }
        default:
            return state;
    }
}