import React, { PureComponent, Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { NavLink, Route, Switch, Redirect, withRouter} from 'react-router-dom';
import Breadcrumb from './Breadcrumb';
import adminJson from '../conf1.json';
import * as appConstants from '../../constants/appConstants.js';
import { RMA_ROLES } from '../../constants/accountConstants';
import ApiConfigClass  from '../../config/apiConfig';
import {isEmpty, openSupportWidget} from '../../helper/commonFunc';
import Loader from '../../commonUI/loader/withLoader';
import '../../css/sidenav.css';
import '../../css/footer.css';

const Dashboard = lazy(() => import('../../dashboard/components/Dashboard'));
const AllAccounts = lazy(() => import('../../accounts/components/Acc'));
const NewAccount = lazy(() => import('../../accounts/components/NewAccount'));
const PeopleTabPanel = lazy(() => import('../../peopleContainer/components/peopleTabPanel'));
const AddPerson = lazy(() => import('../../peopleContainer/people/components/AddPerson'));
const GenerateKey = lazy(() => import('../../peopleContainer/people/components/GenerateKey'));
const ConversionPixel = lazy(() => import('../../brand/ConversionPixel/components/ConversionPixel'));
const ConversionPixelList = lazy(() => import('../../brand/ConversionPixel/components/ConversionPixelList'));
const RetargetingPixel = lazy(() => import('../../brand/RetargetingPixel/components/RetargetingPixel'));
const AllRetargetingPixel = lazy(() => import('../../brand/RetargetingPixel/components/AllRetargetingPixel'));
const Label = lazy(() => import('../../label/components/DefaultCompLabel'));
const EditCategory = lazy(() => import('../../editCategory/components/editCategory'));
const Prefix = lazy(() => import('../../prefix/components/DefaultCompPrefix'));
const MainComponent = lazy(() => import('../../partnerOrg/components/mainComponent'));
const InvitePartner = lazy(() => import('../../partnerOrg/invitepartner/components/InvitePartner'));
const RenderAddPeople =lazy(() => import('../../partnerOrg/allpartners/components/allPartnerActions/renderAddPeople'));
const Trackers = lazy(() => import('../../partnerIntegration/components/PartnerIntegrationComp'));
const thirdpartyTrackers = lazy(() => import('../../partnerIntegration/components/PartnerIntegrationComp'));
const BrandSafety = lazy(() => import('../../partnerIntegration/components/BrandSafety'));
const Support = lazy(() => import('../../support/components/Support'));
const Reports = lazy(() => import('../../reports/components/Reports'));

const mapStateToProps = (state) => {
    return {
        authorizationDetails: state.permissionReducer.authorizationDetails,
        compData: state.appReducer.compData
    }
}
var mouseX = 0, mouseY = 0;
class SideNavComp extends PureComponent {
    constructor(props) {
        super(props);
        this.handleClick = this.handleClick.bind(this);
        this.handleChildClick = this.handleChildClick.bind(this);
        this.splitSpace = this.splitSpace.bind(this);
        this.hideSubMenu = this.hideSubMenu.bind(this);
        let menuArr = [], allMenu = [], allRoutes = [];
        const userPermission = this.props.authorizationDetails.consolidatedPermission ? this.props.authorizationDetails.consolidatedPermission : [];
        const brandPermission = (this.props.authorizationDetails.organizationType === "Brand" || 
									this.props.authorizationDetails.organizationType === "Retailer") ? true : false;
        const tenantPerm = this.props.authorizationDetails.consolidatedTenantPermission;
        const adminPerm = this.props.authorizationDetails.userAppScopes;
        let hasBrbPerm = userPermission ? userPermission.filter(item => item > 0 && item < 10000) : [];
        let hasRmaPerm = adminPerm && adminPerm.includes(appConstants.RETAIL_MEDIA_SCOPE);

        adminJson.SideMenu.forEach((menuDetail, index) => { 
            if(!menuDetail.menu.permision.length || menuDetail.menu.permision.some(r => userPermission.indexOf(r) >= 0)
            || hasBrbPerm.length || hasRmaPerm ) {
                menuDetail.menu.child.forEach(function (child, childIndex) {
                    if(menuDetail.menu.child.length && menuDetail.menu.menuName === "BRB") {
                        allRoutes.push('/brb');
                        allMenu.push("brb"); 
                    } 
					else if(menuDetail.menu.child.length && menuDetail.menu.menuName === appConstants.JIVOX_IQ_DAVINCI){
						allRoutes.push('/rma');
                        allMenu.push("rma"); 
					}
					else {
                        if((child.permision && child.permision.some(r => userPermission.indexOf(r) >= 0)) || !child.permision) {
                            let childName = Object.keys(child)[0];
                            let childRoute = '';
                            let pushRoute = false;

                            for(let [key, value] of Object.entries(child)) {
                                if(key === 'childRoute') {
                                    childRoute = value;
                                    if(menuDetail.menu.menuName === "Brand") {
                                        if(brandPermission) {
                                            pushRoute = true;
                                        }
                                    } else {
                                        pushRoute = true;
                                    }
        
                                    if(pushRoute) {
                                        allRoutes.push(childRoute);
                                        allMenu.push(childName);
                                    }
                                }
                            }
                        }
                    } 
                });
            }
        }); 
        let childActive = false;
        adminJson.SideMenu.forEach((menuDetail, index) => { 
            var childArr = []; 
            let menuState = 'inactive';
            let navClass = 'nav child_menu no-display';
            let iClass = '', validMenu = 1;
        
            switch(menuDetail.menu.menuName) {
                case 'Accounts':
                    iClass = 'fal fa-table'
                    break;
                case 'Partner Organization':
                    iClass = 'far fa-handshake-alt'
                    break;
                case 'Partner Integrations':
                    iClass = 'far fa-weight'
                    break;
                case 'People':
                    iClass = 'far fa-users'
                    break;
                case 'Brand':
                    iClass = 'fab fa-bandcamp'
                    break;
                case 'Labels':
                    iClass = 'far fa-bookmark'
                    break;
                case 'Prefix':
                    iClass = 'far fa-file-import'
                    break;
                case 'Reports':
                    iClass = 'far fa-line-chart'
                    break;
                case 'Support':
                    iClass = 'far fa-info-circle'
                    break;
                case 'BRB':
                    iClass = 'far fa-external-link'
                    break;
				case appConstants.JIVOX_IQ_DAVINCI:
					iClass = 'far fa-bullhorn'
					break;
                case 'Placements':
                    iClass = 'far fa-user'
                    break;
                default:
                    iClass = 'far fa-home'
            }
            var parentPermArr = [];
            if(menuDetail.menu.menuName === "Accounts") {
                parentPermArr = userPermission;
            } else if(menuDetail.menu.menuName === "Placements") {
                parentPermArr = adminPerm;
            } else {
                parentPermArr = tenantPerm;
            }
            if(menuDetail.menu.permision) { 
                if(!menuDetail.menu.permision.length || (parentPermArr !== undefined && menuDetail.menu.permision.some(r => parentPermArr.indexOf(r) >= 0))
                    || (hasBrbPerm.length && menuDetail.menu.menuName === "BRB") || (hasRmaPerm && menuDetail.menu.menuName === appConstants.JIVOX_IQ_DAVINCI)) {
                    if(menuDetail.menu.menuName === "Placements") {
                        if(this.props.authorizationDetails && this.props.authorizationDetails.organizationType.toLowerCase() !== "publisher") {
                            validMenu = 0;
                        } 
                    }
                    if(menuDetail.menu.menuName === "Brand") {
                        if(this.props.authorizationDetails && 
							this.props.authorizationDetails.organizationType.toLowerCase() !== "brand" && 
							this.props.authorizationDetails.organizationType.toLowerCase() !== "retailer" ) {
                            validMenu = 0;
                        } 
                    }
                    
                    let currentLocation = this.props.currentLocation;
                    let locationState = this.props.location.state;
                    let currentPath = locationState && locationState.path ? this.props.location.state.path : this.props.location.pathname ;
                    let menuVisible = true;
                    
                    menuDetail.menu.child.forEach(function (child, childIndex) { 
                        menuVisible = typeof child.menuVisible !== 'undefined' ? child.menuVisible : true;
                        
                        let permArr;
                        if(Object.keys(child)[0] === "allaccounts") {
                            permArr = userPermission;
                        } else if(Object.keys(child)[0] === "placements") {
                            permArr = adminPerm;
                        } else {
                            permArr = tenantPerm;
                        }

                        if((child.permision && child.permision.some(r => permArr.indexOf(r) >= 0)) || !child.permision) {
                            childArr.push(child);
                        } 

                        if(menuVisible) {
                            let childRoute = child.childRoute;
                            
                            if(childRoute && !childActive) {
                                if(currentLocation && currentLocation === childRoute) {
                                    childActive = true;
                                    menuState = 'active';
                                    navClass = 'nav child_menu slidedown';
                                }  else if(currentLocation && currentLocation.split("/")[1] === childRoute.split("/")[1] && allRoutes.indexOf(currentPath) !== -1) {
                                    // for all non side nav routes like edit category
                                    childActive = true;
                                    menuState = 'active';
                                    navClass = 'nav child_menu slidedown';
                                } else if(locationState && locationState.routeTo === childRoute) {
                                    // for all side nav's dynamic route(:id)
                                    childActive = true;
                                    menuState = 'active';
                                    navClass = 'nav child_menu slidedown';
                                }
                            } 
                        }
                    });
                  
                    if(validMenu) {
                        menuArr.push({
                            menuName: menuDetail.menu.menuName,
                            menuStatus: menuState,
                            ulSidenavClass: navClass,
                            iClass: iClass,
                            child: childArr,
                            expanded: false,
                            route: menuDetail.menu.route
                        })
                    }
                }
            } 
            return menuArr
        });
        let activeMenu = menuArr.filter((menu, index) => {
            return menu.menuStatus === 'active'
        })
        if(!activeMenu.length) {
            menuArr[0].menuStatus = "active"
            menuArr[0].ulSidenavClass = "nav child_menu slidedown"
        }
     
        this.state = {
            menuState: menuArr,
            navSize: 'nav-md',
            allMenu: allMenu
        }
        //if the user directly enters a path which they have no permission for, then redirect them to home path
        if(!allRoutes.includes(this.props.location.pathname)){
            this.props.history.push('/'); 
        }
    }

    getMousePositionX = (event) => {
        if(this.props.navActiveClass === "active-sm"){
            mouseX = event.pageX;
            return mouseX;
        }
    }
    getMousePositionY = (event) => {
        if(this.props.navActiveClass === "active-sm"){
            mouseY = event.pageY;
            return mouseY;
        }
    }
    componentDidMount(){
        document.body.addEventListener('mousemove', this.getMousePositionX, this.getMousePositionY);
        document.body.addEventListener('click', this.hideSubMenu);
    }
    componentWillUnmount() {
        document.body.removeEventListener('mousemove', this.getMousePositionX, this.getMousePositionY);
        document.body.removeEventListener('click', this.hideSubMenu);
    }
    hideSubMenu(e){
        var clickedElement;
        if(e.target.tagName === "I"){
            clickedElement = e.target.parentNode.parentNode;
        }else if(e.target.tagName === "A"){
            clickedElement = e.target.parentNode;
        }
        if(clickedElement !== undefined && clickedElement.classList.contains('expanded')){
            return;
        }
        if(this.props.navActiveClass === "active-sm" && (!e.target.parentNode.parentNode.parentNode.classList.contains('side-menu') || !e.target.classList.contains('left-navigation'))){
            let duplicateMenuState = [
                ...this.state.menuState
            ]
    
           duplicateMenuState.forEach((key, index) => {
                if(key.expanded){
                    duplicateMenuState[index].expanded = false;
                    duplicateMenuState[index].ulSidenavClass = duplicateMenuState[index].ulSidenavClass.replace('slidedown', 'slideup');
                    duplicateMenuState[index].ulSidenavClass = duplicateMenuState[index].ulSidenavClass.replace('block-display', '');
                }
                if(key.menuStatus === "expanded"){
                    duplicateMenuState[index].menuStatus = "inactive";
                }
            })
            this.setState({
                menuState:duplicateMenuState
            })
        }
    }
    // on menu toggle change class from active to active-sm viceversa & close side menu on toggle
    componentDidUpdate(prevProps, prevState) {
        let menuStateDup = this.state.menuState.slice();
        let activeMenu = menuStateDup.find((element) => {
            return ( element.menuStatus === 'active' || element.menuStatus === 'active-sm' )
        });
        const locationState = this.props.location.state;
        const currentPathName = this.props.currentLocation;
    
        if (prevProps.navActiveClass !== this.props.navActiveClass) {
            let currentNavSize = '';

            if ( activeMenu && ( activeMenu.menuStatus === 'active' || activeMenu.menuStatus === 'active-sm') ) {
                activeMenu.menuStatus = this.props.navActiveClass;
            } 

            if(activeMenu && this.props.navActiveClass === 'active-sm') {
                currentNavSize = 'nav-sm';
                activeMenu.ulSidenavClass = "nav child_menu height-zero";
                activeMenu.expanded = false;
            } else if(activeMenu) {
                activeMenu.ulSidenavClass = 'nav child_menu slidedown';
                activeMenu.expanded = true;
            }

            this.setState({
                menuState: menuStateDup,
                navSize: currentNavSize,
            });
        } else if(locationState && locationState.updatePending) {
            // when user navigated to a different menu wihtout touching sidenav
            // using a link/button of the site ( example- brand safety from card menu)
            this.updateMenu(menuStateDup, activeMenu, locationState.routeTo);
            locationState.updatePending = false;
        } else if(activeMenu && activeMenu.route && activeMenu.route.split("/")[1] !== currentPathName.split("/")[1] && activeMenu.route !== currentPathName) {
            // execute this block when user click on browser back button, last menu should be active now
            // current menu should be closed
            this.updateMenu(menuStateDup, activeMenu, currentPathName);
        }
    }
    /**
     * updateMenu Update side nav menu to active/inactive, slide up/down
     * menuStateDup Duplicate of current menu state Object
     * routePath If destination route specified String
     */
    updateMenu = (menuStateDup, activeMenu, routePath) => {
        const updatedMenu = menuStateDup.map((menuDetail, index) => {
            const child = menuDetail.child;
            const childArr = Object.values(child);
           
            for(let value of childArr) {
                if(routePath === value.childRoute) {
                    activeMenu.ulSidenavClass = 'nav child_menu slideup';
                    activeMenu.expanded = false;
                    activeMenu.menuStatus = 'inactive';

                    menuDetail.ulSidenavClass = 'nav child_menu slidedown';
                    menuDetail.expanded = false;
                    menuDetail.menuStatus = 'active';
                }
            }
            return menuDetail
        })
       
        this.setState({
            menuState: updatedMenu
        });
    }
    showMenu = (e, menuClicked) => {
        this.setState((prevState) => ({menuState: prevState.menuState.map((name, index) => {
            if((name.menuName !== menuClicked && name.menuStatus !== 'active' && name.menuStatus !== 'active-sm') || 
                (name.menuStatus === 'active' && name.menuName === menuClicked) ||
                (name.menuName !== menuClicked && name.menuStatus === 'expanded') || 
                (name.menuName === menuClicked && name.expanded)) {
                return {
                    ...name,
                    ulSidenavClass: 'nav child_menu slideup',
                    expanded: false,
                    menuStatus: 'inactive'
                }
            } else if(this.state.navSize === "nav-sm" && name.menuStatus === 'active-sm' && name.menuName === menuClicked) {
                return {
                    ...name,
                    ulSidenavClass: 'nav child_menu block-display slidedown',
                    expanded: true,
                    menuStatus: 'active'
                }
            } else if(name.menuName === menuClicked && (name.menuStatus !== 'active' || name.menuStatus !== 'active-sm')) {
                return {
                    ...name,
                    ulSidenavClass: 'nav child_menu slidedown',
                    expanded: true,
                    menuStatus: 'expanded'
                }
            } else if(this.state.navSize === "nav-sm" && name.menuStatus === 'active' && name.menuName !== menuClicked){
                return {
                    ...name,
                    ulSidenavClass: 'nav child_menu slideup',
                    menuStatus: 'active-sm',
                    expanded: false
                }
            } else {
                return {
                    ...name
                }
            }
        })}))
    }
    handleClick(e, menuClicked) {
        if(this.props.navActiveClass === "active" && e.type === "click"){
            this.showMenu(e, menuClicked);
        } else if(this.props.navActiveClass === "active-sm" && e.type === "mouseenter"){
            setTimeout(() => {
                var oldelement = document.elementFromPoint(mouseX, mouseY);
                if(oldelement === null){
                    return;
                }
                if(oldelement.tagName === "I"){
                    oldelement = oldelement.parentNode.parentNode;
                }else if(oldelement.tagName === "A"){
                    oldelement = oldelement.parentNode;
                }
                var oldIndex = [...oldelement.parentNode.childNodes].indexOf(oldelement);
                setTimeout(() => {
                    var newelement = document.elementFromPoint(mouseX, mouseY);
                    if(newelement === null){
                        return;
                    }                    
                    if(newelement.tagName === "I"){
                        newelement = newelement.parentNode.parentNode;
                    }else if(newelement.tagName === "A"){
                        newelement = newelement.parentNode;
                    }
                    var newIndex = [...newelement.parentNode.childNodes].indexOf(newelement);
                    if(oldIndex === newIndex && !newelement.classList.contains('expanded')){
                        this.showMenu(e, menuClicked);
                    }
                }, 300)
            }, 50);
            
        }
    }
    handleChildClick(menuClicked, childMenuName, event) {
        if(childMenuName == 'help' && event){
            event.preventDefault();
            openSupportWidget();
        }
        this.setState((prevState) => ({menuState: prevState.menuState.map((name, index) => {
            if(name.menuName !== menuClicked || ( name.menuName !== menuClicked && name.menuStatus === 'active' && this.state.navSize !== "nav-sm")) {
                return {
                    ...name,
                    menuStatus: 'inactive',
                    ulSidenavClass: 'nav child_menu slideup'
                }
            } else if(this.state.navSize === "nav-sm") {
                return {
                    ...name,
                    menuStatus: 'active-sm',
                    ulSidenavClass: 'nav child_menu slideup'
                }
            } else {
                return {
                    ...name,
                    menuStatus: 'active'
                }
            }
        })}))
    }
    splitSpace(elem) {
        if(elem.indexOf(' ') !== -1) {
            elem = elem.split(' ');
            elem = elem[0];
        }
        return elem;
    }
    handlemouseout = (event) => {
        if(this.props.navActiveClass === "active-sm"){
            setTimeout(() => {
                var elementMouseIsOver = document.elementFromPoint(mouseX, mouseY);
                if(elementMouseIsOver === null){
                    return;
                }
                var elementParent = elementMouseIsOver.parentNode.parentNode;
                if(!elementParent.classList.contains('child_menu') && !elementParent.parentNode.classList.contains('side-menu') && !elementMouseIsOver.classList.contains('left-navigation')){
                    let duplicateMenuState = [
                        ...this.state.menuState
                    ]
            
                    duplicateMenuState.forEach((key, index) => {
                        if(key.expanded){
                            duplicateMenuState[index].expanded = false;
                            duplicateMenuState[index].ulSidenavClass = duplicateMenuState[index].ulSidenavClass.replace('slidedown', 'slideup');
                            duplicateMenuState[index].ulSidenavClass = duplicateMenuState[index].ulSidenavClass.replace('block-display', '');
                        }
                        if(key.menuStatus === "expanded"){
                            duplicateMenuState[index].menuStatus = "inactive";
                        }
                    })
                    this.setState({
                        menuState:duplicateMenuState
                    })
                }
            }, 500)
        }      
    }
    render() {
        let 
            currentLocation = this.props.currentLocation,
            softwareUpdateWarning = ApiConfigClass.getData(appConstants.SOFTWARE_UPDATE_WARNING);
        const locationState = this.props.location.state;
        const routeTo = locationState && locationState.routeTo ? locationState.routeTo.substr(locationState.routeTo.lastIndexOf('/') + 1) : '';
        currentLocation = routeTo ? routeTo : currentLocation.substr(currentLocation.lastIndexOf('/') + 1);
        return (
            <div className="nav_content">
                <div className="col-md-3 left_col left-navigation" onMouseLeave={this.handlemouseout}>
                    <div className="left_col scroll-view">
                        <div className="main_menu_side hidden-print main_menu" id="sidebar-menu">
                            <div className="menu_section active">
                                <ul className="nav side-menu">
                                    {
                                        this.state.menuState.length ? this.state.menuState.map((menuDetail, index) => {
                                            let childMenu = menuDetail.child;
                                            var self = this;
                                            
                                            return (
                                                <li key={index} className={menuDetail.menuStatus}>
                                                    <a
                                                        onClick={(e) => this.handleClick(e, menuDetail.menuName)}
                                                        onMouseEnter={(e) => this.handleClick(e, menuDetail.menuName)}>
                                                        <i className={menuDetail.iClass}></i> {menuDetail.menuName} 
                                                        <span className="far fa-chevron-down"></span>
                                                    </a>
                                                    <ul key={index} className={menuDetail.ulSidenavClass}>
                                                        {
                                                            childMenu.length ? 
                                                                childMenu.map(function (child, childIndex) {
                                                                    const menuVisible = typeof child.menuVisible !== 'undefined' ? child.menuVisible : true;

                                                                    if(menuVisible) {
                                                                        let childName = Object.keys(child)[0],
                                                                        childPath = child.childRoute, 
                                                                        organizationId = btoa(self.props.authorizationDetails.organizationId),
                                                                        template = '', externalUrl = '', 
                                                                        publisherUrl = '/affiliate/adminportal/administration.php?id='+organizationId;

                                                                        if(childName !== "brb" && childName !== "placements" && childName !== "support" && childName !== "help" && childName !== "rma") {
                                                                            template = <NavLink to={childPath} onClick={() => self.handleChildClick(menuDetail.menuName)}>{Object.values(child)[0]}</NavLink>;
                                                                        } else {
                                                                            var BRB_URL = ApiConfigClass.getData(appConstants.BRB_URL),
                                                                                SUPPORT_URL = ApiConfigClass.getData(appConstants.SUPPORT_URL),
																				RMA_URL = ApiConfigClass.getData(appConstants.RMA_URL);
                                                                            externalUrl = childName === "brb" ? BRB_URL : childName === "placements" ? publisherUrl : childName === "support" ? SUPPORT_URL : childName === "rma" ? RMA_URL : '' ;
                                                                            template = <a href={externalUrl} target = {childName === "support" ? "_blank" : childName === "rma" ? "_blank" : ""} onClick={(event) => self.handleChildClick(menuDetail.menuName,childName, event)}>{Object.values(child)[0]}</a>;
                                                                        }
                                                                    
                                                                        return(
                                                                            <li className={currentLocation === childName ? 'current-page' : ''} key={childIndex}>
                                                                                {template}
                                                                            </li>  
                                                                        )
                                                                    }
                                                                    
                                                                }) : ''
                                                        }
                                                    </ul>
                                                </li>
                                            )
                                        }) : ''
                                    }
                                </ul>
                            </div>
                            {
                                softwareUpdateWarning ? 
                                <div className='softwareWarning'>
                                    <div className='title'>
                                        <i className="far fa-laptop-code"></i>
                                        <span>Software Updates</span>
                                    </div>
                                    <div className='warningMessage'>
                                        {softwareUpdateWarning}
                                    </div>
                                </div> : null 
                            }
                        </div>
                    </div>
                </div>

                <div className="right_content">
                    <Breadcrumb currentLocation={this.props.location.pathname} menuState={this.state.menuState}/>
                    <Suspense fallback=''>
                        <Switch>
                            <Route path="/home/dashboard" render={() => <Dashboard />} />
                            <Route path="/accounts/allaccounts" render={() => <AllAccounts isAdmin={this.props.isAdmin} history={this.props.history}/>} />
                            <Route path="/accounts/newaccount" render={() => <NewAccount history={this.props.history} />} />
                            <Route path="/people/allpeople" render={() => <PeopleTabPanel isAdmin={this.props.isAdmin} />} />
                            <Route path="/people/newperson" render={() => <AddPerson history={this.props.history} />} />
                            <Route path="/people/generatekey" render={() => <GenerateKey />} />    
                            <Route path="/brand/retargetingpixel" render={() => <RetargetingPixel />} />
                            <Route exact path="/brand/allretargetingpixel" render={() => <AllRetargetingPixel />} />
                            <Route path="/brand/conversionpixel/add" render={() => <ConversionPixel />} />
                            <Route path="/brand/conversionpixel" render={() => <ConversionPixelList />} />
                            <Route exact path="/labels/addlabel" render={() => <Label history={this.props.history}/>} />
                            <Route path={`/labels/addlabel/editCategory/:id`} render={() => 
                                    <EditCategory rowData={this.props.location.state ? this.props.location.state.rowData : []} history={this.props.history} />
                                }/>  
                            <Route path="/prefix/addprefix" render={() => <Prefix />} />
                            <Route path="/partner/allpartners" render={() => <MainComponent history={this.props.history}/>} />
                            <Route path="/partner/invitepartner" render={() => <InvitePartner history={this.props.history} />} />
                            <Route path={`/partner/addPeople/:id`} render={() => 
                                    <RenderAddPeople rowData={this.props.location.state ? this.props.location.state.rowData : []} history={this.props.history} />
                                }/>  
                            <Route path={`/partnerintegration/brandsafety/:id?`}>
                                <BrandSafety />
                            </Route>
                            <Route path="/partnerintegration/moat" render={() => <Trackers />}/>
                            <Route path="/partnerintegration/gcm" render={() => <Trackers />}/>
                            <Route path="/partnerintegration/dv360" render={() => <Trackers />}/>
                            <Route path="/partnerintegration/doubleVerify" render={() => <Trackers />}/>
                            <Route path="/partnerintegration/c3" render={() => <Trackers />}/>
                            <Route path="/partnerintegration/neustar" render={() => <Trackers />}/>
                            <Route path="/partnerintegration/sizmek" render={() => <Trackers />}/>
                            <Route path="/partnerintegration/adobe" render={() => <Trackers />}/>
                            <Route path="/partnerintegration/nielsen" render={() => <Trackers />}/>
                            <Route path="/partnerintegration/mediaMath" render={() => <Trackers />}/>
                            <Route path="/reports/reports" render={() => <Reports />} />
                            <Route path="/support/support" render={() => <Support />} /> 
                            <Redirect to="/home/dashboard" render={() => <Dashboard />} /> 
                        </Switch>
                    </Suspense>
                </div>
                {
                    this.props.showFooter !== 'none' ? 
                    (
                        <footer>
                            <div className="footer-content">
                                {
                                    this.props.footerConfig.length === 0  ? 
                                    <ul>
                                        <li><a href="//www.jivox.com/company">About Jivox</a> <span>|</span> </li>
                                        <li><a href="//www.jivox.com/terms">Terms of Use</a> <span>|</span> </li>
                                        <li><a href="//www.jivox.com/advertiserguidelines">Advertising Guidelines</a>  <span>|</span> </li>
                                        <li><a href="//www.jivox.com/privacy">Privacy</a>  <span>|</span> </li>
                                        <li><a href="//www.jivox.com/contact">Contact Us</a>  <span>|</span> </li>    
                                        <li>© Jivox, 2007 - {new Date().getFullYear()}. All rights reserved.</li>
                                    </ul>
                                    :
                                    <ul>
                                    {
                                        this.props.footerConfig.map((config,index) => (
                                           <li key={index}>
                                               {
                                                   
                                                        !!config.link.trim() ? <a href={config.link}>{config.title}</a> : config.title
                                               }
                                               {
                                                        index !== this.props.footerConfig.length-1 ? <span> | </span> : ''
                                               }
                                           </li> 
                                        ))
                                    }
                                    </ul>
                                    
                                }
                            </div>
                            <div className="clearfix"></div>
                        </footer>
                    ) : null
                }
               
            </div>
        );
    }
}
const SideNav = connect(mapStateToProps, null)(SideNavComp);
export default withRouter(SideNav);