import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
// import adminJson from '../conf1.json';

class Breadcrumb extends Component {
    render() {
        var prevPath = this.props.location && this.props.location.state ? this.props.location.state.prevPath : '',
        currentPath = this.props.currentLocation.split("/"),
        parentName = currentPath && currentPath[1] ? currentPath[1] : '',
        routeChildName = currentPath && currentPath[2] ? currentPath[2] : '',
        routeTo, parentBreadcrumbName = '', childBreadcrumbName = '';

        this.props.menuState.length && this.props.menuState.forEach((menuDetail, index) => {
            let childMenu = menuDetail.child;

            if(menuDetail.menuName.toLowerCase() === parentName) {
                parentBreadcrumbName = menuDetail.menuName;
                routeTo = menuDetail.route;
            }

            childMenu.length && childMenu.forEach(function (child, childIndex) {
                var childName = Object.keys(child)[0];
                
                if(routeChildName === childName) {
                    childBreadcrumbName = Object.values(child)[0];
                }
            });
        });

        return ( 
            <div className="x_title_breadcrumbs">
                <ul className="breadcrumbs">
                    {
                        parentName === "home" ? 
                        (
                            <li>
                                <span>Home</span>
                            </li>
                        ) : 
                        <li>
                           <NavLink to="/home/dashboard">Home</NavLink>
                        </li>
                    }
                    
                    {
                        parentBreadcrumbName && prevPath ?
                        (
                            <li>
                                <NavLink to={routeTo}>{parentBreadcrumbName}</NavLink>
                            </li>
                        ) : ''
                    }
                    {
                        childBreadcrumbName && childBreadcrumbName !== "Dashboard" ?
                        (
                            <li>{childBreadcrumbName}</li>
                        ) : ''
                    }                  
                </ul>
            </div>
        );
    }
}
export default withRouter(Breadcrumb);
