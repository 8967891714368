import {useEffect} from 'react';
import { useDispatch } from 'react-redux';

import {isEmpty} from '../commonFunc'

export const useSubscription = (actionData) => {
    const {postData, endPoint, storePath, actionType, requestInfo, actionCreator} = actionData;
    let {isUpdateRequired} = actionData;
    isUpdateRequired = typeof isUpdateRequired !== 'undefined' ? isUpdateRequired : false;
    const dispatch = useDispatch();
    const actionObj = {
        url: endPoint,
        storePath: storePath,
        actionType: actionType,
        requestInfo: requestInfo,
        postData: postData
    }
    useEffect(() => {
        if(isUpdateRequired || !isEmpty(postData)) {
            dispatch(actionCreator(actionObj));
        }
    }, [isUpdateRequired, postData])
}