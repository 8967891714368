import * as actionTypes from '../../../constants/peopleConstants.js';

export default function peopleReducer(state = { allPeopleList: {}, peoplesLoading: true, 
    processingInviteAcc: {processing: false, msg: '', apiKey: '', associatedAccount:[]}}, action) {
    switch (action.type) {
        case actionTypes.SET_ALL_PEOPLES:
            return {
                ...state,
                allPeopleList: action.list,
            }
        case actionTypes.SHOW_ACC_INVITE_LOADER:
            return {
                ...state,
                processingInviteAcc: action.data
            }
        case actionTypes.SET_API_KEY:
            return {
                ...state,
                apiKey: action.apiKey
            }
        case actionTypes.SET_ASSOCIATED_ACCOUNT:
            return {
                ...state,
                associatedAccount: action.accountList
            }
        default:
            return state;
    }
}