import * as appConstants from '../../constants/appConstants.js';
import { permissionApi } from '../services/permissionApi.js';
import {apiError} from '../../app/actions/appAction';

function getPermissions(response, STORE_PATH) {
    return {
        type: appConstants.GET_PERMISSIONS,
        response,
        STORE_PATH
    }
}

export const getPermissionAction = (storePath, callback) => (dispatch) => {
    permissionApi( (response) => {
        if(response.error) {
            dispatch(apiError(response.error, storePath));
        } else {
            dispatch(getPermissions(response, storePath));
        }
        callback && callback(response);
    });
}