import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Root from './routes/routes.js';
import configureStore from './store/configureStore';
import { unregister } from './registerServiceWorker';
import styles from '../src/css/accCard.module.css';
unregister() ;

const store = configureStore();
const canHover = !(matchMedia('(hover: none)').matches);

// if (process.env.NODE_ENV === 'development') {
//     const whyDidYouRender = require('@welldone-software/why-did-you-render');
//     whyDidYouRender(React, {
//         trackAllPureComponents: true
//     })
// }

if(canHover) {
    document.getElementById('root').classList.add(styles.canHover);
} else {
    document.getElementById('root').classList.remove(styles.canHover);
}

ReactDOM.render(
    <Root store={store} />,
    document.getElementById('root')
)
