// Partner Parent
export const PARTNER_TAB_COUNT = 'PARTNER_TAB_COUNT';
export const UPDATE_PARTNER_COUNT = 'UPDATE_PARTNER_COUNT';
export const PARTNER_MAIN_COMP = 'PARTNER_MAIN_COMP';
export const ALL_PARTNERS_TAB = 'All Partners';
export const PENDING_INVITE_TAB = 'Pending Invites';

// All Partners
export const ALL_PARTNERS_LIST = 'ALL_PARTNERS_LIST';
export const SET_ACCEPTED_PARTNER = 'SET_ACCEPTED_PARTNER';
export const SET_INVITED_PARTNER = 'SET_INVITED_PARTNER';
export const PARTNER_DETAILS = 'PARTNER_DETAILS';
export const GET_PARTNER_DETAILS = 'GET_PARTNER_DETAILS';
export const SET_UPDATE_REQUIRED = 'SET_UPDATE_REQUIRED';
export const DELETE_PARTNER = 'DELETE_PARTNER';
export const DELETE_SELECTED_PARTNER  = 'DELETE_SELECTED_PARTNER';
export const UPDATE_PARTNER_LIST = 'UPDATE_PARTNER_LIST';
export const INVITE_PARTNER = 'INVITE_PARTNER';
export const PARTNER_LIST_UPDATE_REQUIRED = 'PARTNER_LIST_UPDATE_REQUIRED';

// SUCCESS_MSG
export const SUCCESS_MSG = 'Partner was deleted';
export const GLOBAL_PARTNER_DELETE_SUCCESS = 'Global Partner was deleted';
export const INVITE_PARTNER_SUCCESS = 'Partner was invited';
export const ACCEPT_INVITE_SUCCESS = 'Partner Invite was accepted';
export const DECLINE_INVITE_SUCCESS = 'Partner Invite was declined';
export const DELETE_INVITE_SUCCESS = "Partner Invite was deleted.";
export const REINVITE_SUCCESS = "Partner (admin) was re-invited";
export const REVOKE_INVITE_SUCCESS ='Partner invite was revoked';
export const ADD_PEOPLE_SUCCESS = 'Person added successfully';
export const EDIT_PARTNER_MEMBERSHIP_SUCCESS = 'The partner membership was updated.';
export const PARTNER_MEMBERSHIP_DELETE_SUCCESS = 'The partner membership was deleted.';

//invite partner constants
export const INVITE_PARTNER_ROLE = 'INVITE_PARTNER_ROLE';

//pending Invites constant
export const PENDING_INVITES_LIST = 'PENDING_INVITES_LIST';
export const DELETE_PENDING_INVITE= 'DELETE_PENDING_INVITE';
export const PENDING_INVITE_UPDATE_REQUIRED = 'PENDING_INVITE_UPDATE_REQUIRED';
export const UPDATE_PENDING_INVITE_ROW = 'UPDATE_PENDING_INVITE_ROW';

//pending Invites Actions
export const ACCEPT_INVITE = 'ACCEPT_INVITE';
export const DECLINE_INVITE = 'DECLINE_INVITE';
export const REVOKE_INVITE = 'REVOKE_INVITE';
export const REINVITE = 'REINVITE';
export const DELETE_INVITE = 'DELETE_INVITE';

//add people
export const ADD_PEOPLE = 'ADD_PEOPLE';

//people container tabs
export const PEOPLE_TAB = 'People';
export const PARTNER_MEMBERSHIP_TAB = 'Partner Membership';

//partner Membership constants
export const PARTNER_MEMBERSHIP_LIST = 'PARTNER_MEMBERSHIP_LIST';
export const DELETE_PARTNER_MEMBERSHIP= 'DELETE_PARTNER_MEMBERSHIP';
export const EDIT_PARTNER_MEMBERSHIP = 'EDIT_PARTNER_MEMBERSHIP';
export const PARTNER_ROLES = 'PARTNER_ROLES';
export const PARTNER_MEMBERSHIP_LIST_UPDATE_REQUIRED = 'PARTNER_MEMBERSHIP_LIST_UPDATE_REQUIRED';
export const SAME_ORG_MEMBER = '1'; 

//footer text
export const ACCEPTED_PARTNERSHIPS = 'Accepted Partnerships';
export const INVITED_PARTNERS = 'Invited Partners';
