import React, { Component } from 'react';
import { connect } from 'react-redux';
import ProfileImg from '../../assests/img.jpg';
import SettingsModal from '../../settingsModal/SettingsModal';
import {toggleSideMenuAction} from '../actions/toggleSideMenuAction';
import {logoutAction} from '../actions/appAction';
import { userInfoAction } from '../../dashboard/actions/dashboardAction';
import { logoutResp, getOrgURl, openSupportWidget } from '../../helper/commonFunc';
import * as appConstants from '../../constants/appConstants.js';
import ApiConfigClass  from '../../config/apiConfig';
import Tooltip from '../../commonUI/tooltip/tooltip.js';
import '../../css/header.css';
import styles from '../../css/accCard.module.css';
import { SettingsModalNew } from '../../settingsModal/SettingsModalNew';

const mapDispatchToProps = (dispatch) => {
    return {
        toggleSideMenuAction: (newClass) => {
            dispatch(toggleSideMenuAction(newClass));
        },
        logoutAction: (callback) => {
            dispatch(logoutAction(callback));
        },
        getUserInfo: () => {
            dispatch(userInfoAction());
        }
    };
}

const mapStateToProps = (state) => {
    return {
        authorizationDetails: state.permissionReducer.authorizationDetails,
        accDetails: state.dashboardReducer.accDetails
    }
}

var tooltipContent = '',
	tooltipId = '';

class HeaderComp extends Component {
    constructor(props) {
        super(props);
        this.toggleMenu = this.toggleMenu.bind(this);
        this.toggleProfile = this.toggleProfile.bind(this);
        this.showNotification = this.showNotification.bind(this);
        this.showSettings = this.showSettings.bind(this);
        this.hideSettings = this.hideSettings.bind(this);
        this.logOut = this.logOut.bind(this);
        this.state = {
            showSettingmodalWindow: false,
            isProfileOpen: '',
            isNotiOpen: 'dropdown',
            toolTipShow :false
        }
    }
    componentDidMount() {
        this.props.getUserInfo();
        var specifiedElement = document.getElementById('profileOption');

        if(specifiedElement) {
            document.addEventListener('click', (event) => this.closePopOver(event, specifiedElement)) 
            document.addEventListener('keyup', (event) => this.closePopOver(event, specifiedElement));
        }
    }

    closePopOver = (e, specifiedElement) => {
        if (e.keyCode === 27 || e.type === 'click') { 
            var isClickInside = specifiedElement.contains(e.target);

            if(!isClickInside) {
                if(this.state.isProfileOpen === 'open') {
                    this.toggleProfile();
                }
            } 
        }
    }
    showSettings() {
        this.setState({ 
            showSettingmodalWindow: true,
            isProfileOpen: ''
        });
    }

    hideSettings(){
        this.setState({ 
            showSettingmodalWindow: false
        });
    }

    logOut() {
        this.props.logoutAction(logoutResp.bind(this));
    }

    toggleMenu() {
        if(this.props.sideNavClass === "nav-md" || this.props.sideNavClass === "nav-md "+styles.navStyleMd) {
            this.props.toggleSideMenuAction('nav-sm '+styles.navStyleSm);
        } else {
            this.props.toggleSideMenuAction("nav-md "+styles.navStyleMd);
        }
    }
    toggleProfile() {
        if(this.state.isProfileOpen === "open") {
            this.setState({
                isProfileOpen: ''
            })
        } else {
            this.setState({
                isProfileOpen: 'open'
            })
        }
    }
    showNotification() {
        if(this.state.isNotiOpen === "dropdown open") {
            this.setState({
                isNotiOpen: 'dropdown'
            })
        } else {
            this.setState({
                isNotiOpen: 'dropdown open'
            })
        }
    }

    showToolTip = (e) => {
        if(e.currentTarget.offsetWidth < e.currentTarget.scrollWidth){
            tooltipContent = e.currentTarget.getAttribute('oldtitle');
            tooltipId = e.currentTarget.getAttribute('data-for');
            this.setState({toolTipShow:true});
        }
	}
    
    resetToolTip = (e) => {
		this.setState({toolTipShow:false});
	}

    render() {
        let modal = null, orgUrl = '', authorizationDetails = this.props.authorizationDetails,
            toolTip = null,
            systemMaintenanceWarning = ApiConfigClass.getData(appConstants.SYSTEM_MAINTENANCE_WARNING);

		if (this.state.showSettingmodalWindow) { 
			//modal =	<SettingsModal show={this.state.showSettingmodalWindow} onHide={this.hideSettings} accDetails={this.props.accDetails} />
            modal = <SettingsModalNew show={this.state.showSettingmodalWindow} onHide={this.hideSettings} accDetails={this.props.accDetails} />
        }
       
        if(this.state.toolTipShow){
			toolTip = <Tooltip source={tooltipContent} id={tooltipId} position={'bottom'} cssclass={'helpText'}/>
        } else {
			toolTip = "";
		}
       
       orgUrl = getOrgURl(authorizationDetails);

        return ( 
            <div className="top_nav">
                <div className="nav_menu">
                    {modal}
                    {toolTip}
                    <nav>
                        <div className="nav toggle">
                            <a id="menu_toggle" onClick={this.toggleMenu}><i className="far fa-bars"></i></a>
                        </div>
                        <ul className="nav navbar-nav navbar-right toprightList">
                            <li className={this.state.isProfileOpen} id="profileOption">
                                <a aria-expanded="false" className="user-profile dropdown-toggle" 
                                    data-toggle="dropdown" onClick={this.toggleProfile}>
                                    {
                                        this.props.accDetails && this.props.accDetails.image && this.props.accDetails.image["i1024x1024"].length ?
                                        (
                                            <img alt="profile" src={this.props.accDetails.image["i1024x1024"]} />
                                        )
                                        :
                                        (
                                            <img alt="profile" src={ProfileImg} />
                                        )
                                    }
                                    
                                    <span className="user-name" title={this.props.accDetails.firstName ? this.props.accDetails.firstName : authorizationDetails ? authorizationDetails.name : ''}>{this.props.accDetails.firstName ? this.props.accDetails.firstName : authorizationDetails ? authorizationDetails.name : ''}</span>
                                    <span className=" far fa-angle-down"></span>
                                </a>
                                <ul className="dropdown-menu dropdown-usermenu pull-right">
                                    <li className="settings" onClick={this.showSettings}>
                                        <a>Settings</a>
                                    </li>
                                    <li>
                                        <a onClick={this.logOut}><i className="far fa-sign-out-alt pull-right"></i> Log Out</a>
                                    </li>
                                </ul>
                            </li>
                            <li id="userBrand">
                                
                                {
                                    authorizationDetails && !!authorizationDetails.orgSettings && !authorizationDetails.orgSettings.isWhiteLabellingEnabled && authorizationDetails.organizationLogo && authorizationDetails.organizationLogo["i1024x1024"] ?
                                    (
                                        orgUrl ?
                                            <a href={orgUrl} className="user-brand" style={{backgroundImage: "url("+authorizationDetails.organizationLogo["i1024x1024"]+")"}}>
                                            </a>
                                        :
                                            <a className="user-brand" style={{backgroundImage: "url("+authorizationDetails.organizationLogo["i1024x1024"]+")"}}>  
                                            </a>
                                    )
                                    : null
                                }
                               
                            </li>
                            <li>
								<div className="supportButtonHeader" onClick={openSupportWidget}>
									<span className="supportHelpIcon"><i className="fal fa-question-circle"></i></span>
									<span className="supportHelpText">Help</span>
								</div>
							</li>
                            {
                                systemMaintenanceWarning ? 
                                    <li id="systemMaintenance">
                                        <div className='maintenance_warning' data-for="maintenance_warning" onMouseEnter={this.showToolTip} onMouseLeave={this.resetToolTip} oldtitle={systemMaintenanceWarning} data-tip>
                                            <span className='far fa-wrench'></span>
                                            <span className='messageContent'>{systemMaintenanceWarning}</span>
                                        </div>
                                    </li> : null 
                            }
                        </ul> 
                    </nav>
                </div>
            </div> 
        );
    }
}

const Header = connect(mapStateToProps, mapDispatchToProps)(HeaderComp);
export default Header;
