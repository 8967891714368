import { 
    TRACKER_CONFIG_LIST, DELETE_TRACKER, TRACKER_CONFIG_LIST_UPDATE_REQUIRED,CURRENT_TRACKER_CONFIG,
    GET_SITE_DETAILS,GET_ADVERTISER_DETAILS, GET_CAMPAIGN_DETAILS, RESET_CURRENT_CONFIG,
    SET_TRACKER_DETAILS, GET_ADVERTISER_DETAILS_DV360
} from '../../constants/trackersConstants';
import { formActionsEnum } from '../../config/brandsafetyconfig'
import { modelTypeEnum } from '../components/GCMConfig';

const currentTrackerConfig = {
    id: null,
    mediaPlan: null,
    campaignList: {},
    advertiserLists: {},
    site: {id:-1, name: "All Sites"},
    campaigns: [{id:-1, name: "All Campaigns"}],
    clientCode: '',
    trackerUrl: '',
    trackerGenerationModel: modelTypeEnum.MODEL1,
    additionalParams: {
        thirdPartyTrackerType: ''
    }
}


function trackerConfigReducer(trackerConfigState = {}, action) {
    switch (action.type) {
        case TRACKER_CONFIG_LIST:
            return {
                ...trackerConfigState,
                trackerConfiglist: action.data,
                totalCount: !!action.data ? action.data.length : 0
            }
        case CURRENT_TRACKER_CONFIG:
            return {
                ...trackerConfigState,
                prevTrackerId: trackerConfigState.currentTrackerConfig.id,
                currentTrackerConfig: action.data,
                formAction: action.data.formAction
            }
        case RESET_CURRENT_CONFIG:
            return {
                ...trackerConfigState,
                prevTrackerId: null,
                currentTrackerConfig: currentTrackerConfig,
                formAction: formActionsEnum.CREATE
            }
        case DELETE_TRACKER:
            return deleteTracker(trackerConfigState, action);
        case TRACKER_CONFIG_LIST_UPDATE_REQUIRED:
            return {
                ...trackerConfigState,
                isUpdateRequired: true
            }
        default:
            return trackerConfigState;
    }
}

function deleteTracker(trackerConfigState = {}, action) {
    let allTrackers = [...trackerConfigState.trackerConfiglist];
    let filteredList = allTrackers.filter((tracker, index) => {
        const id = tracker.id || tracker.configId;
        return id !== action.data.trackerConfigId
    });
    let totalCount = filteredList.length;

    return {
        ...trackerConfigState,
        trackerConfiglist: filteredList,
        totalCount: totalCount
    }
}

function siteDetails(siteDetails = {}, action) {
    switch (action.type) {
        case GET_SITE_DETAILS:
            let options = action.list;
            let formatSiteData = [];

            if(options.length) {
                formatSiteData = [
                     {
                        id: -1,
                        name: "All Sites"
                    },
                    ...options
                ];
            } 
            return {
                ...siteDetails,
                sites: formatSiteData
            }
        default:
            return siteDetails;
    }
}

function dcmAdvertiserList(advertiserList = {}, action) {
    switch (action.type) {
        case GET_ADVERTISER_DETAILS:
            let options = action.list;
            let formatBrandData = [];

            if(options.length) {
                formatBrandData = [
                    ...options
                ];
            } 
            return {
                ...advertiserList,
                advertiserList: formatBrandData
            }
        default:
            return advertiserList;
    }
}

function advertisersListDV360(advertisersList = {},action) {
    switch (action.type) {
        case GET_ADVERTISER_DETAILS_DV360:
            return {
                ...advertisersList,
                advertisersList: action.list                
            };
        default:
            return advertisersList;
    }
}

function dcmCampaignList(campaignList = {}, action) {
    switch (action.type) {
        case GET_CAMPAIGN_DETAILS:
            let options = action.list;
            let formatBrandData = [];

            if(options.length) {
                formatBrandData = [
                    ...options
                ];
            } 
            return {
                ...campaignList,
                campaignList: formatBrandData
            }
        default:
            return campaignList;
    }
}

function allTrackerDetails(trackers = {}, action) {
    switch (action.type) {
        case SET_TRACKER_DETAILS:
            return {
                trackers: action.data
            }
        default:
            return trackers;
    }
}

export default function partnerIntegrationReducer(state = {
    trackerConfig: {
        trackerConfiglist: [], gcmTrackerConfiglist: [],
        currentTrackerConfig, prevTrackerId: null,
        isUpdateRequired: true, totalCount: 0,
        formAction: formActionsEnum.CREATE
    },
    siteDetails: {sites: []},
    dcmAdvertiserList: {advertiserList: []},
    dcmCampaignList: {campaignsList: []},
    dv360AdvertisersList : {advertisersList: []},
    trackerDetails: {trackers: []}
}, action) {
    return {
        trackerConfig: trackerConfigReducer(state.trackerConfig, action),
        siteDetails: siteDetails(state.siteDetails, action),
        dcmAdvertiserList: dcmAdvertiserList(state.dcmAdvertiserList, action),
        dcmCampaignList: dcmCampaignList(state.dcmCampaignList, action),
        allTrackerDetails: allTrackerDetails(state.trackerDetails, action),
        dv360AdvertisersList: advertisersListDV360(state.dv360AdvertisersList,action)
    }
}