import * as actionTypes from '../../constants/accountConstants.js';

export default function editAccReducer(state = {accDetails: {}, processingEditAcc: {processing: false, msg: ''}}, action){
    switch (action.type) {
        case actionTypes.SET_EDIT_ACC_DETAILS:
            return {
                ...state,
                accDetails: action.data
            }
        case actionTypes.SHOW_ACC_EDIT_LOADER:
            return {
                ...state,
                processingEditAcc: action.data

            }
        default:
            return state;
    }
}