export const SET_PIXELS_FOR_EDIT = 'SET_PIXELS_FOR_EDIT';
export const GET_ALL_PIXELS = 'GET_ALL_PIXELS';
export const UPDATE_PIXEL_DETAILS = 'UPDATE_PIXEL_DETAILS';
export const LIST_PIXEL_COMPONENT = 'LIST_PIXEL_COMPONENT';
export const EMAIL_GENERATED_PIXEL = 'EMAIL_GENERATED_PIXEL';
export const UPDATE_PIXEL_COMPONENT = 'UPDATE_PIXEL_COMPONENT';
export const CREATE_PIXEL_COMPONENT = 'CREATE_PIXEL_COMPONENT';
export const GET_RETARGETING_PIXEL_TAG = 'GET_RETARGETING_PIXEL_TAG';

export const CONVERSION_PIXEL_COMPONENT = 'CONVERSION_PIXEL_COMPONENT';
export const GET_ALL_CONVERSION_PIXELS = 'GET_ALL_CONVERSION_PIXELS';
export const LIST_CONVERSION_PIXEL_COMPONENT = 'LIST_CONVERSION_PIXEL_COMPONENT';
export const SET_CONVERSION_PIXELS_FOR_EDIT = 'SET_CONVERSION_PIXELS_FOR_EDIT';
export const GET_CONVERSION_PIXEL_TAG = 'GET_CONVERSION_PIXEL_TAG';

export const PIXEL_COMPONENT = 'PIXEL_COMPONENT';
export const NEW_PIXEL_SUCCESS_MSG = 'Pixel created successfully';
export const UPDATE_PIXEL_SUCCESS_MSG = 'Pixel updated successfully';
export const EMAIL_PIXEL_SUCCESS_MSG = 'Email sent successfully';

