import { ALL_PARTNERS_LIST, GET_PARTNER_DETAILS, SET_UPDATE_REQUIRED, UPDATE_PARTNER_LIST, PARTNER_LIST_UPDATE_REQUIRED } from '../../../constants/partnerConstants';
import {ALL} from '../../../constants/filterConstants';
import { getInviteType } from '../../../helper/reducer/reducerUtil';

function allPartnersReducer(partnersState = {}, action) {
    switch (action.type) {
        case ALL_PARTNERS_LIST:
            return formatPartnerData(partnersState, action);
        case UPDATE_PARTNER_LIST:
            return deletePartner(partnersState, action);
        case PARTNER_LIST_UPDATE_REQUIRED:
            return {
                ...partnersState,
                isUpdateRequired: true
            }
        default:
            return partnersState;
    }
}

function formatPartnerData(allPartners, action) {
    let totalCount = action.data.totalCount;
    let partnersData = action.data.partners;

    // If filtered other than ALL, then don't update inviteType
    // inviteType is the inviteType of all invites not filtered invites   
    if(action.data.requestInfo.filterBy.label === ALL) {
        let inviteType = getInviteType(partnersData);
        return {
            partnersList: action.data ? action.data.partners : [],
            isUpdateRequired: false,
            totalCount: totalCount,
            inviteType: inviteType
        }
    } else {
        return {
            ...allPartners,
            partnersList: action.data ? action.data.partners : [],
            isUpdateRequired: false,
            totalCount: totalCount
        }
    }
}

function deletePartner(partnersState = {}, action) {
    let allPartners = [...partnersState.partnersList];
    let filteredList = allPartners.filter((partner, index) => {
        return partner.sourceOrgId !== action.data.sourceOrgId
    });
    let totalCount = filteredList.length;
    let inviteType = getInviteType(filteredList);

    return {
        partnersList: filteredList,
        totalCount: totalCount,
        inviteType: inviteType
    }
}

function partnerDetails(partnerDetails = {}, action) {
    switch (action.type) {
        case GET_PARTNER_DETAILS:
            return {
                ...partnerDetails,
                destOrgs: action.data.destOrgs,
                isUpdateRequired: false
            }
        case SET_UPDATE_REQUIRED:
            return {
                ...partnerDetails,
                isUpdateRequired: true
            }
        default:
            return partnerDetails;
    }
}
export default function partnerReducer(state = {
    allPartners: {partnersList: [], isUpdateRequired: true, totalCount: -1, inviteType: 0},
    partnerDetails: {destOrgs: [], isUpdateRequired: true}
}, action) {
    return {
        allPartners: allPartnersReducer(state.allPartners, action),
        partnerDetails: partnerDetails(state.partnerDetails, action)
    }
}