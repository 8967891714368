export const CREATE_PREFIX_COMPONENT = 'CREATE_LABEL_COMPONENT';
export const LIST_PREFIX_COMPONENT = 'LIST_PREFIX_COMPONENT';
export const EDIT_PREFIX_COMPONENT = 'EDIT_PREFIX_COMPONENT';
export const SET_PREFIX_LIST = 'SET_PREFIX_LIST';
export const DELETE_PREFIX_COMPONENT = 'DELETE_PREFIX_COMPONENT';
export const UPDATE_PREFIX_ROW = 'UPDATE_PREFIX_ROW';
export const SET_PREFIXES_OF_ENTITY = 'SET_PREFIXES_OF_ENTITY';
export const PREFIX_PERM = '21502,21511';

// SUCCSS_MSG
export const SUCCSS_MSG = 'The prefix was created successfully';
export const EDIT_SUCCSS_MSG = 'The prefix was updated successfully';
export const DELETE_PREFIX_SUCCESS_MSG = 'The Prefix was deleted';
