import React, { Component } from 'react';
import { connect } from 'react-redux';
import '../../css/logo.css';
import LogoImg from '../../assests/jivox_logo.png';
import {getOrgURl } from '../../helper/commonFunc';

const mapStateToProps = (state) => {
    return {
        authorizationDetails: state.permissionReducer.authorizationDetails
    }
}

class LogoComp extends Component {
    render() {
        const 
            {authorizationDetails} = this.props,
            isWhitelabel = authorizationDetails && !!authorizationDetails.orgSettings ? authorizationDetails.orgSettings.isWhiteLabellingEnabled : false,
            OrgLogo = isWhitelabel ? authorizationDetails.organizationLogo ? authorizationDetails.organizationLogo["i1024x1024"] : null : LogoImg,
            orgUrl = isWhitelabel ? getOrgURl(authorizationDetails) : 'https://www.jivox.com/';

        return (
            <div className="navbar nav_title" style={{border: 0}}>
                {
                    OrgLogo ?  
                        <a className="site_title" style={{backgroundImage: "url("+OrgLogo+")"}} href={orgUrl ? orgUrl : "#"}>
                        </a>
                    : null
                }
            </div>
        );
    }
}

const Logo = connect(mapStateToProps, null)(LogoComp);
export default Logo;