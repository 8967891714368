export default function settingsReducer(state = {
    loadingData : {fetching : false, fetchingDetails : ''}
}, action) {
    switch (action.type) {
        case 'SHOW_UPDATE_PERSON_LOADER': 
            return {
                ...state,
                loadingData : action.loadingData
            }
        default :
            return state
    }
}