import React, { Component } from 'react';
import {isEmpty} from '../../helper/commonFunc'

export default class CSSVariableApplicator extends Component {
    componentDidMount() {  
      this.updateCSSVariables(this.props.variables);
    }
  
    componentDidUpdate(prevProps) {
      if (this.props.variables !== prevProps.variables) {     
        this.updateCSSVariables(this.props.variables);
      }
    }
    updateCSSVariables(variables) {  
        if(!isEmpty(variables)) {
            var fileName = variables["--font-name"], fontSrc = variables["--font-src"] ? variables["--font-src"].substring(5, variables["--font-src"].length-2) : '';
          
            var newStyle = document.createElement('style');
            newStyle.appendChild(document.createTextNode("\
            @font-face {\
                font-family: " + fileName + ";\
                src:  url('" + fontSrc + "');\
            }\
            ")); 
            
            document.head.appendChild(newStyle);
            document.getElementById("mainDiv").style.fontFamily = fileName;
         
            for(let variable in variables) {
                document.documentElement.style.setProperty(variable, variables[variable]);
            }
        }
    }
    render() {
      return <div>{this.props.children}</div>;
    }
}