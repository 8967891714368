import * as appConstants from '../../constants/appConstants.js';
import * as actionTypes from '../../constants/dashboardConstants.js'

export default function appReducer(state = {}, action) {
    const { STORE_PATH, data, errMsg, logoPath } = action;
    const fileData = {
        data: '',
        errMsg: '',
        fetching: false,
        fileName: ''
    };

    switch (action.type) {
        case appConstants.UPLOAD_REQUEST:
            fileData.fetching = true;
            fileData.errMsg = '';
            break;
        case appConstants.UPLOAD_SUCCESS:
            fileData.data = data;
            fileData.fetching = false;
            break;
        case appConstants.UPLOAD_ERROR:
            fileData.errMsg = errMsg;
            fileData.fetching = false;
            fileData.data = ''
            break;
        case appConstants.REMOVE_FILE:
            fileData.data = '';
            break;
        case appConstants.IMG_DETAILS:
        case actionTypes.SET_LOGGED_USERDETAILS:
            fileData.data = logoPath;
            break;
        default:
            return state;
    }
    return {
        ...state,
        [STORE_PATH]: {
            ...state[STORE_PATH],
            ...fileData
        }
    }
}