import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {createMuiTheme, MuiThemeProvider} from "@material-ui/core/styles";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionActions';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const ExpansionPanel = withStyles({
    root: {
        width: '100%',
    }
})(Accordion);
  
const ExpansionPanelSummary = withStyles({
    root: {
        '&:hover': {
            backgroundColor: '#EEE',
        },        
        minHeight:'45px !important'
    },
    expanded: {
        backgroundColor: '#EEE',
        height:'20px ',
    },
    expandIcon: {
        color: "var(--primary-color) !important",
    }
})(AccordionSummary);
  
const ExpansionPanelDetails = withStyles(theme => ({
    root: {
        display:'inherit',
        padding:'10px 20px'
    }
}))(AccordionDetails);

const useStyles = makeStyles(theme => ({
    root:{
        MuiExpanded: {
            minHeight: 0,   
        }
    },
    heading: {
        fontSize: theme.typography.pxToRem(25),
        fontWeight: theme.typography.fontWeightRegular,
    }
}))

export default function CustomizedExpansionPanels(props) {
    const {hookToggle, storePath, expanded, onTogglePanel, panelBody, title} = props;
    const classes = useStyles();
    const getMuiTheme = createMuiTheme({
        overrides: {
            MuiSvgIcon:{
                root: {
                    fontSize: '22px !important',
                }
            },
            MuiCollapse: {
                hidden: {
                    visibility: 'collapse',
                },
                container: {
                    transitionDuration: '1.25s !important',
                    transition: 'height 1.5s cubic-bezier(0.4, 0, 0.2, 1) 0ms'
                }
            }
        }
    });
    return (
    <div className={classes.root}>
        <MuiThemeProvider theme={getMuiTheme} >
            <ExpansionPanel expanded={expanded === true} onChange={!!hookToggle ? () => hookToggle(!expanded) : !!onTogglePanel ? () => onTogglePanel(storePath, expanded) : null} >
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                    <Typography className={classes.heading}>{title}</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    {panelBody}
                </ExpansionPanelDetails>
            </ExpansionPanel> 
        </MuiThemeProvider>
    </div>
  );
}
