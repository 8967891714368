import * as actionTypes from '../../constants/peopleConstants.js'

export default function editPersonReducer(state = {processingPersonEdit: {processing: false, msg: ''}, initialPersonDetails: {}}, action) {
    switch (action.type) {
        case actionTypes.SHOW_EDIT_PERSON_LOADER:
            return {
                ...state,
                processingPersonEdit: action.data
            }
        case actionTypes.SET_INITIAL_EDIT_PERSON_DETAILS:
                return {
                    ...state,
                    initialPersonDetails: action.data
                }
        default:
            return state;
    }
}