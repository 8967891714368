import {setValidity} from '../../helper/formHelper/formValidation';
import {joinValues, getValue} from '../../helper/formatData';
import {isPlainObject} from "lodash";
import {isEmpty} from '../commonFunc';
import { payloadType } from '../../config/brandsafetyconfig';

export function formSubmit(event, formState, formData, postDataType) {
    event.preventDefault();
    var processedData = {postData: {}, requestData: {}, formTouched: false, formValid: false};
   
    validateFromData(processedData, formState, formData, postDataType);

    if(processedData.formValid) {
        return processedData;
    } else {
        return {
            formValid: processedData.formValid
        }
    }
}

export function validateFromData(processedData = {formTouched: false, formValid: false}, formState, formData = {}, postDataType) {
    var flag = 0;
    var formValid = true;

    for (let formElementIdentifier in formState) {
        let fieldValid = true;

        // Validation: if submitted without field change
        fieldValid = setValidity(formElementIdentifier, formState[formElementIdentifier].value, formState[formElementIdentifier].validation);
        formValid = fieldValid && formValid;

        if(flag === 0 && formState[formElementIdentifier].touched) {
            flag = 1;
            processedData.formTouched = true;
        }
        if(!isEmpty(formData)) {
            // Generate From data 
            processFormData(formState, formElementIdentifier, formData, processedData, postDataType);
        }
    }
    processedData.formValid = formValid;
    return processedData;
}

export function processFormData(formState, formElementIdentifier, formData, processedData, postDataType) {
    let elementName = formState[formElementIdentifier].elementConfig.name;
    /** 
     *  
     * In create form, we will process all the form elements which is available in the state(using formData.type).
     * In edit form, we will process only the form fields which is updated(using touched property).
    */
    if(formState[formElementIdentifier].touched || (!!formData && formData.type === 'createForm') || postDataType === payloadType.ENTIRE_FORM) {  
        if(formState[formElementIdentifier]['elementType'] === "file") {
            //To Process file object value
            processedData.postData[formElementIdentifier] = formData.fileObj;
            processedData.requestData[elementName] = processedData.postData[formElementIdentifier];
        } else {
            if(typeof formState[formElementIdentifier].value !== 'object') {
                //To Process string value
                processedData.postData[formElementIdentifier] = formState[formElementIdentifier].value;
            } else if(isPlainObject(formState[formElementIdentifier].value)) {
                //To process Object value
                processedData.postData[formElementIdentifier] = getValue(formState[formElementIdentifier].value);
            } else if(formState[formElementIdentifier].value) {
                //To process Array value
                var joinValuesByKey = (!!formState[formElementIdentifier]['joinValuesBy'] ? formState[formElementIdentifier]['joinValuesBy'] : 'id');
                processedData.postData[formElementIdentifier] = joinValues(formState[formElementIdentifier].value, joinValuesByKey);
            } else {
                processedData.postData[formElementIdentifier] = null;
            }
            processedData.requestData[elementName] = formState[formElementIdentifier].value;
        }
    }
    return processedData;
}