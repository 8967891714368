import { dashboardApi, dashboardApiSSODetails, dashboardApiSetSSODetails, activitiApi, dashboardApiSetCoBrandDetails, dashboardApiCoBrandDetails, dashboardApiResetCoBrandDetails, sharedEntitityApi, shareBrandDataApi, dashboardApiCoBrandDetailsRMA, dashboardApiResetCoBrandDetailsRMA } from '../services/dashboardApi.js';
import * as actionTypes from '../../constants/dashboardConstants.js';
import {apiError, apiSuccess, apiRequest} from '../../app/actions/appAction';
import { func } from 'prop-types';

function getRecentActivitiList(list, viewType, loading) {
    return {
        type: actionTypes.SET_RECENT_ACTIVITI,
        list,
        loading,
        viewType
    }
}

function setLoader(loading) {
    return {
        type: actionTypes.SET_ACTIVITI_LOADER,
        loading
    }
}

function getRecentAccountList(accList, loading) {
    return {
        type: actionTypes.SET_RECENT_ACCOUNT,
        accList,
        loading
    }
}

function getCoBrandList(coBrandList, loading, STORE_PATH) {
    return {
        type: actionTypes.GET_COBRAND_DETAILS,
        coBrandList,
        loading,
        STORE_PATH
    }
}

function getCoBrandListRMA(coBrandListRMA, loading, STORE_PATH) {
    return {
        type: actionTypes.GET_COBRAND_DETAILS_RMA,
        coBrandListRMA,
        loading,
        STORE_PATH
    }
}

function resetCoBrandList() {
    return {
        type: actionTypes.RESET_COBRAND_DETAILS
    }
}

function getAccInfo(accInfo, loading, STORE_PATH) {
    let logoPath = accInfo.image ? accInfo.image["i256x200"] : '';
    return {
        type: actionTypes.SET_LOGGED_USERDETAILS,
        accInfo,
        loading,
        logoPath,
        STORE_PATH
    }
}

function getSSODetails(accInfo, STORE_PATH){
    return {
        type: actionTypes.GET_SSO_DETAILS,
        ssoDetails: accInfo,
        STORE_PATH
    }
}

function getSharedEntityDetails(sharedEntityInfo) {
    return {
        type: actionTypes.GET_SHARED_ENTITY,
        sharedEntity: sharedEntityInfo
    }
}

export const recentActivitiAction = (postData, callback) => (dispatch) => {
    activitiApi( postData, (response) => {
        dispatch(getRecentActivitiList(response, postData.viewType, false));
        callback && callback(response);
    });
};

export const loaderAction = (loading) => (dispatch) => {
    dispatch(setLoader(loading));
};

export const userInfoAction = () => (dispatch) => {
    dashboardApi( (response) => {
        dispatch(getAccInfo(response, false, 'personsettings'));
    });
};

export const updateSSOAction = (accId, postData, storePath, callback) => (dispatch) => {
    dispatch(apiRequest(storePath));
    dashboardApiSetSSODetails(accId, postData, (response) => {
        if(response.error) {
            dispatch(apiError(response.error, storePath));
        } else {
            dispatch(apiSuccess(storePath, true))
        }
        callback && callback(response);
    });
};

export const updateCoBrandAction = (accId, postData, isRM, storePath, callback) => (dispatch) => {
    dispatch(apiRequest(storePath));
    dashboardApiSetCoBrandDetails(accId, postData, isRM, (response) => {
        if(response.error) {
            dispatch(apiError(response.error, storePath));
        } else {
            dispatch(apiSuccess(storePath, true))
        }
        callback && callback(response);
    });
};

export const ssoDetails = ({orgId, storePath}) => (dispatch) => {
    dispatch(apiRequest(storePath));
    dashboardApiSSODetails(orgId, (response) => {
        dispatch(getSSODetails(response, storePath));
    })
}

export const sharedEntitiesAction = (orgId) => (dispatch) => {
    sharedEntitityApi(orgId, (response) => {
        dispatch(getSharedEntityDetails(response.sharedEntities));
    })
}

export const coBrandDetails = (storePath, callback) => (dispatch) => {
    dashboardApiCoBrandDetails( (response) => {
        if(response.error) {
            dispatch(apiError(response.error, storePath));
        } else {
            dispatch(getCoBrandList(response, false, storePath));
        }
        callback && callback(response);
    })
}

export const coBrandDetailsRMA = (storePath, callback) => (dispatch) => {
    dashboardApiCoBrandDetailsRMA( (response) => {
        if(response.error) {
            dispatch(apiError(response.error, storePath));
        } else {
            dispatch(getCoBrandListRMA(response, false, storePath));
        }
        callback && callback(response);
    })
}

export const resetCoBrandDetailsRMA = (postData, scope, callback) => (dispatch) => {
    dashboardApiResetCoBrandDetailsRMA( postData, scope, (response) => {
        dispatch(resetCoBrandList(response, false));
        callback && callback(response);
    })
}

export const resetCoBrandDetails = (postData, scope, callback) => (dispatch) => {
    dashboardApiResetCoBrandDetails( postData, scope, (response) => {
        dispatch(resetCoBrandList(response, false));
        callback && callback(response);
    })
}

export const shareBrandDataAction  = ({postData, requestData, storePath, brandId}) => (dispatch) => {
    dispatch(apiRequest(storePath));
    shareBrandDataApi(postData, brandId, (response) => {
        if(response.error) {
            dispatch(apiError(response.error, storePath));
        } else {
            sharedEntitityApi(requestData.brandId, (response) => {
                dispatch(getSharedEntityDetails(response.sharedEntities));
            })
            // dispatch(getSharedEntityDetails(requestData.shareBrandDestination));
            dispatch(apiSuccess(storePath, true))
        }
    });
}

