import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import {ADD_PERSON} from '../../constants/peopleConstants.js';

class Button extends Component {
    cancel = (route) => {
        if(this.props.btnObj.from === ADD_PERSON) {
            this.props.removeLogoImage();
        }
        this.props.history.push(route);
    }
    handleSubmit = (e, btnObj) => {
        e.stopPropagation();
        this.props.submitHandler(e);

        let listRef = btnObj.listRef;
        if(!!listRef) {
            let timeOutId = setTimeout(function () {
                if (!!listRef.current && !!listRef.current.scrollIntoView) {
                    listRef.current.scrollIntoView({
                        behavior: "smooth",
                        block: "nearest"
                    });
                }
            },100);
        }
    }
    render() {
        let btnObj = this.props.btnObj;
        const parent = !!this.props ? this.props : '';

        return ( 
            btnObj === undefined ? null : 
            btnObj && btnObj.btnGroup ?
            <div className={btnObj.divclass}>
                <button type="button" className="btn btn-default btn-round margin-right-15" 
                id={btnObj.idSec} onClick={(e) => btnObj.buttonTitleSec !== "Cancel" ? (btnObj.handleButtonClick(e)) : btnObj.handleCancel ? (btnObj.handleCancel(e)) : this.cancel(btnObj.route)}>
                    {
                        btnObj.faIconSec ?
                        <i className={btnObj.faIconSec}></i>
                        : ''
                    }
                    {btnObj.buttonTitleSec}
                </button>
                <button type="button" className="btn btn-primary btn-round" disabled={btnObj.disabled || btnObj.isLoading} 
                id={btnObj.id} onClick={(e) => this.handleSubmit(e, btnObj)}>
                    {
                        btnObj.faIcon && !btnObj.isLoading  ?
                            <i className={btnObj.faIcon}></i>
                        : ''
                    }
                    {btnObj.isLoading ? 'Processing ' : btnObj.buttonTitle} 
                    {btnObj.isLoading ? <i className="far fa-spinner fa-spin"></i> : null}
                </button>
                {!!parent.toolTipContent ? 
                    <span 
                    className="toolTipAnchorClass ques far fa-question-circle" 
                    onMouseEnter={parent.showToolTip} 
                    onMouseLeave={parent.hideToolTip} 
                    tooltipcontent={parent.toolTipContent} 
                    data-tip 
                    data-for={parent.id} 
                    ></span> 
                :null}
            </div> 
            :
            <div className={btnObj.divclass}>
                <button type="submit" className="btn btn-primary btn-round" onClick={(e) => btnObj.handleButtonClick ? (btnObj.handleButtonClick(e)) : this.props.onClick(e)} disabled={btnObj.btnDisabled ? btnObj.btnDisabled : btnObj.disabled} id={btnObj.id}>
                    {
                        btnObj.faIcon && !btnObj.isLoading ?
                            <i className={btnObj.faIcon}></i>
                        : ''
                    }
                    {btnObj.isLoading ? 'Processing ' : btnObj.buttonTitle} 
                    {btnObj.isLoading ? <i className="far fa-spinner fa-spin"></i> : null}
                </button>
                {!!parent.toolTipContent ? 
                    <span 
                    className="toolTipAnchorClass ques far fa-question-circle" 
                    onMouseEnter={parent.showToolTip} 
                    onMouseLeave={parent.hideToolTip} 
                    tooltipcontent={parent.toolTipContent} 
                    data-tip 
                    data-for={parent.id} 
                    ></span> 
                :null}
            </div>   
        );
    }
}

export default withRouter(Button);